<div class="tenant-switch-dialog__container">
    <div class="tenant-switch-dialog__header">
        <h3>Switch tenant</h3>
        <button ax-button [icon-only]="true" (click)="close()">
            <i class="fa-light fa-xmark"></i>
        </button>
    </div>
    <div class="tenant-switch-dialog__content">
        <div class="tenant-switch-dialog__content__block">
            <span class="tenant-switch-dialog__content__block__label">Select a tenant</span>
            <ax-select
                [options]="tenantoptions"
                [(value)]="selectedTenantId"
                [placeholder]="'Select a tenant'"
                [noOptionsText]="'No tenants found...'"
                [hasClearEnabled]="false"
                (valueChange)="setSelectedTenant()">
            </ax-select>
        </div>
    </div>
    <!-- <div class="tenant-switch-dialog__footer">
        <button ax-button="primary" (click)="setSelectedTenant()">Set tenant</button>
        <button ax-button (click)="close()">Cancel</button>
    </div> -->
</div>