import { InjectionToken } from '@angular/core';

export interface ToastConfig {
    disable_timeout: boolean;
    delay: number;
    has_close_button: boolean;
    click_to_dispose: boolean;
}

export interface ToastGlobalConfig extends ToastConfig {
    max_opened: number;
    prevent_duplicates: boolean;
    position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

export const TOAST_CONFIG: InjectionToken<ToastConfig> = new InjectionToken<ToastConfig>('ToastConfig');
