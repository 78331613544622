/* eslint-disable curly */
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { commonViewImports } from '../../../utilities/global-imports';
import { ScanService } from '../../../services/scan.service';
import { ToastService } from '../../../components/toast/toast.service';
import { AbsApiService } from '../../../services/abs-api.service';
import { LocalSettings } from '../../../models/local-settings';
import { SettingsHelper } from '../../../utilities/settings-helper';
import { PartyIdentifierOptions } from '../../../models/party-identifier-options.model';
import { PartyIdentifierResult } from '../../../models/party-identifier-result.model';
import { PartyIdentifierTask } from '../../../models/party-identifier-task.model';
import { PartyIdentifierResults } from '../../../models/party-identifier-results.enum';
import _ from 'lodash';
import { LogisticsService } from '../../../services/logistics.service';
import { PartyStockLevel } from '../../../models/party-stock-level';
import { PageTemplateComponent } from '../../../templates/page-template/page-template.component';
import { Party } from '../../../models/party.model';
import { DialogComponentRef } from '../../../components/dialog/models/dialog-component-ref';
import { PartySelectDialogComponent } from '../../../dialogs/party-select-dialog/party-select-dialog.component';
import { DialogService } from '../../../components/dialog/dialog.service';
import { Subscription } from 'rxjs';
import { PartyStocktakeCommand } from '../../../models/party-stocktake-command-model';
import { AbsSettings } from '../../../models/abs-settings';

@Component({
    standalone: true,
    selector: 'ax-stocktake-page',
    templateUrl: './stocktake-overview-page.component.html',
    styleUrl: './stocktake-overview-page.component.scss',
    imports: [commonViewImports],
})
export class StocktakeOverviewPageComponent implements OnInit, AfterViewInit, OnDestroy {
    protected hasValidEntity: boolean = false;
    protected partyStockLevel: PartyStockLevel | undefined;

    // Current stock values
    protected boxesInStock: number = 0;
    protected stemsPerBox: number = 0;
    protected restUnits: number = 0;
    protected totalUnitsInStock: number = 0;

    // Available stock value
    protected availableBoxesInStock: number = 0;
    protected availableStemsPerBox: number = 0;
    protected availableRestUnits: number = 0;
    protected availableTotalUnitsInStock: number = 0;

    // Recorded quantity
    protected recordedQuantity: string = '';

    private partyKey: number = 0;
    private party: Party | undefined;
    private scannedBarcode: string = '';
    private settings: LocalSettings = SettingsHelper.loadSettings();
    private scanSubscription: Subscription;
    private absSettings: AbsSettings;

    @ViewChild('quantityInput') private readonly quantityInputElement: ElementRef;

    constructor(
        private readonly pageTemplate: PageTemplateComponent,
        private readonly scanService: ScanService,
        private readonly toastService: ToastService,
        private readonly absApiService: AbsApiService,
        private readonly logisticsService: LogisticsService,
        private readonly dialogService: DialogService
    ) {}

    async ngOnInit(): Promise<void> {
        this.absSettings = await this.absApiService.getSettings();

        this.scanSubscription = this.scanService.onScan.subscribe(async (barcode: string) => {
            try {
                this.scannedBarcode = barcode;

                const task: PartyIdentifierTask = {
                    partyIdentifier: this.scannedBarcode,
                    partyIdentifierOptions: new PartyIdentifierOptions(),
                };

                const result: PartyIdentifierResult = await this.absApiService.getLotsByIdAsync(task);

                if (result.partyIdentifierResults === PartyIdentifierResults.partiesNotFound) {
                    this.toastService.danger('Party not found', `No party found on '${barcode}'`);
                    this.hasValidEntity = false;
                    this.partyStockLevel = undefined;
                    return;
                } else if (_.isNil(result.foundLots) || _.isEmpty(result.foundLots)) {
                    this.toastService.danger('Party not found', `No party found on '${barcode}'`);
                    this.hasValidEntity = false;
                    this.partyStockLevel = undefined;
                } else if (!_.isNil(result.foundLots) && result.foundLots.length > 1) {
                    this.scanService.successSound();

                    const partySelectDialogRef: DialogComponentRef<PartySelectDialogComponent> = this.dialogService.createDialog(PartySelectDialogComponent, {
                        parties: result.foundLots,
                    });

                    partySelectDialogRef.afterClose.subscribe(async (selectedInternalNumber: string) => {
                        if (_.isEmpty(selectedInternalNumber)) return;
                        else {
                            this.party = result.foundLots.find((party: Party) => party.internalNumber === selectedInternalNumber);

                            if (_.isNil(this.party)) {
                                this.toastService.danger('Party select error', 'Unable to fetch data for selected party');
                                return;
                            } else {
                                this.partyStockLevel = await this.logisticsService.getPartyStockLevel(this.party.partyKey);
                                this.setPartyValues(this.partyStockLevel, this.party);
                                this.hasValidEntity = true;
                                this.pageTemplate.setBarcode(this.partyStockLevel.internalNumber);
                                this.partyKey = this.party.partyKey;
                            }
                        }
                    });
                } else {
                    this.partyStockLevel = await this.logisticsService.getPartyStockLevel(result.foundLots[0].partyKey);
                    this.setPartyValues(this.partyStockLevel, result.foundLots[0]);
                    this.hasValidEntity = true;
                    this.scanService.successSound();
                    this.partyKey = result.foundLots[0].partyKey;
                    this.party = result.foundLots[0];
                }
            } catch {
                this.toastService.danger('Fetch error', 'Failed to fetch party');
            }
        });
    }

    ngAfterViewInit(): void {
        // Add a tiny delay to prevent race condition on change detection
        _.delay(() => {
            this.pageTemplate.setBarcodePlaceholder('Scan a lot');
        }, 10);
    }

    ngOnDestroy(): void {
        this.scanSubscription.unsubscribe();
        this.pageTemplate.setBarcode('');
    }

    protected getEmptyStateText(): string {
        if (this.settings.show_barcode_bar) return 'Scan or type a barcode to start stocktaking';
        else return 'Scan a barcode to start stocktaking';
    }

    protected setPartyValues(partyStockLevel: PartyStockLevel, party: Party): void {
        this.boxesInStock = partyStockLevel.boxesInStock;
        this.stemsPerBox = partyStockLevel.stemsPerBox;
        this.restUnits = partyStockLevel.restStemsInStock;
        this.totalUnitsInStock = partyStockLevel.unitsInStock;

        const productUnits: number = this.divideSafely(party.stems, party.stockUnitsPerProductUnit);
        const totalProductUnitsLot: number = this.divideSafely(party.totalStemsParty, party.stockUnitsPerProductUnit);
        const productUnitsUsed: number = this.divideSafely(party.totalStemsParty - party.stemsFree, party.stockUnitsPerProductUnit);

        this.availableBoxesInStock = this.divideSafely(totalProductUnitsLot - productUnitsUsed, productUnits);
        this.availableStemsPerBox = party.stems;
        this.availableRestUnits = party.stemsFree % party.stems;
        this.availableTotalUnitsInStock = party.stemsFree;

        //We need to wait a little bit so the layout is ready for setting the quantity input
        setTimeout(() => {
            this.setRecordedQuantity();
        });
    }

    protected isStockUpdateDisabled(): boolean {
        if (_.isEmpty(this.recordedQuantity)) return true;

        return false;
    }

    protected checkInput(event: KeyboardEvent): boolean {
        const key: string = event.key;

        // Allow a single period character in recordedQuantity
        if (key === '.') {
            return this.recordedQuantity.includes('.') ? false : true;
        }

        // Check if the key is a number
        const isNumber = !isNaN(Number(key));
        return isNumber && key !== null && key.trim() !== '';
    }

    protected async updateStock(): Promise<void> {
        const inputUnits: number = this.getUnits();

        let mutationUnits: number = 0;
        let result: number = 0; // This needs a better name

        if (inputUnits === this.totalUnitsInStock) {
            mutationUnits = 0;
            result = 1;
        } else if (inputUnits > this.totalUnitsInStock) {
            mutationUnits = this.totalUnitsInStock - inputUnits;
            result = 2;
        } else {
            // This needs a better name
            const scanResult: number = this.availableTotalUnitsInStock < this.totalUnitsInStock - inputUnits ? 4 : 3;

            const calc: number = this.totalUnitsInStock - inputUnits;
            mutationUnits = Math.min(this.availableTotalUnitsInStock, calc);
            result = scanResult;
        }

        const partyStocktakeCommand: PartyStocktakeCommand = {
            partyKey: this.partyKey,
            units: inputUnits,
            scannedValue: this.partyStockLevel.internalNumber,
            scanResult: result,
            partyMutationUnits: mutationUnits,
        };

        await this.logisticsService.stocktakeAsync(partyStocktakeCommand);

        // Reload data

        const task: PartyIdentifierTask = {
            partyIdentifier: this.scannedBarcode,
            partyIdentifierOptions: new PartyIdentifierOptions(),
        };

        const partyIdentifierResult: PartyIdentifierResult = await this.absApiService.getLotsByIdAsync(task);
        // We are not re-checking like we do in the initial call since we never get here if we are not in a valid context

        this.party = partyIdentifierResult.foundLots.find((x: Party) => x.partyKey === this.partyKey);

        this.partyStockLevel = await this.logisticsService.getPartyStockLevel(this.partyKey);
        this.setPartyValues(this.partyStockLevel, this.party);

        this.toastService.success('Success', 'Stock updated');
    }

    // Logic copied from original WMA project
    private divideSafely(dividend: number, divisor: number): number {
        if (divisor === 0) return 0;

        return Math.floor(dividend / divisor);
    }

    private getUnits(): number {
        if (_.isNil(this.recordedQuantity)) {
            throw new Error('');
        } else {
            if (this.recordedQuantity.includes('.')) {
                const splittedQuantity: string[] = this.recordedQuantity.split('.');

                // Assuming we always handle numbers
                const boxes: number = Number(splittedQuantity[0]);
                const remainder: number = Number(splittedQuantity[1]);

                return boxes * this.stemsPerBox + remainder;
            } else {
                return Number(this.recordedQuantity);
            }
        }
    }

    private setRecordedQuantity() {
        const suggestQuantityExpected = this.absApiService.getBooleanSetting(this.absSettings, 'SuggestExpectedQuantityForStockTake');
        const suggestUpp = this.absApiService.getBooleanSetting(this.absSettings, 'PDAStockTakeSuggestUpp');
        const hasRemains = this.restUnits > 0;

        switch (`${suggestQuantityExpected},${suggestUpp},${hasRemains}`) {
            case 'true,true,false': {
                this.recordedQuantity = `${this.boxesInStock}.`;
                this.setQuantityInputSelection(0, this.boxesInStock.toString().length);
                break;
            }
            case 'true,true,true': {
                this.recordedQuantity = `${this.boxesInStock}.${this.restUnits}`;
                this.setQuantityInputSelection(0, this.recordedQuantity.length);
                break;
            }
            case 'false,true,false': {
                this.recordedQuantity = '.';
                this.setQuantityInputSelection(0, 0);
                break;
            }
            case 'true,false,false': {
                const stock = this.boxesInStock * this.stemsPerBox + this.restUnits;
                this.recordedQuantity = stock.toString();
                this.setQuantityInputSelection(0, stock.toString().length);
                break;
            }
            default: {
                this.setQuantityInputSelection(0, 0);
            }
        }
    }

    private setQuantityInputSelection(start: number, end: number) {
        //First set focus on the element, before we can set the selection range in a timeout
        this.quantityInputElement.nativeElement.focus();
        setTimeout(() => {
            this.quantityInputElement.nativeElement.setSelectionRange(start, end);
        });
    }
}
