import { Component, OnInit } from '@angular/core';
import { commonViewImports } from '../../../utilities/global-imports';
import { MeasurementPoint } from '../../../models/measurement-point';
import { AbsApiService } from '../../../services/abs-api.service';
import { ToastService } from '../../../components/toast/toast.service';
import { ListViewItemDefinition } from '../../../components/list-view/interfaces/list-view-item-definition';
import { ListViewDefinitionType } from '../../../components/list-view/enums/list-view-item-definition-type';
import { Router } from '@angular/router';

@Component({
    standalone: true,
    templateUrl: './measuring-page.component.html',
    styleUrl: './measuring-page.component.scss',
    imports: [commonViewImports],
})
export class MeasuringPageComponent implements OnInit {
    measurementPoints: MeasurementPoint[] = [];
    isLoading: boolean = true;

    protected listViewDefinitions: ListViewItemDefinition[] = [{ caption: '', property_name: 'description', type: ListViewDefinitionType.Default }];

    constructor(
        private readonly absApiService: AbsApiService,
        private readonly toastService: ToastService,
        private router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        const measurementPoints = await this.absApiService.getMeasurementPointsAsync();

        if (measurementPoints !== null && measurementPoints.length > 0) {
            for (const measurementPoint of measurementPoints) if (measurementPoint.availableInABSUI) this.measurementPoints.push(measurementPoint);
        } else this.toastService.danger('Measurement points', 'No measurement points found');
        this.isLoading = false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected async onItemClick(event: any): Promise<void> {
        this.router.navigate([`measuring/${event.measurementPointKey}`]);
    }
}
