import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { angularImports } from '../../utilities/global-imports';
import _ from 'lodash';

@Component({
    standalone: true,
    selector: 'ax-checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    imports: [angularImports],
})
export class CheckboxComponent {
    @Input() public isChecked: boolean = false;
    @Input() public tabIndex: number = 0;
    @Input() public isDisabled: boolean = false;
    @Input() public stopPropagationOnChange: boolean = true;
    @Input() public size: 'default' | 'small' = 'default';
    @Output() public isCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @HostListener('keydown.enter', ['$event'])
    public onEnter(event: KeyboardEvent): void {
        event.preventDefault();

        this.checkedChange(null);
    }

    public isFocussed: boolean = false;

    setClasses(): string {
        let classList: string = '';

        if (this.isChecked) classList = `${classList} ax-checkbox--checked`;

        if (this.isFocussed) classList = `${classList} ax-checkbox--has-focus`;

        if (this.isDisabled) classList = `${classList} ax-checkbox--disabled`;

        if (this.size === 'small') classList = `${classList} ax-checkbox--small`;

        return classList;
    }

    checkedChange(event: Event): void {
        if (!_.isNil(event) && this.stopPropagationOnChange) event.stopPropagation();

        this.isChecked = !this.isChecked;
        this.isCheckedChange.emit(this.isChecked);
    }

    onFocus(): void {
        if (!this.isDisabled) this.isFocussed = true;
    }

    onFocusLost(): void {
        this.isFocussed = false;
    }
}
