import { ApplicationRef, ComponentRef, createComponent, EnvironmentInjector, Injectable, Type } from '@angular/core';
import { DialogComponent } from './dialog.component';
import { DialogComponentRef } from './models/dialog-component-ref';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    private dialogComponentRef: ComponentRef<DialogComponent>;

    constructor(
        private readonly applicationRef: ApplicationRef,
        private readonly injector: EnvironmentInjector
    ) {
        if (this.dialogComponentRef === undefined || this.dialogComponentRef === null) {
            this.dialogComponentRef = createComponent(DialogComponent, {
                environmentInjector: this.injector,
            });

            document.body.appendChild(this.dialogComponentRef.location.nativeElement);
            this.applicationRef.attachView(this.dialogComponentRef.hostView);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createDialog<T>(element: Type<T>, data?: any): DialogComponentRef<T> {
        return this.dialogComponentRef.instance.createDialog<T>(element, data);
    }
}
