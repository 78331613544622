<div class="shipment-check-overview__container">
    @if (shipments.length > 0) {
        <ax-list-view
            [isLoading]="isLoading"
            [definitions]="listViewDefinitions"
            [data]="shipments"
            [columnCount]="'auto'"
            (rowClicked)="onItemClick($event)"
            [booleanIconTrue]=""
            [booleanIconFalse]=""
            [rowClassCallbackFn]="getRowClass"
        ></ax-list-view>
    } @else {
        <div class="no__data__container">
            <i class="no__data__container__icon fa-light fa-shelves"></i>
            <h5>No shipments were found</h5>
        </div>
    }
</div>
