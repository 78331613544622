<div class="party-select-dialog__container">
    <div class="party-select-dialog__header">
        <h3>Select a party</h3>
        <button ax-button [icon-only]="true" (click)="close()">
            <i class="fa-light fa-xmark"></i>
        </button>
    </div>
    <div class="party-select-dialog__content">
        <div class="party-select-dialog__content__block">
            <span class="party-select-dialog__content__block__label">Select a party</span>
            <ax-select
                [options]="partyOptions"
                [(value)]="selectedPartyInternalNumber"
                [placeholder]="'Select a party'"
                [noOptionsText]="'No parties found...'"
                [hasClearEnabled]="false">
            </ax-select>
        </div>
    </div>
    <div class="party-select-dialog__footer">
        <button ax-button="primary" (click)="confirmSelection()" [disabled]="selectedPartyInternalNumber === ''">Confirm</button>
        <button ax-button (click)="close()">Cancel</button>
    </div>
</div>