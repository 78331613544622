/* eslint-disable curly */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { LogisticsService } from '../../../../services/logistics.service';
import { ToastService } from '../../../../components/toast/toast.service';
import { ShipmentScanTransaction } from '../../../../models/shipment-scan-transaction.model';
import { commonViewImports, pipeImports } from '../../../../utilities/global-imports';
import { ListViewItemDefinition } from '../../../../components/list-view/interfaces/list-view-item-definition';
import { ListViewDefinitionType } from '../../../../components/list-view/enums/list-view-item-definition-type';
import { ShipmentBoxRow } from '../../../../models/shipment-box-row.model';
import { Subscription } from 'rxjs';
import { ScanService } from '../../../../services/scan.service';
import { EntityIdentification, EntityType } from '../../../../utilities/entity-identification';
import { PageTemplateComponent } from '../../../../templates/page-template/page-template.component';
import _ from 'lodash';
import { Shipment } from '../../../../models/shipment.model';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from '../../../../components/dialog/dialog.service';
import { ShowLabelsDialogComponent } from '../../../../dialogs/show-labels-dialog/show-labels-dialog.component';
import { Label } from '../../../../models/label.model';
import { LabelScanActionContext } from '../../../../models/label-scan-action-context';

@Component({
    standalone: true,
    templateUrl: './shipment-check-details-page.component.html',
    styleUrl: './shipment-check-details-page.component.scss',
    imports: [commonViewImports, pipeImports],
})
export class ShipmentCheckDetailsPageComponent implements OnInit, AfterViewInit, OnDestroy {
    boxRows: ShipmentBoxRow[] = [];
    isLoading: boolean = true;

    protected total: string = '';
    protected toScan: number = 0;
    protected loosePackings: number = 0;
    protected openPackings: number = 0;
    protected sealedPackings: number = 0;
    protected shipment: Shipment;

    protected listViewDefinitions: ListViewItemDefinition[] = [
        { caption: 'Product', property_name: 'product', type: ListViewDefinitionType.Primary },
        { caption: 'ID', property_name: 'orderAndBoxNumberCombined', type: ListViewDefinitionType.Default },
        { caption: 'Amount', property_name: 'amount', type: ListViewDefinitionType.Default },
        { caption: 'Cask', property_name: 'caskCode', type: ListViewDefinitionType.Default },
    ];

    private shipmentKey: number = 0;
    private shipmentScanTransactionId: number = 0;
    private shipmentScanTransaction: ShipmentScanTransaction;
    private scanSubscription: Subscription;
    private barcode: string = '';

    constructor(
        private readonly pageTemplate: PageTemplateComponent,
        private readonly logisticsService: LogisticsService,
        private readonly toastService: ToastService,
        private readonly scanService: ScanService,
        private readonly dialogService: DialogService,
        route: ActivatedRoute
    ) {
        this.shipmentKey = route.snapshot.params['shipmentKey'];
    }

    async ngOnInit(): Promise<void> {
        this.scanSubscription = this.scanService.onScan.subscribe(async (barcode) => {
            this.barcode = barcode;
            if (this.barcode.length === 14 || this.barcode.length === 12 || this.barcode.length === 8) {
                const entity: EntityIdentification | Error = EntityIdentification.validateBarcode(this.barcode);
                if (!(entity instanceof Error))
                    switch (entity.entityType) {
                        case EntityType.TransportCarrierLayer:
                            await this.handleTransportCarrierLayerEntity(entity);
                            break;
                        case EntityType.PackingLabel:
                            await this.handlePackingLabelEntity(entity);
                            break;
                        default:
                            this.toastService.danger('Entity type', `No valid entity type found`);
                            break;
                    }
            } else this.toastService.danger('Barcode', `Invalid barcode scanned ${this.barcode}`);
        });

        await this.startShipmentScanTransactionAsync(this.shipmentKey);
        await this.getShipmentScanTransactionAsync(this.shipmentScanTransactionId);

        this.isLoading = false;
    }

    ngAfterViewInit(): void {
        _.delay(() => {
            this.pageTemplate.setBarcodePlaceholder('Scan a barcode');
        }, 10);
    }

    ngOnDestroy(): void {
        this.scanSubscription.unsubscribe();
        this.pageTemplate.setBarcode('');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected showLabelsDialog(event: any): void {
        if (event.hasLabels) this.dialogService.createDialog<ShowLabelsDialogComponent>(ShowLabelsDialogComponent, this.shipmentScanTransaction.shipment.labels);
    }

    protected getRowClass(row: ShipmentBoxRow): string {
        if (row) {
            switch (row.rowStatus) {
                case 1:
                    return 'ax-list-view__item--status-1';
                case 2:
                    return 'ax-list-view__item--status-2';
                case 0:
                default:
                    return '';
            }
        } else return '';
    }

    private async startShipmentScanTransactionAsync(shipmentKey: number) {
        const shipmentScanTransaction = await this.logisticsService.startShipmentScanTransactionAsync({ shipmentKey });
        this.shipmentScanTransactionId = shipmentScanTransaction.shipmentScanTransactionKey;
    }

    private async getShipmentScanTransactionAsync(shipmentScanTransactionKey: number) {
        const shipmentScanTransaction = await this.logisticsService.getShipmentScanTransactionAsync(shipmentScanTransactionKey);
        if (shipmentScanTransaction !== null) {
            this.shipmentScanTransaction = shipmentScanTransaction;
            this.setShipmentTransactionValues(this.shipmentScanTransaction);
        } else this.toastService.danger('Scan transaction', 'No scan transaction found');
    }

    private async handleTransportCarrierLayerEntity(entity: EntityIdentification): Promise<void> {
        if (this.shipmentScanTransactionId === null)
            if ((await this.getShipmentByLabelIdAsync(entity.entityKey, true)) !== null) await this.getShipmentScanTransactionAsync(this.shipmentScanTransactionId);
            else this.toastService.danger('Shipment', 'No shipment could be found for this label');
        else await this.scanLabelAsync(entity.entityKey, true);
    }

    private async handlePackingLabelEntity(entity: EntityIdentification): Promise<void> {
        if (this.shipmentScanTransactionId === null)
            if ((await this.getShipmentByLabelIdAsync(entity.entityKey)) !== null) await this.getShipmentScanTransactionAsync(this.shipmentScanTransactionId);
            else this.toastService.danger('Shipment', 'No shipment could be found for this label');
        else await this.scanLabelAsync(entity.entityKey);
    }

    private async getShipmentByLabelIdAsync(labelId: number, groupLabel: boolean = false): Promise<Shipment | null> {
        const shipment = await this.logisticsService.getShipmentByLabelIdAsync(labelId, groupLabel);
        if (shipment !== null) await this.startShipmentScanTransactionAsync(shipment.shipmentKey);
        return shipment;
    }

    private async scanLabelAsync(labelId: number, groupLabel: boolean = false): Promise<void> {
        try {
            const scanActionKey = await this.logisticsService.scanLabelOnShipmentScanTransaction(this.shipmentScanTransactionId, labelId, groupLabel);
            if (scanActionKey > 0) {
                const context: LabelScanActionContext = await this.logisticsService.getShipmentScanTransactionLabelScanActionContextAsync(this.shipmentScanTransaction.shipment.shipmentKey, labelId, scanActionKey);
                if (context !== null)
                    if (!context.valid) {
                        if (context.validationResults.length > 0) {
                            let errorString = '';
                            if (context.validationResults.length > 1) errorString = context.validationResults[0] + ' and ' + context.validationResults[1];
                            else errorString = context.validationResults[0];

                            this.toastService.danger('Scan', errorString);
                        }
                    } else {
                        const labelToUpdate: Label | undefined = this.shipmentScanTransaction.shipment.labels.find((x: Label) => x.labelId === context.labelId);

                        if (!_.isNil(labelToUpdate)) labelToUpdate.scanned = true;

                        this.toastService.success('Label', 'Label scanned successfully');

                        await this.getShipmentScanTransactionAsync(this.shipmentScanTransactionId);
                    }
                else this.toastService.danger('Scan', 'Could not confirm scan result');
            } else this.toastService.danger('Label', 'Label scan failed');
        } catch (e) {
            console.log(e);
            this.toastService.danger('Label', 'Failed to scan label, please contact your supervisor.');
        }
    }

    private setShipmentTransactionValues(shipmentScanTransaction: ShipmentScanTransaction): void {
        this.shipment = shipmentScanTransaction.shipment;
        this.total = `${shipmentScanTransaction.amountReady} / ${shipmentScanTransaction.numberOfPackings}`;
        this.toScan = shipmentScanTransaction.toScan;
        this.loosePackings = shipmentScanTransaction.loosePackings;
        this.openPackings = shipmentScanTransaction.openPackings;
        this.sealedPackings = shipmentScanTransaction.sealedPackings;
        this.boxRows = shipmentScanTransaction.shipment.boxRows;

        this.boxRows.forEach((boxrow) => {
            boxrow.orderAndBoxNumberCombined = `${boxrow.orderNumber}.${boxrow.boxNumber}`;
            if (boxrow.rowStatus === 2) boxrow.hasLabels = true;
            else boxrow.hasLabels = false;
        });
    }
}
