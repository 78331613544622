import { Component, OnInit } from '@angular/core';
import { Measure } from '../../../models/measure';
import { AbsApiService } from '../../../services/abs-api.service';
import { ToastService } from '../../../components/toast/toast.service';
import { AddMeasurementsTask } from '../../../models/add-measurement-task';
import { MeasurementPointResult } from '../../../models/measure-point-result';
import { EntityIdentification } from '../../../utilities/entity-identification';
import { MeasurementType } from '../../../models/measurement-type';
import { Label } from '../../../models/label.model';
import { RawMeasurement } from '../../../models/raw-measurement';
import { AddMeasurementsResults } from '../../../models/add-measurements-results';
import { Subscription } from 'rxjs';
import { commonViewImports } from '../../../utilities/global-imports';
import { ButtonDirective } from '../../../directives/button.directive';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    standalone: true,
    templateUrl: './measuring-details-page.component.html',
    styleUrl: './measuring-details-page.component.scss',
    imports: [commonViewImports, ButtonDirective],
})
export class MeasuringDetailsPageComponent implements OnInit {
    values: string[] = [];
    measures: Measure[] = [];
    currentlyEditablePosition: number = 0;

    get measureResult(): string {
        return this._measureResult;
    }

    set measureResult(value: string) {
        this._measureResult = value;
    }

    get measurementInputError(): boolean {
        return this._measurementInputError;
    }

    set measurementInputError(value: boolean) {
        this._measurementInputError = value;
    }

    private currentMeasurements: AddMeasurementsTask = {
        measurementTransactionKey: 0,
        measurementPointKey: 0,
        rawMeasurements: [],
    };

    private measurementPointKey: number = 0;
    private selectedMeasurementPoint: MeasurementPointResult | undefined;
    private selectedMeasure: Measure | undefined;
    private _measureResult: string = '';
    private _measurementInputError: boolean = false;
    private scanSubscription: Subscription | undefined;

    constructor(
        private readonly absApiService: AbsApiService,
        private readonly toastService: ToastService,
        route: ActivatedRoute,
        private router: Router
    ) {
        this.measurementPointKey = route.snapshot.params['measurementPointKey'];
    }

    async ngOnInit(): Promise<void> {
        await this.getMeasurementPointAsync(this.measurementPointKey);
    }

    protected async executeScanActionAsync(): Promise<void> {
        if (this.currentlyEditablePosition > this.measures.length - 1) {
            this.toastService.danger('Scan not allowed', 'First commit or cancel the measurements');
            return;
        }

        const labelMeasure = this.currentMeasurements.rawMeasurements.find((rw) => rw.measurementType === MeasurementType.LabelId) ?? null;
        const measurementType = MeasurementType[this.measures[this.currentlyEditablePosition].measureId];

        try {
            switch (measurementType) {
                case 'LabelId':
                    // eslint-disable-next-line no-case-declarations
                    const labelId: string = this.validateLabelId(this.values[this.currentlyEditablePosition]);
                    if (labelId !== null)
                        await this.handleMeasurement(
                            MeasurementType.LabelId,
                            null,
                            labelId,
                            this.measures[this.currentlyEditablePosition].measureKey,
                            this.currentMeasurements.measurementTransactionKey,
                            this.selectedMeasurementPoint.measurementPoint.measurementPointKey,
                            null
                        );
                    break;
                case 'Packing':
                    await this.handleMeasurement(
                        MeasurementType.Packing,
                        labelMeasure.label,
                        labelMeasure.labelId,
                        this.measures[this.currentlyEditablePosition].measureKey,
                        this.currentMeasurements.measurementTransactionKey,
                        this.selectedMeasurementPoint.measurementPoint.measurementPointKey,
                        this.values[this.currentlyEditablePosition]
                    );
                    break;
                case 'Weight':
                    await this.handleMeasurement(
                        MeasurementType.Weight,
                        labelMeasure.label,
                        labelMeasure.labelId,
                        this.measures[this.currentlyEditablePosition].measureKey,
                        this.currentMeasurements.measurementTransactionKey,
                        this.selectedMeasurementPoint.measurementPoint.measurementPointKey,
                        this.values[this.currentlyEditablePosition]
                    );
                    break;
                case 'Custom':
                    await this.handleMeasurement(
                        MeasurementType.Custom,
                        labelMeasure?.label,
                        labelMeasure?.labelId,
                        this.measures[this.currentlyEditablePosition].measureKey,
                        this.currentMeasurements.measurementTransactionKey,
                        this.selectedMeasurementPoint.measurementPoint.measurementPointKey,
                        this.values[this.currentlyEditablePosition]
                    );
                    break;
            }
        } catch {
            this.toastService.danger('Measurement', 'Something went wrong during the measurement');
            this.measurementInputError = true;
        }
    }

    private validateLabelId(value: string): string {
        const entityIdentification: EntityIdentification | Error = EntityIdentification.validateBarcode(value);

        if (entityIdentification instanceof Error) {
            this.measurementInputError = true;
            this.toastService.danger('Barcode', `Invalid barcode scanned ${value}`);
            return null;
        } else {
            const labelId = entityIdentification.entityKey ?? parseInt(value, 10);
            return labelId.toString();
        }
    }

    private async handleMeasurement(measurementType: MeasurementType, label: Label, labelId: string, measureKey: number, measurementTransactionKey: number, measurementPointKey: number, measurementValue: string): Promise<void> {
        const rawMeasurement: RawMeasurement = {
            label: label,
            labelId: labelId,
            measureKey: measureKey,
            measurementTransactionKey: measurementTransactionKey,
            value: measurementValue,
        };

        const task: AddMeasurementsTask = {
            measurementTransactionKey,
            measurementPointKey,
            rawMeasurements: [rawMeasurement],
        };

        const result = await this.absApiService.addMeasurementsResultAsync(task);

        const createdRawMeasurement = result.addMeasurementsTask.rawMeasurements[0];

        createdRawMeasurement.measurementType = measurementType;

        this.currentMeasurements.rawMeasurements.push(createdRawMeasurement);

        if (this.currentMeasurements.measurementTransactionKey === 0) this.currentMeasurements.measurementTransactionKey = createdRawMeasurement.measurementTransactionKey ?? 0;

        if (result.addMeasurementResults === AddMeasurementsResults.MeasurementTransactionOk) {
            switch (measurementType) {
                case MeasurementType.LabelId:
                    this.measures[this.currentlyEditablePosition].value = result.addMeasurementsTask.rawMeasurements[0].labelId;
                    this.measureResult = result.addMeasurementsTask.rawMeasurements[0].labelId;
                    break;
                case MeasurementType.Custom:
                    this.measures[this.currentlyEditablePosition].value = result.validateResult ?? result.addMeasurementsTask.rawMeasurements[0]?.value;
                    this.measureResult = result.validateResult ?? result.addMeasurementsTask.rawMeasurements[0]?.value;
                    break;
                default:
                    this.measures[this.currentlyEditablePosition].value = result.addMeasurementsTask.rawMeasurements[0].value;
                    this.measureResult = result.addMeasurementsTask.rawMeasurements[0].value;
                    break;
            }

            await this.moveNext();
        } else {
            let error = '';
            switch (measurementType) {
                case MeasurementType.LabelId:
                    error = result.addMeasurementResults === AddMeasurementsResults.LabelNotFound ? `Label ${labelId} not found` : `Something went wrong scanning label: ${labelId}`;
                    break;
                default:
                    error = `Invalid input: ${measurementValue}`;
                    break;
            }

            this.measurementInputError = true;
            this.toastService.danger('Error', error);
        }
    }

    private async moveNext(): Promise<void> {
        const nrOfMeasures: number = this.measures.length - 1;
        const oldPosition: number = this.currentlyEditablePosition;

        if (nrOfMeasures > this.currentlyEditablePosition) {
            this.currentlyEditablePosition++;
            this.selectedMeasure = oldPosition === this.currentlyEditablePosition ? this.measures[this.currentlyEditablePosition] : this.selectedMeasure;
        } else if (nrOfMeasures === this.currentlyEditablePosition) this.currentlyEditablePosition++;

        const completed: boolean = this.measures.every((m) => !!m.value);

        if (completed) {
            await new Promise((resolve) => setTimeout(resolve, 500));
            this.toastService.success('Success', 'Completed all measures');
            this.router.navigateByUrl('/measuring');
        }
    }

    private async getMeasurementPointAsync(measurementPointKey: number): Promise<void> {
        const measurementPoint = await this.absApiService.getMeasurementPointAsync(measurementPointKey);

        if (!measurementPoint) {
            this.toastService.danger('Measurement point', `Measurement point could not be found for key: ${measurementPointKey}`);
            return;
        }

        if (measurementPoint.measures.length === 0) {
            this.toastService.danger('Measurement point', 'This measurement point is invalid');
            return;
        }

        this.currentMeasurements.measurementPointKey = measurementPointKey;

        this.measures.push(...measurementPoint.measures.sort((a, b) => (a.measureKey ?? 0) - (b.measureKey ?? 0)));

        this.selectedMeasurementPoint = measurementPoint;
        this.selectedMeasure = this.measures[0];

        this.toastService.success('Measurement point', `Selected ${measurementPoint.measurementPoint.description}`);
    }
}
