<div class="ax-loader__container" [ngClass]="isFullPage ? 'ax-loader__container--full-page' : ''">
    <svg class="ax-loader__image" [ngClass]="isFullPage ? 'ax-loader__image--full-page' : ''" attr.width="{{width}}px" attr.heigt="{{height}}px" viewBox="0 0 164 160" fill="none">
        <circle id="circle-14" cx="117.218" cy="126.14" r="13" fill="#95BD0C"/>
        <circle id="circle-13" cx="84.0159" cy="140.145" r="17.5" fill="#E30D70"/>
        <circle id="circle-12" cx="49.7562" cy="126.14" r="13" fill="#FFCD00"/>
        <circle id="circle-11" cx="139.223" cy="100.118" r="17.5" fill="#009EDE"/>
        <circle id="circle-10" cx="104.718" cy="93.4465" r="12.5" fill="#E1001B"/>
        <circle id="circle-9" cx="84.0159" cy="110.118" r="10" fill="#00722E"/>
        <circle id="circle-8" cx="62.2562" cy="93.4465" r="12.5" fill="#009EDE"/>
        <circle id="circle-7" cx="24.1588" cy="100.852" r="17.5" fill="#E9690C"/>
        <circle id="circle-6" cx="127.348" cy="62.5" r="17.5" fill="#95BD0C"/>
        <circle id="circle-5" cx="84.0159" cy="62.5" r="17.5" fill="#E9690C"/>
        <circle id="circle-4" cx="40.684" cy="60.8149" r="17.5" fill="#95BD0C"/>
        <circle id="circle-3" cx="117.83" cy="28.9372" r="12.5" fill="#9661A0"/>
        <circle id="circle-2" cx="84.0159" cy="18.5839" r="17.5" fill="#FFCD00"/>
        <circle id="circle-1" cx="51.8515" cy="28.9372" r="12.5" fill="#E9690C"/>
    </svg>
    <h3 class="ax-loader__text">{{loaderText}}</h3>
</div>