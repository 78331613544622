import { ApplicationRef, ComponentRef, createComponent, EnvironmentInjector, Inject, Injectable, Injector, StaticProvider } from '@angular/core';
import { ToastComponent } from './toast.component';
import * as _ from 'lodash';
import { TOAST_DATA, ToastData } from './interfaces/toast-data';
import { TOAST_CONFIG, ToastConfig, ToastGlobalConfig } from './interfaces/toast-config';
import { Toolkit } from '../../utilities/toolkit';
import { ScanService } from '../../services/scan.service';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private readonly toastReferences: ComponentRef<ToastComponent>[] = [];
    private toastContainer: HTMLDivElement;

    constructor(
        @Inject(TOAST_CONFIG) private readonly globalConfig: ToastGlobalConfig,
        private readonly applicationRef: ApplicationRef,
        private readonly injector: EnvironmentInjector,
        private readonly scanService: ScanService
    ) {}

    success(title?: string, message?: string, override: Partial<ToastConfig> = {}): ToastComponent {
        const toastComponent = this.open('success', title, message, override);
        this.scanService.successSound();
        return toastComponent;
    }

    danger(title?: string, message?: string, override: Partial<ToastConfig> = {}): ToastComponent {
        const toastComponent = this.open('danger', title, message, override);
        this.scanService.errorSound();
        return toastComponent;
    }

    warning(title?: string, message?: string, override: Partial<ToastConfig> = {}): ToastComponent {
        const toastComponent = this.open('warning', title, message, override);
        this.scanService.warningSound();
        return toastComponent;
    }

    info(title?: string, message?: string, override: Partial<ToastConfig> = {}): ToastComponent {
        return this.open('info', title, message, override);
    }

    remove(toastId: string): void {
        const toastRef: ComponentRef<ToastComponent> = this.toastReferences.find((x: ComponentRef<ToastComponent>) => x.instance.id === toastId);

        this.removeAndDestroy(toastRef);
    }

    private removeAndDestroy(toastRef: ComponentRef<ToastComponent>): void {
        if (toastRef !== undefined && toastRef !== null) {
            const index: number = this.toastReferences.indexOf(toastRef);

            if (index > -1) {
                this.toastReferences.splice(index, 1);

                toastRef.instance.handleDestory();
                _.delay(() => {
                    toastRef.destroy();
                    if (this.toastReferences.length === 0) this.destroyContainer();
                }, 300);
            }
        }
    }

    private open(type: string, title?: string, message?: string, override: Partial<ToastConfig> = {}): ToastComponent {
        this.createContainerIfNotExists();

        if (this.globalConfig.prevent_duplicates) {
            const duplicateToast: ToastComponent = this.getDuplicate(title, message);

            if (duplicateToast !== undefined && duplicateToast !== null) return duplicateToast;
        }

        const config: ToastGlobalConfig = this.buildConfig(override);
        const toastData: ToastData = {
            id: Toolkit.generateId(10),
            title: title,
            message: message,
            type: type,
            has_close_button: config.has_close_button,
            click_to_dispose: config.click_to_dispose,
        };

        const ref: ComponentRef<ToastComponent> = createComponent(ToastComponent, {
            environmentInjector: this.injector,
            elementInjector: this.createInjector(toastData),
        });

        if (this.globalConfig.max_opened > 0 && this.toastReferences.length === this.globalConfig.max_opened) {
            const firstRef: ComponentRef<ToastComponent> = this.toastReferences[0];
            this.removeAndDestroy(firstRef);

            _.delay(() => {
                this.toastContainer.appendChild(ref.location.nativeElement);
                this.applicationRef.attachView(ref.hostView);
            }, 300);
        } else {
            this.toastContainer.appendChild(ref.location.nativeElement);
            this.applicationRef.attachView(ref.hostView);
        }

        ref.instance.onDispose.subscribe(() => {
            this.removeAndDestroy(ref);
        });

        if (!config.disable_timeout)
            _.delay(() => {
                this.removeAndDestroy(ref);
            }, config.delay);

        this.toastReferences.push(ref);

        return ref.instance;
    }

    private createContainerIfNotExists(): void {
        if (this.toastContainer === undefined || this.toastContainer === null) {
            this.toastContainer = document.createElement('div');
            this.toastContainer.classList.add('ax-toast__component__container');

            const positionClass = `ax-toast__component__container--${this.globalConfig.position}`;

            this.toastContainer.classList.add(positionClass);

            document.body.appendChild(this.toastContainer);
        }
    }

    private destroyContainer(): void {
        _.delay(() => {
            if (this.toastContainer !== undefined && this.toastContainer !== null) {
                document.body.removeChild(this.toastContainer);
                this.toastContainer = null;
            }
        }, 400);
    }

    private createInjector(data: ToastData): Injector {
        const providers: StaticProvider[] = [{ provide: TOAST_DATA, useValue: data }];

        return Injector.create({ providers: providers });
    }

    private buildConfig(override: Partial<ToastConfig> = {}): ToastGlobalConfig {
        return { ...this.globalConfig, ...override };
    }

    private getDuplicate(title?: string, message?: string): ToastComponent {
        const toast: ComponentRef<ToastComponent> = this.toastReferences.find((x: ComponentRef<ToastComponent>) => x.instance.title === title && x.instance.message === message);

        if (toast !== null && toast !== undefined) return toast.instance;

        return null;
    }
}
