/* eslint-disable curly */
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Route, Router } from '@angular/router';
import _ from 'lodash';
import { environment } from '../../../environments/environment';
import { AvatarComponent } from '../../components/avatar/avatar.component';
import { LoaderComponent } from '../../components/loader/loader.component';
import { SidePanelComponent } from '../../components/side-panel/side-panel.component';
import { routes } from '../../config/app.routes';
import { ButtonDirective } from '../../directives/button.directive';
import { ContextMenuService } from '../../menus/context-menu.service';
import { UserContextMenuComponent } from '../../menus/user-context-menu/user-context-menu.component';
import { ApplicationService } from '../../services/application.service';
import { AuthService } from '../../services/auth.service';
import { angularImports } from '../../utilities/global-imports';
import { Breadcrumb } from './interfaces/breadcrumb';
import { NavigationMenuItem } from './interfaces/navigation-menu-item';
import { firstValueFrom } from 'rxjs';
import { InputDirective } from '../../directives/input.directive';
import { BarcodeComponent } from '../../components/barcode/barcode.component';
import { LocalSettings } from '../../models/local-settings';
import { SettingsHelper } from '../../utilities/settings-helper';
import { ApplicationInsightsService } from '../../services/application-insights.service';
import { AccessService } from '../../services/access.service';
import { Tenant } from '../../models/tenant.model';
import { Location } from '@angular/common';

@Component({
    standalone: true,
    selector: 'ax-page-template',
    templateUrl: './page-template.component.html',
    styleUrl: './page-template.component.scss',
    imports: [angularImports, ButtonDirective, InputDirective, UserContextMenuComponent, AvatarComponent, SidePanelComponent, LoaderComponent, BarcodeComponent],
})
export class PageTemplateComponent implements OnInit {
    @ViewChild(SidePanelComponent) private readonly navigationPanel: SidePanelComponent;

    @HostListener('window:resize')
    public onResize(): void {
        this.isDesktop = window.matchMedia('(min-width: 1280px)').matches;
    }

    protected isDesktop: boolean = window.matchMedia('(min-width: 1280px)').matches;

    protected showBreadCrumbs: boolean = false;
    protected showBarcode: boolean = false;
    protected breadCrumbs: Breadcrumb[] = [];
    protected userName: string = '';
    protected navigationItems: NavigationMenuItem[] = [];
    protected version: string = environment.version;
    protected isAuthenticated: boolean = false;
    protected isLoading: boolean = false;
    protected barcode: string = '';
    protected barcodeComponentPlaceholder: string = 'Enter a barcode';
    protected tenant: Tenant;

    private ignoreBreadcrumbs: string[] = ['/', '/home', '/403', '/404'];
    private ignoredMenuItemRoutes: string[] = ['', '**', '403', '404', 'settings', 'development'];
    private ignoreBarcode: string[] = ['/', '/home', '/403', '/404', '/settings', '/putaway', '/distribution', '/sales', '/development'];

    constructor(
        private readonly menuService: ContextMenuService,
        private readonly authService: AuthService,
        private readonly applicationService: ApplicationService,
        private readonly router: Router,
        private readonly aiService: ApplicationInsightsService,
        private readonly accessService: AccessService,
        private readonly location: Location
    ) {}

    async ngOnInit(): Promise<void> {
        try {
            this.isLoading = true;

            this.authService.isAuthenticated.subscribe((isAuthenticated: boolean) => {
                this.userName = this.authService.getUserData().preferred_username;
                this.isAuthenticated = isAuthenticated;
            });

            this.applicationService.onTenantSwitched.subscribe(() => {
                this.buildNavigationMenu();
            });

            this.router.events.subscribe((event: Event | unknown) => {
                if (event instanceof NavigationEnd) {
                    const navigationEndEvent: NavigationEnd = event as NavigationEnd;
                    this.setShowProperties(navigationEndEvent.urlAfterRedirects);
                    if (this.showBreadCrumbs) this.buildBreadcrumbs(navigationEndEvent.urlAfterRedirects);
                }
            });

            await firstValueFrom(this.applicationService.initialized);
            this.setShowProperties(this.router.routerState.snapshot.url);
            if (this.showBreadCrumbs) this.buildBreadcrumbs(this.router.routerState.snapshot.url);

            this.buildNavigationMenu();
            this.tenant = this.applicationService.getTenant();
        } finally {
            this.isLoading = false;
        }
    }

    setBarcode(barcode: string): void {
        this.barcode = barcode;
    }

    setBarcodePlaceholder(placeholder: string): void {
        if (_.isEmpty(placeholder)) return;

        this.barcodeComponentPlaceholder = placeholder;
    }

    protected toggleNavigationPanel(event: MouseEvent): void {
        event.stopPropagation();
        this.navigationPanel.toggle();
        this.aiService.logEvent('navigation-menu-toggle');
    }

    protected openUserPanel(event: Event): void {
        event.stopPropagation();
        this.aiService.logEvent('user-menu-toggle');

        let isAdminUser: boolean = false;
        const userRole: string | undefined = this.authService.getUserData().role;

        if (!_.isNil(userRole) && !_.isEmpty(userRole)) isAdminUser = userRole.toLowerCase() === 'admin';

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const menuData: any = {
            show_tenant_switch_option: this.applicationService.getTenants().length > 1 ? true : false,
            show_environment_switch_option: isAdminUser,
        };

        this.menuService.toggleContextMenu('user-context-menu', UserContextMenuComponent, menuData, event.target as HTMLElement, {
            position: 'bottom',
            elementSpacing: 8,
        });
    }

    protected handleNavigation(url: string): void {
        this.aiService.logEvent('navigation-item-click', {
            route: url,
        });
        this.navigationPanel.toggle();
        this.router.navigateByUrl(url);
    }

    protected handleBreadCrumb(url: string): void {
        this.aiService.logEvent('breadcrumb-click', {
            route: url,
        });
        this.router.navigateByUrl(url);
    }

    protected navigateBack(): void {
        this.location.back();
    }

    private buildNavigationMenu(): void {
        this.navigationItems = [];
        routes.forEach(async (route: Route) => {
            if (this.ignoredMenuItemRoutes.includes(route.path)) {
                return;
            }

            if (!route.path.includes('/')) {
                let iconClass: string | undefined = undefined;

                if (!_.isNil(route.data)) {
                    iconClass = route.data['iconClass'];
                }

                let hasMenuAccess: boolean = false;

                if (!_.isNil(route.data)) {
                    const accessKey: string | undefined | null = route.data['access_key'] as string;

                    if (_.isNil(accessKey) || _.isEmpty(accessKey)) {
                        return;
                    }

                    hasMenuAccess = await this.accessService.hasAccess(accessKey);
                }

                if (hasMenuAccess) {
                    this.navigationItems.push({
                        route: `/${route.path}`,
                        title: route.title as string,
                        icon_class: iconClass,
                        child_routes: [],
                    });
                }
            } else {
                const navigationItem: NavigationMenuItem = this.navigationItems.find((item: NavigationMenuItem) => item.route.includes(route.path.split('/')[0]));
                let iconClass: string | undefined = '';

                if (!_.isNil(route.data)) iconClass = route.data['iconClass'];

                if (navigationItem !== undefined)
                    navigationItem.child_routes.push({
                        route: route.path,
                        title: route.title as string,
                        icon_class: iconClass,
                        child_routes: [],
                    });
            }
        });
    }

    private setShowProperties(fullPath: string): void {
        const settings: LocalSettings = SettingsHelper.loadSettings();

        this.showBarcode = settings.show_barcode_bar && !this.ignoreBarcode.includes(fullPath);
        this.showBreadCrumbs = settings.show_breadcrumb_bar && !this.ignoreBreadcrumbs.includes(fullPath);
    }

    private buildBreadcrumbs(fullPath: string): void {
        this.breadCrumbs = [];
        const sanitizedUrl: string = fullPath.substring(1);

        if (sanitizedUrl.includes('/')) {
            const splittedRoutes: string[] = sanitizedUrl.split('/');

            // TODO: Add another if check when we have deeper nesting
            if (splittedRoutes.length === 2) {
                const firstRoute: Route | undefined = routes.find((x: Route) => x.path === splittedRoutes[0]);
                const secondRoute: Route | undefined = routes.find((x: Route) => x.path === sanitizedUrl);

                if (firstRoute)
                    this.breadCrumbs.push({
                        display_name: firstRoute.title as string,
                        route: firstRoute.path,
                    });

                if (secondRoute)
                    this.breadCrumbs.push({
                        display_name: secondRoute.title as string,
                        route: secondRoute.path,
                    });
            }
        } else {
            const route: Route | undefined = routes.find((x: Route) => x.path === sanitizedUrl);

            if (route)
                this.breadCrumbs.push({
                    display_name: route.title as string,
                    route: route.path,
                });
        }
    }
}
