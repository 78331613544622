import { Component, HostListener, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { commonViewImports } from '../../utilities/global-imports';
import { TableComponent } from '../../components/table/table.component';
import { ListViewComponent } from '../../components/list-view/list-view.component';
import { ValidationHost } from '../../validator/validation-host';
import { SelectOption } from '../../components/select/interfaces/select-option';
import { ValidationRule } from '../../validator/rules/validation-rule';
import { TableColumn } from '../../components/table/interfaces/table-column';
import { TableColumnType } from '../../components/table/enums/table-column-property-type';
import { TableColumnOptionKey } from '../../components/table/enums/table-column-option-key';
import { ListViewItemDefinition } from '../../components/list-view/interfaces/list-view-item-definition';
import { ListViewDefinitionType } from '../../components/list-view/enums/list-view-item-definition-type';
import { ListViewItemDefinitionOptionKey } from '../../components/list-view/enums/list-view-item-definition-option-key';
import { ToastComponent } from '../../components/toast/toast.component';
import { ValidateDirective } from '../../validator/directives/validate.directive';
import { ValidateErrorDirective } from '../../validator/directives/validate-error.directive';
import { ToastService } from '../../components/toast/toast.service';
import { Validator } from '../../validator/validator';
import { SortDirection } from '../../components/table/enums/sort-direction';
import { CustomValidationRule, CustomValidationRuleValidateResult } from '../../validator/rules/custom-validation-rule';
import _ from 'lodash';

@Component({
    standalone: true,
    selector: 'ax-development-page',
    templateUrl: './development-page.component.html',
    styleUrl: './development-page.component.scss',
    imports: [commonViewImports, TableComponent, ListViewComponent],
})
export class DevelopmentPageComponent implements OnInit, ValidationHost {
    @HostListener('window:resize')
    public onResize(): void {
        this.isMobile = window.matchMedia('(max-width: 767px)').matches;
    }

    protected isMobile: boolean = window.matchMedia('(max-width: 767px)').matches;
    protected isLoading: boolean = false;
    protected testValue: string;
    protected disabledValue: string;
    protected selectedValue: string;

    protected selectOptions: SelectOption[] = [
        { value: '1', display_value: 'First Value' },
        { value: '2', display_value: 'Second Value' },
    ];

    protected validationRules: ValidationRule[] = [];

    protected tableColumns: TableColumn[] = [
        {
            caption: 'Title',
            property_name: 'title',
            type: TableColumnType.Default,
            is_sortable: true,
        },
        {
            caption: 'Child Element',
            property_name: 'root_element.child_element',
            type: TableColumnType.Default,
        },
        {
            caption: 'Date',
            property_name: 'date',
            type: TableColumnType.Date,
        },
        {
            caption: 'Date Time',
            property_name: 'dateTime',
            type: TableColumnType.DateTime,
        },
        {
            caption: 'Time',
            property_name: 'time',
            type: TableColumnType.Time,
        },
        {
            caption: 'Time Ago',
            property_name: 'timeago',
            type: TableColumnType.TimeAgo,
        },
        {
            caption: 'Booleans',
            property_name: 'bool',
            type: TableColumnType.Boolean,
            options: [
                {
                    key: TableColumnOptionKey.DisplayBooleanAsIcon,
                    value: true,
                },
            ],
        },
        {
            caption: 'Custom Booleans',
            property_name: 'bool',
            type: TableColumnType.Boolean,
            options: [
                {
                    key: TableColumnOptionKey.BooleanDisplayTrueValue,
                    value: 'YES!',
                },
                {
                    key: TableColumnOptionKey.BooleanDisplayFalseValue,
                    value: 'NOPE!',
                },
            ],
        },
    ];

    protected listViewDefinitions: ListViewItemDefinition[] = [
        {
            caption: 'Title',
            property_name: 'title',
            type: ListViewDefinitionType.Primary,
        },
        {
            caption: 'Child Element',
            property_name: 'root_element.child_element',
            type: ListViewDefinitionType.Default,
        },
        {
            caption: 'Date',
            property_name: 'date',
            type: ListViewDefinitionType.Date,
        },
        {
            caption: 'Date Time',
            property_name: 'dateTime',
            type: ListViewDefinitionType.DateTime,
        },
        {
            caption: 'Time',
            property_name: 'time',
            type: ListViewDefinitionType.Time,
        },
        {
            caption: 'Time Ago',
            property_name: 'timeago',
            type: ListViewDefinitionType.TimeAgo,
        },
        {
            caption: 'Booleans',
            property_name: 'bool',
            type: ListViewDefinitionType.Boolean,
            options: [
                {
                    key: ListViewItemDefinitionOptionKey.DisplayBooleanAsIcon,
                    value: true,
                },
            ],
        },
        {
            caption: 'Custom Booleans',
            property_name: 'bool',
            type: ListViewDefinitionType.Boolean,
            options: [
                {
                    key: ListViewItemDefinitionOptionKey.BooleanDisplayTrueValue,
                    value: 'YES!',
                },
                {
                    key: ListViewItemDefinitionOptionKey.BooleanDisplayFalseValue,
                    value: 'NOPE!',
                },
            ],
        },
    ];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected tableData: any[] = [];

    private validator: Validator;
    private openWarningToast: ToastComponent | undefined;

    @ViewChildren(ValidateDirective) private readonly validateDirectives: ValidateDirective[];
    @ViewChildren(ValidateErrorDirective) private readonly validateErrorDirectives: ValidateErrorDirective[];
    @ViewChild('listView') private readonly listViewComponent: ListViewComponent;

    constructor(private readonly toastService: ToastService) {}

    ngOnInit(): void {
        try {
            this.isLoading = true;
            this.validator = new Validator(this);
            this.setCustomValidationRules();
            this.loadMockData();
        } catch (err) {
            this.toastService.danger('Loading error', err as string);
        } finally {
            this.isLoading = false;
        }
    }

    getValidationItems(): ValidateDirective[] {
        return this.validateDirectives;
    }

    getValidationErrorItems(): ValidateErrorDirective[] {
        return this.validateErrorDirectives;
    }

    post(): void {
        try {
            this.validator.resetValidationStates();
            const isValid: boolean = this.validator.validate();

            if (!isValid)
                this.openWarningToast = this.toastService.warning('Validation error', 'Missing required fields', {
                    disable_timeout: true,
                });
            else {
                if (this.openWarningToast !== undefined) this.toastService.remove(this.openWarningToast.id);

                this.openWarningToast = undefined;
                this.toastService.success('Success', 'Send a message to the void');
            }
        } catch (err) {
            this.toastService.danger('Error', err as string);
        }
    }

    onSort(column: TableColumn): void {
        if (column.property_name === 'title')
            switch (column.sort_direction) {
                case SortDirection.Ascending:
                    this.tableData.sort((a: any, b: any) => {
                        if (a.title < b.title) return -1;

                        if (a.title > b.title) return 1;

                        return 0;
                    });
                    break;

                case SortDirection.Descending:
                    this.tableData.sort((a: any, b: any) => {
                        if (a.title > b.title) return -1;

                        if (a.title < b.title) return 1;

                        return 0;
                    });
                    break;

                case SortDirection.None:
                    this.tableData = [];
                    this.loadMockData();
                    break;
            }
    }

    showLoader(): void {
        this.isLoading = true;

        _.delay(() => {
            this.isLoading = false;
        }, 5000);
    }

    clearData(): void {
        this.tableData = [];
    }

    reloadData(): void {
        this.tableData = [];
        this.loadMockData();
    }

    checkAllRows(isChecked: boolean): void {
        if (isChecked) this.listViewComponent.selectAllRows();
        else this.listViewComponent.deSelectAllRows();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowClicked(data: any): void {
        window.alert(JSON.stringify(data));
    }

    private setCustomValidationRules(): void {
        const func = <T>(value: T): CustomValidationRuleValidateResult => {
            const val: string = value as string;

            if (!val.startsWith('Axerrio')) return new CustomValidationRuleValidateResult(false, "must start with 'Axerrio'");
            else return new CustomValidationRuleValidateResult(true, '');
        };

        this.validationRules.push(new CustomValidationRule('test', func));
    }

    private loadMockData(): void {
        for (let i: number = 0; i < 100; i++)
            this.tableData.push({
                title: `Title ${i}`,
                root_element: {
                    child_element: `Child ${i}`,
                },
                date: '2024-02-08 13:00:05',
                dateTime: '2024-02-08 13:00:05',
                time: '12:53:06',
                timeago: Date.now().toString(),
                bool: i % 2 === 0 ? true : false,
            });
    }
}
