import { LocalSettings } from '../models/local-settings';
import _ from 'lodash';

export class SettingsHelper {
    static loadSettings(): LocalSettings {
        let settings: LocalSettings = {
            theme: null,
            locale: 'en-EN',
            show_barcode_bar: true,
            show_breadcrumb_bar: false,
            enable_sound: true,
        };

        const settingsString: string | null = localStorage.getItem('settings');

        if (_.isNil(settingsString)) localStorage.setItem('settings', JSON.stringify(settings));
        else {
            settings = JSON.parse(settingsString);
            this.validateSettings(settings);
        }

        return settings;
    }

    static updateSettings(settings: LocalSettings): void {
        localStorage.setItem('settings', JSON.stringify(settings));
    }

    private static validateSettings(settings: LocalSettings): void {
        if (_.isUndefined(settings.theme)) settings.theme = null;

        if (_.isUndefined(settings.locale)) settings.locale = 'en-EN';

        if (_.isUndefined(settings.show_barcode_bar)) settings.show_barcode_bar = true;

        if (_.isUndefined(settings.show_breadcrumb_bar)) settings.show_breadcrumb_bar = false;

        if (_.isUndefined(settings.enable_sound)) settings.enable_sound = true;
    }
}
