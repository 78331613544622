<div class="ax-table__container" (scroll)="onScrolled($event)">
    <table #tableElement class="ax-table">
        <colgroup>
            @if (hasMultiSelectEnabled) {
                <col class="ax-table__col__multi-select">
            }
            
            @for (column of columns; track column.property_name) {
                <col>
            }
        </colgroup>
        <thead>
            @if (hasMultiSelectEnabled) {
                <th>
                    <ax-checkbox [isChecked]="hasAllRowsChecked" [stopPropagationOnChange]="false" (click)="selectAllRows($event)"></ax-checkbox>
                </th>
            }

            @for (column of columns; track column.property_name) {
                <th (click)="onSort(column)" [ngClass]="setColumnCaptionClasses(column)">
                    @if (column.is_sortable) {
                        <i class="ax-table__sort-icon fa-duotone fa-sort" [ngClass]="setSortDirectionClass(column)"></i>
                    }
                    <span>{{column.caption}}</span>
                </th>
            }
        </thead>
        @if ((data && data.length > 0) && loaderDelay < 25) {
            <tbody>
                @for (entity of data; track entity; let dataIndex = $index) {
                    <tr (click)="selectRow(dataIndex, $event)" [ngClass]="setRowClasses(dataIndex)">
                        @if (hasMultiSelectEnabled) {
                            <td>
                                <ax-checkbox [isChecked]="isSelectedRow(dataIndex)" [stopPropagationOnChange]="false"></ax-checkbox>
                            </td>
                        }
                        @for (column of columns; track column.property_name) {
                            <td attr.data-propertyname="{{column.property_name}}" attr.data-index="{{dataIndex}}">
                                @switch (column.type) {
                                    @case (tablePropertyType.Default) {
                                        <span (click)="navigateTo(dataIndex, column, $event)" [ngClass]="setCellDefaultClasses(column)">{{getPropertyValue(entity, column.property_name)}}</span>
                                    }

                                    @case (tablePropertyType.DateTime) {
                                        <span>{{getPropertyValue(entity, column.property_name) | datetime}}</span>
                                    }

                                    @case (tablePropertyType.Date) {
                                        <span>{{getPropertyValue(entity, column.property_name) | date}}</span>
                                    }

                                    @case (tablePropertyType.Time) {
                                        <span>{{getPropertyValue(entity, column.property_name) | time}}</span>
                                    }

                                    @case (tablePropertyType.TimeAgo) {
                                        <span>{{getPropertyValue(entity, column.property_name) | timeago}}</span>
                                    }

                                    @case (tablePropertyType.Boolean) {
                                        <div class="ax-table__cell__boolean">
                                            @if (getColumnOptionValueAsBoolean(column, tableColumnOptionKey.DisplayBooleanAsIcon)) {
                                                <i [ngClass]="setCellBooleanIconClasses(getPropertyValue(entity, column.property_name))" class="fa-solid ax-table__cell__boolean__icon fa-xl"></i>
                                            }

                                            @if (!getColumnOptionValueAsBoolean(column, tableColumnOptionKey.DisplayBooleanAsIcon) && getPropertyValue(entity, column.property_name)) {
                                                <span>{{getColumnOptionValueAsString(column, tableColumnOptionKey.BooleanDisplayTrueValue)}}</span>
                                            }

                                            @if (!getColumnOptionValueAsBoolean(column, tableColumnOptionKey.DisplayBooleanAsIcon) && !getPropertyValue(entity, column.property_name)) {
                                                <span>{{getColumnOptionValueAsString(column, tableColumnOptionKey.BooleanDisplayFalseValue)}}</span>
                                            }
                                        </div>
                                    }

                                    @default {
                                        <span>{{getPropertyValue(entity, column.property_name)}}</span>
                                    }
                                }
                            </td>
                        }
                    </tr>
                }
            </tbody>
        }
    </table>
    @if (isLoading && loaderDelay > 25) {
        <div class="ax-table__loader">
            <ax-loader class="ax-table__loader__image" [width]="164" [height]="160" [loaderText]="loaderText"></ax-loader>
        </div>
    }

    @if (!isLoading && !data.length) {
        <div class="ax-table__no-results__container">
            <p class="ax-table__no-results__container__text" [innerHTML]="noDataText"></p>
        </div>
    }
</div>