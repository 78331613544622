<div class="transfer-overview__container">
    @if (partyStorageLocations.length > 0) {
        <div class="transfer-header">
            <h4>{{storageLocation.description}}</h4>
        </div>
        <ax-list-view
            [isLoading]="isLoading"
            [definitions]="listViewDefinitions"
            [data]="partyStorageLocations"
            [columnCount]="'auto'"
        ></ax-list-view>
    } @else {
        <div class="no__data__container">
            <i class="no__data__container__icon fa-light fa-boxes-stacked"></i>
            <h5>{{ getEmptyStateText() }}</h5>
        </div>
    }
</div>
