import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { DIALOG_DATA, DialogComponentRef } from '../../components/dialog/models/dialog-component-ref';
import { commonViewImports } from '../../utilities/global-imports';
import { ButtonDirective } from '../../directives/button.directive';
import _ from 'lodash';
import { Party } from '../../models/party.model';
import { ReceiveLotsTaskResult } from '../../models/receive-lots-task-result.model';
import { ReceiveLotsTask } from '../../models/receive-lots-task.model';
import { ValidateErrorDirective } from '../../validator/directives/validate-error.directive';
import { ValidateDirective } from '../../validator/directives/validate.directive';
import { ToastService } from '../../components/toast/toast.service';
import { AbsApiService } from '../../services/abs-api.service';
import { Validator } from '../../validator/validator';

@Component({
    standalone: true,
    templateUrl: './lot-details-dialog.component.html',
    styleUrl: './lot-details-dialog.component.scss',
    imports: [commonViewImports, ButtonDirective],
})
export class LotDetailsDialogComponent implements OnInit {
    description: string | null = null;
    logisticID: string | null = null;
    internalNumber: string | null = null;
    totalUnits: string | null = null;
    supplier: string | null = null;
    packingUnits: number | null = null;
    productUnits: string | null = null;
    moa: string | null = null;
    packing: string | null = null;

    private validator: Validator;

    @ViewChildren(ValidateDirective) private readonly validateDirectives: ValidateDirective[];
    @ViewChildren(ValidateErrorDirective) private readonly validateErrorDirectives: ValidateErrorDirective[];

    constructor(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        @Inject(DIALOG_DATA) private readonly data: any,
        private readonly dialogComponentRef: DialogComponentRef<LotDetailsDialogComponent>,
        private readonly absApiService: AbsApiService,
        private readonly toastService: ToastService
    ) {
        if (!_.isNil(data.description)) this.description = data.description;
        if (!_.isNil(data.logisticID)) this.logisticID = data.logisticID;
        if (!_.isNil(data.internalNumber)) this.internalNumber = data.internalNumber;
        if (!_.isNil(data.totalStemsParty)) this.totalUnits = data.totalStemsParty;
        if (!_.isNil(data.supplier.name)) this.supplier = data.supplier.name;
        if (!_.isNil(data.boxes)) this.packingUnits = data.boxes;
        if (!_.isNil(data.stems)) this.productUnits = data.stems;
        if (!_.isNil(data.availableForProcessingMoment)) this.moa = this.formatDate(data.availableForProcessingMoment);
        if (!_.isNil(data.packingCode)) this.packing = data.packingCode;
    }

    ngOnInit(): void {
        try {
            this.validator = new Validator(this);
        } catch (err) {
            this.toastService.danger('Loading error', err as string);
        }
    }

    getValidationItems(): ValidateDirective[] {
        return this.validateDirectives;
    }
    getValidationErrorItems(): ValidateErrorDirective[] {
        return this.validateErrorDirectives;
    }

    protected updatePackingUnit(): void {
        try {
            this.validator.resetValidationStates();
            const isValid: boolean = this.validator.validate();

            if (!isValid) this.toastService.warning('Validation error', 'Missing required fields');
            else {
                this.data.boxesToReceive = this.packingUnits;
                const selectedLot: Party[] = [this.data];
                const data: ReceiveLotsTask = {
                    lots: selectedLot,
                };

                this.absApiService
                    .savePartyIdentificationJob(data)
                    .then((result: ReceiveLotsTaskResult) => {
                        if (result.receivedLots.length === 0) this.toastService.danger('Error', 'Could not receive lot(s).');
                        else {
                            this.toastService.success('Success', `${result.receivedLots.length} lot(s) received.`);
                            this.dialogComponentRef.closeDialog(result);
                        }
                    })
                    .catch(() => {
                        this.toastService.danger('Error', 'An error occurred while receiving lots.');
                    });

                this.close();
            }
        } catch (err) {
            this.toastService.danger('Error', err as string);
        }
    }

    protected close(): void {
        this.dialogComponentRef.closeDialog();
    }

    private formatDate(dateString: string): string {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }
}
