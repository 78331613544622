/* eslint-disable curly */
import { Component, OnInit } from '@angular/core';
import { angularImports } from '../../../utilities/global-imports';
import { ButtonDirective } from '../../../directives/button.directive';
import { AccessService } from '../../../services/access.service';

@Component({
    standalone: true,
    selector: 'ax-putaway-page',
    templateUrl: './putaway-overview-page.component.html',
    styleUrl: './putaway-overview-page.component.scss',
    imports: [angularImports, ButtonDirective],
})
export class PutawayOverviewPageComponent implements OnInit {
    protected isLoading: boolean = false;
    protected showOnLocation: boolean = false;
    protected showTransfer: boolean = false;
    protected showOnContainer: boolean = false;

    constructor(private readonly accessService: AccessService) {}

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.showOnLocation = await this.accessService.hasAccess('putaway-on-location');
        this.showTransfer = await this.accessService.hasAccess('putaway-transfer');
        this.showOnContainer = await this.accessService.hasAccess('putaway-on-container');
        this.isLoading = false;
    }
}
