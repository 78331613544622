import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PageTemplateComponent } from '../../../templates/page-template/page-template.component';
import { ScanService } from '../../../services/scan.service';
import { ToastService } from '../../../components/toast/toast.service';
import { commonViewImports } from '../../../utilities/global-imports';
import { DialogService } from '../../../components/dialog/dialog.service';
import { AbsApiService } from '../../../services/abs-api.service';
import { PartyIdentifierTask } from '../../../models/party-identifier-task.model';
import { PartyIdentifierOptions } from '../../../models/party-identifier-options.model';
import { PartyIdentifierResult } from '../../../models/party-identifier-result.model';
import { PartyIdentifierResults } from '../../../models/party-identifier-results.enum';
import { Party } from '../../../models/party.model';
import { DistributeStockDialogComponent } from '../../../dialogs/distribute-stock-dialog/distribute-stock-dialog.component';
import _ from 'lodash';

@Component({
    standalone: true,
    selector: 'ax-distribution-stock-distribute-page',
    templateUrl: './stock-distribute-page.component.html',
    styleUrl: './stock-distribute-page.component.scss',
    imports: [commonViewImports],
})
export class StockDistributePageComponent implements OnInit, AfterViewInit, OnDestroy {
    private scanSubscription: Subscription;
    private barcode: string = '';

    constructor(
        private readonly pageTemplate: PageTemplateComponent,
        private readonly scanService: ScanService,
        private readonly toastService: ToastService,
        private readonly dialogService: DialogService,
        private readonly absApiService: AbsApiService
    ) {}

    ngOnInit(): void {
        this.scanSubscription = this.scanService.onScan.subscribe(async (barcode: string) => {
            this.barcode = barcode;

            const task: PartyIdentifierTask = {
                partyIdentifier: this.barcode,
                partyIdentifierOptions: new PartyIdentifierOptions(),
            };

            task.partyIdentifierOptions.useLogisticID = false;
            task.partyIdentifierOptions.useHamifleurBarcode = false;
            task.partyIdentifierOptions.useHoekhuisBarcode = false;
            task.partyIdentifierOptions.useKoperBarcode = false;
            task.partyIdentifierOptions.useLogisticalUnitsBarcode = false;
            task.partyIdentifierOptions.useVBAFloraBarcode = false;
            task.partyIdentifierOptions.useZandbergenBarcode = false;
            task.partyIdentifierOptions.searchInInternalNumber = true;
            task.partyIdentifierOptions.searchInCashAndCarryID = false;
            task.partyIdentifierOptions.restrictToPurchaseLots = false;

            try {
                const result: PartyIdentifierResult = await this.absApiService.getLotsByIdAsync(task);

                switch (result.partyIdentifierResults) {
                    case PartyIdentifierResults.partiesFound: {
                        if (result.foundLots.length > 1) this.toastService.danger('Party not found', `Party not found. (Scanned ${this.barcode})`);
                        else this.processLots(result);

                        break;
                    }
                    case PartyIdentifierResults.partiesNotFound: {
                        this.toastService.danger('Party not found', `Party not found. (Scanned ${this.barcode})`);
                        break;
                    }
                }
            } catch {
                this.toastService.danger('Party not found', `Party not found. (Scanned ${this.barcode})`);
            }
        });
    }

    processLots(lot: PartyIdentifierResult): void {
        const lotFound: Party = lot.foundLots[0];

        if (lotFound !== null)
            this.dialogService.createDialog<DistributeStockDialogComponent>(DistributeStockDialogComponent, {
                lot: lotFound,
                barcode: this.barcode,
            });
        else this.toastService.warning('Lot not found', `No lot found on '${this.barcode}'`);
    }

    ngAfterViewInit(): void {
        // Add a tiny delay to prevent race condition on change detection
        _.delay(() => {
            this.pageTemplate.setBarcodePlaceholder('Scan a barcode');
        }, 10);
    }

    ngOnDestroy(): void {
        this.scanSubscription.unsubscribe();
        this.pageTemplate.setBarcode('');
    }
}
