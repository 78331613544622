<div class="measuring-details__container">
    @for (measure of measures; track measure.measureId; let i = $index) {
        <div class="measure-values">
            <h4 class="measure-values__title">{{ measures[i].description }}</h4>
            <input
                type="text"
                ax-input
                placeholder="Fill in a value"
                [(ngModel)]="values[i]"
                [disabled]="i !== currentlyEditablePosition"
                (keyup.enter)="executeScanActionAsync()"
                >
        </div>
    }
</div>
