import { Component, Input } from '@angular/core';
import { angularImports } from '../../utilities/global-imports';

@Component({
    standalone: true,
    selector: 'ax-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    imports: [angularImports],
})
export class LoaderComponent {
    @Input() public width: number = 164;
    @Input() public height: number = 160;
    @Input() public loaderText: string = 'Loading';
    @Input() public isFullPage: boolean = false;
}
