import { Pipe, PipeTransform } from '@angular/core';
import { Converters } from '../utilities/converters';
import _ from 'lodash';

@Pipe({ name: 'timeago', standalone: true })
export class TimeAgoPipe implements PipeTransform {
    transform(value: string) {
        if (_.isNil(value) || _.isEmpty(value)) return '';

        return Converters.toTimeAgo(value);
    }
}
