/* eslint-disable curly */
import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogComponentRef } from '../../components/dialog/models/dialog-component-ref';
import { ButtonDirective } from '../../directives/button.directive';
import { ShipmentScanTransaction } from '../../models/shipment-scan-transaction.model';
import _ from 'lodash';
import { DateTimePipe } from '../../pipes/date-time.pipe';

@Component({
    standalone: true,
    templateUrl: './shipment-details-dialog.component.html',
    styleUrl: './shipment-details-dialog.component.scss',
    imports: [ButtonDirective, DateTimePipe],
})
export class ShipmentDetailsDialogComponent implements OnInit {
    protected destination: string = '';
    protected arrivalDate: Date;
    protected shipmentNumber: string = '';
    protected total: string = '';
    protected toScan: number = 0;
    protected open: number = 0;
    protected sealedPackings: number = 0;
    protected loose: number = 0;
    protected dimensions: string = 'No dimensions set';
    protected hasDimensionsSet: boolean = false;

    constructor(
        @Inject(DIALOG_DATA) private readonly dialogData: ShipmentScanTransaction,
        private readonly dialogComponentRef: DialogComponentRef<ShipmentDetailsDialogComponent>
    ) {}

    ngOnInit(): void {
        this.destination = this.dialogData.shipment.destinationName;
        this.arrivalDate = this.dialogData.shipment.arrivalDate;
        this.shipmentNumber = this.dialogData.shipment.manualShipmentNumber.trim();
        this.total = `${this.dialogData.amountReady} / ${this.dialogData.numberOfPackings}`;
        this.toScan = this.dialogData.toScan;
        this.open = this.dialogData.openPackings;
        this.sealedPackings = this.dialogData.sealedPackings;
        this.loose = this.dialogData.loosePackings;

        if (!_.isNil(this.dialogData.shipment) && !_.isEmpty(this.dialogData.shipment.boxRows)) {
            if (!_.isNil(this.dialogData.shipment.boxRows[0].dimensions)) {
                this.dimensions = this.dialogData.shipment.boxRows[0].dimensions;
                this.hasDimensionsSet = true;
            }
        }
    }

    closeDialog(event: Event): void {
        event.stopPropagation();
        this.dialogComponentRef.closeDialog();
    }
}
