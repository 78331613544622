/* eslint-disable curly */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PageTemplateComponent } from '../../../templates/page-template/page-template.component';
import { ScanService } from '../../../services/scan.service';
import { ToastService } from '../../../components/toast/toast.service';
import { LogisticsService } from '../../../services/logistics.service';
import _ from 'lodash';
import { EntityIdentification, EntityType } from '../../../utilities/entity-identification';
import { TransportCarrierDeliveryNote } from '../../../models/transport-carrier-delivery-note';
import { DataRow } from './interfaces/data-row';

@Component({
    standalone: true,
    selector: 'ax-distribution-delivery-note-page',
    templateUrl: './delivery-note-page.component.html',
    styleUrl: './delivery-note-page.component.scss',
})
export class DeliveryNotePageComponent implements OnInit, AfterViewInit, OnDestroy {
    private scanSubscription: Subscription;

    protected entityLabel: string = '';
    protected data: DataRow[] = [];
    protected isLastEntityTypeTransportCarrier: boolean = false;

    constructor(
        private readonly pageTemplate: PageTemplateComponent,
        private readonly scanService: ScanService,
        private readonly toastService: ToastService,
        private readonly logisticsService: LogisticsService
    ) {}

    ngOnInit(): void {
        this.scanSubscription = this.scanService.onScan.subscribe(async (barcode: string) => {
            const entityIdentification: EntityIdentification | Error = EntityIdentification.validateBarcode(barcode);

            if (entityIdentification instanceof Error) {
                const transportCarrierDeliveryNote: TransportCarrierDeliveryNote = await this.logisticsService.getDeliveryNotesByDeliveryNoteCode(barcode);

                this.data = [
                    {
                        key: 'Transport carrier',
                        value: this.createTransportCarrierBarcode(transportCarrierDeliveryNote.transportCarrier.transportCarrierId),
                    },
                ];

                this.entityLabel = `Delivery Note: ${barcode}`;
                this.isLastEntityTypeTransportCarrier = false;
            } else if (entityIdentification.entityType === EntityType.TransportCarrier) {
                const transportCarrierDeliveryNotes: TransportCarrierDeliveryNote[] = await this.logisticsService.getDeliveryNotesByTransportCarrierId(entityIdentification.entityKey);

                if (transportCarrierDeliveryNotes.length === 0) {
                    this.toastService.warning('Fetch error', `No transport carrier found for ${barcode}`);
                    return;
                }

                if (!this.isLastEntityTypeTransportCarrier) {
                    this.data = [];
                }

                transportCarrierDeliveryNotes.forEach((deliveryNote: TransportCarrierDeliveryNote) => {
                    this.data.push({
                        key: 'Logistical Labelcode',
                        value: deliveryNote.deliveryNoteCode,
                    });
                });

                this.entityLabel = `Transport Carrier: ${entityIdentification.entityKey}`;
                this.isLastEntityTypeTransportCarrier = true;
            } else this.toastService.danger('Unable to fetch', '');
        });
    }

    ngAfterViewInit(): void {
        // Add a tiny delay to prevent race condition on change detection
        _.delay(() => {
            this.pageTemplate.setBarcodePlaceholder('Search a delivery note');
        }, 10);
    }

    ngOnDestroy(): void {
        this.scanSubscription.unsubscribe();
        this.pageTemplate.setBarcode('');
    }

    private createTransportCarrierBarcode(transportCarrierId: string): string {
        const count: number = transportCarrierId.length + 2; // Reserving 2 character for the entity key (95)
        let barcode: string = '95';

        for (let i: number = 0; i < count; i++) {
            barcode = `${barcode}0`;
        }

        return `${barcode}${transportCarrierId}`;
    }
}
