<div class="register-transport-carrier-dialog__container">
    <div class="register-transport-carrier-dialog__header">
        <h3>Register Transport Carrier</h3>
        <button ax-button [icon-only]="true" (click)="close()">
            <i class="fa-light fa-xmark"></i>
        </button>
    </div>
    <div class="register-transport-carrier-dialog__content">
        <div class="register-transport-carrier-dialog__content__block">
            <span class="register-transport-carrier-dialog__content__block__label">Number of trolleys:</span>
            <input
                type="number"
                ax-input
                placeholder="fill in trolleys"
                ax-validate="trolleys"
                required
                [(ngModel)]="trolleys"
                (keyup.enter)="registerTransportCarrier()">
            <small class="register-transport-carrier-dialog__content__block__error" ax-validate-error="trolleys"></small>
        </div>
        <div class="register-transport-carrier-dialog__content__block">
            <span class="register-transport-carrier-dialog__content__block__label">Number of shelves:</span>
            <input
                type="number"
                ax-input
                placeholder="fill in shelves"
                ax-validate="shelves"
                required
                [(ngModel)]="shelves"
                (keyup.enter)="registerTransportCarrier()">
            <small class="register-transport-carrier-dialog__content__block__error" ax-validate-error="shelves"></small>
        </div>
    </div>
    <div class="register-transport-carrier-dialog__footer">
        <button ax-button (click)="close()">Cancel</button>
        <button ax-button="primary" (click)="registerTransportCarrier()">Ok</button>
    </div>
</div>
