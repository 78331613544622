import { ValidationRule } from './validation-rule';
import _ from 'lodash';

export class CustomValidationRule implements ValidationRule {
    public readonly key: string;
    public errorMessage: string = 'message';
    public readonly func: <T>(value: T) => CustomValidationRuleValidateResult;

    constructor(key: string, func: <T>(value: T) => CustomValidationRuleValidateResult) {
        this.key = key.toLowerCase();

        if (!_.isFunction(func)) throw new Error('Parameter func is not a valid function');

        this.func = func;
    }

    validateRule<T>(value: T): boolean {
        const result = this.func(value);
        this.errorMessage = result.errorMessage;

        return result.isValid;
    }
}

export class CustomValidationRuleValidateResult {
    public readonly isValid: boolean;
    public readonly errorMessage: string;

    constructor(isValid: boolean, errorMessage: string) {
        this.isValid = isValid;
        this.errorMessage = errorMessage;
    }
}
