import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ScanService } from '../../services/scan.service';
import { PartyIdentifierTask } from '../../models/party-identifier-task.model';
import { PartyIdentifierOptions } from '../../models/party-identifier-options.model';
import { PartyIdentifierResult } from '../../models/party-identifier-result.model';
import { PartyIdentifierResults } from '../../models/party-identifier-results.enum';
import { AbsApiService } from '../../services/abs-api.service';
import { ToastService } from '../../components/toast/toast.service';
import { ProcessLotsDerivedFrom } from '../../models/process-lots-derived-from.enum';
import { Party } from '../../models/party.model';
import { commonViewImports } from '../../utilities/global-imports';
import { ListViewItemDefinition } from '../../components/list-view/interfaces/list-view-item-definition';
import { ListViewDefinitionType } from '../../components/list-view/enums/list-view-item-definition-type';
import { ListViewComponent } from '../../components/list-view/list-view.component';
import { ReceiveLotsTask } from '../../models/receive-lots-task.model';
import { ReceiveLotsTaskResult } from '../../models/receive-lots-task-result.model';
import _ from 'lodash';
import { DialogService } from '../../components/dialog/dialog.service';
import { Subscription } from 'rxjs';
import { PageTemplateComponent } from '../../templates/page-template/page-template.component';
import { LocalSettings } from '../../models/local-settings';
import { SettingsHelper } from '../../utilities/settings-helper';
import { DialogComponentRef } from '../../components/dialog/models/dialog-component-ref';
import { SearchSupplierDialogComponent } from '../../dialogs/search-supplier-dialog/search-supplier-dialog.component';
import { RegisterTransportCarrierDialogComponent } from '../../dialogs/register-transport-carrier-dialog/register-transport-carrier-dialog.component';
import { LotDetailsDialogComponent } from '../../dialogs/lot-details-dialog/lot-details-dialog.component';

@Component({
    standalone: true,
    templateUrl: './receive-page.component.html',
    styleUrl: './receive-page.component.scss',
    imports: [commonViewImports],
})
export class ReceivePageComponent implements OnInit, AfterViewInit, OnDestroy {
    lots: Party[] = [];
    selectedLots: Party[] = [];
    isLoading: boolean = true;

    protected listViewDefinitions: ListViewItemDefinition[] = [
        { caption: 'Name', property_name: 'description', type: ListViewDefinitionType.Primary },
        { caption: 'Grower', property_name: 'manufacturer.name', type: ListViewDefinitionType.Default },
        { caption: 'Supplier', property_name: 'supplier.name', type: ListViewDefinitionType.Default },
        { caption: 'Packing units', property_name: 'boxes', type: ListViewDefinitionType.Default },
        { caption: 'Product units', property_name: 'stems', type: ListViewDefinitionType.Default },
    ];

    private selectAllRows: boolean = true;
    private barcode: string = '';
    private scanSubscription: Subscription;
    private settings: LocalSettings = SettingsHelper.loadSettings();

    @ViewChild(ListViewComponent) private readonly listViewComponent: ListViewComponent;

    constructor(
        private readonly pageTemplate: PageTemplateComponent,
        private readonly scanService: ScanService,
        private readonly absApiService: AbsApiService,
        private readonly toastService: ToastService,
        private readonly dialogService: DialogService
    ) {}

    ngOnInit(): void {
        this.scanSubscription = this.scanService.onScan.subscribe(async (barcode) => {
            this.barcode = barcode;
            const isTransportCarrier = this.barcode.length === 26;

            const task: PartyIdentifierTask = {
                partyIdentifier: this.barcode,
                partyIdentifierOptions: new PartyIdentifierOptions(),
            };

            try {
                const result = isTransportCarrier ? await this.absApiService.getPartyByTransportCarrier(task) : await this.absApiService.getLotsByIdAsync(task);
                this.handlePartyIdentifierResult(result, isTransportCarrier);
            } catch (err) {
                this.toastService.danger('Error', err as string);
            }
        });
    }

    ngAfterViewInit(): void {
        // Add a tiny delay to prevent race condition on change detection
        _.delay(() => {
            this.pageTemplate.setBarcodePlaceholder('Scan a barcode');
        }, 10);
    }

    ngOnDestroy(): void {
        this.scanSubscription.unsubscribe();
        this.pageTemplate.setBarcode('');
    }

    protected getEmptyStateText(): string {
        if (this.settings.show_barcode_bar) return 'Scan or type a barcode to start receiving';
        else return 'Scan a barcode to start receiving';
    }

    protected async receiveLots(): Promise<void> {
        const data: ReceiveLotsTask = {
            lots: this.selectedLots,
        };

        this.absApiService
            .savePartyIdentificationJob(data)
            .then((result: ReceiveLotsTaskResult) => {
                if (result.receivedLots.length === 0) this.toastService.danger('Error', 'Could not receive lot(s).');
                else this.toastService.success('Success', `${result.receivedLots.length} lot(s) received`);
            })
            .catch(() => {
                this.toastService.danger('Error', 'An error occurred while receiving lots.');
            });
    }

    protected checkAllRows(): void {
        this.selectAllRows = !this.selectAllRows;
        if (this.selectAllRows) this.listViewComponent.selectAllRows();
        else this.listViewComponent.deSelectAllRows();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected selectedRows(data: any[]): void {
        this.selectedLots = data.map((lot) => ({ ...lot.data }));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected showLotDetailsDialog(data: any): void {
        const showLotDetailsDialogRef: DialogComponentRef<LotDetailsDialogComponent> = this.dialogService.createDialog<LotDetailsDialogComponent>(LotDetailsDialogComponent, data);

        showLotDetailsDialogRef.afterClose.subscribe((result: ReceiveLotsTaskResult) => {
            const internalNumber = result.receivedLots[0].internalNumber;
            const lot = this.lots.find((x) => x.internalNumber === internalNumber);
            if (lot) lot.boxes = result.receivedLots[0].boxesToReceive;
        });
    }

    protected showSearchBySupplier(): void {
        const searchSupplierDialogRef: DialogComponentRef<SearchSupplierDialogComponent> = this.dialogService.createDialog<SearchSupplierDialogComponent>(SearchSupplierDialogComponent);

        searchSupplierDialogRef.afterClose.subscribe((result: PartyIdentifierResult) => {
            const isTransportCarrier = this.barcode.length === 26;
            this.handlePartyIdentifierResult(result, isTransportCarrier);
        });
    }

    private handlePartyIdentifierResult(result: PartyIdentifierResult, isTransportCarrier: boolean): void {
        switch (result.partyIdentifierResults) {
            case PartyIdentifierResults.partiesFound:
                this.processLots(result.foundLots, ProcessLotsDerivedFrom.Scan);
                this.scanService.successSound();
                break;
            case PartyIdentifierResults.partiesAlreadyReceived:
                if (isTransportCarrier) this.toastService.warning('Transport carrier received', `Transport carrier already received on '${this.barcode}'`);
                else this.processAlreadyRecievedLotsAsync(result.foundLots);
                this.scanService.errorSound();
                break;
            case PartyIdentifierResults.partiesNotFound:
                if (isTransportCarrier) this.toastService.danger('Transport carrier not found', `No transport carrier found on '${this.barcode}'`);
                else this.toastService.danger('Lot not found', `No lot found on '${result.identifierTask.partyIdentifier}'`);
                break;
            default:
                this.toastService.danger('Lot not found', `No lot found on '${this.barcode}'}`);
        }

        this.isLoading = false;
        setTimeout(() => {
            if (this.listViewComponent) this.listViewComponent.selectAllRows();
        }, 50);
    }

    private processLots(lots: Party[], derivedFrom: ProcessLotsDerivedFrom): void {
        if (lots.length > 0 && lots[0].supplier.registerInboundTransportCarrier && derivedFrom === ProcessLotsDerivedFrom.Scan) {
            this.lots = lots;
            this.showRegisterTransportCarrierDialog();
        }

        lots = _.orderBy(lots, [(lot) => new Date(lot.availableForProcessingMoment)], ['desc']);
        this.lots = lots;
    }

    private processAlreadyRecievedLotsAsync(lots: Party[]): void {
        lots = _.orderBy(lots, [(lot) => new Date(lot.availableForProcessingMoment)], ['desc']);
        this.lots = lots;
        if (lots.length > 1) this.toastService.warning('Error', `Lots found were already received.`);
        else this.toastService.warning('Error', `Lot found is already received.`);
    }

    private showRegisterTransportCarrierDialog(): void {
        const data = {
            transportCarrierId: this.barcode,
            lots: this.lots,
        };

        this.dialogService.createDialog<RegisterTransportCarrierDialogComponent>(RegisterTransportCarrierDialogComponent, data);
    }
}
