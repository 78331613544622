/* eslint-disable curly */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LocalSettings } from '../models/local-settings';
import { SettingsHelper } from '../utilities/settings-helper';

@Injectable({
    providedIn: 'root',
})
export class ScanService {
    private readonly _onScan: Subject<string> = new Subject<string>();
    public readonly onScan: Observable<string> = this._onScan.asObservable();

    private localSettings: LocalSettings = SettingsHelper.loadSettings();

    constructor() {
        window.addEventListener('on-barcode-scanned', (event: CustomEventInit<string>) => {
            const barcodeValue: string | undefined = event.detail;
            this.onBarcodeScan(barcodeValue);
        });
    }

    public onBarcodeScan(barcodeValue: string): void {
        this._onScan.next(barcodeValue);
    }

    public successSound(): void {
        if (this.localSettings.enable_sound) {
            // @ts-expect-error: method is injected in WMA WebView
            if (window.native && typeof window.native.postSuccess === 'function') window.native.postSuccess();
        }
    }

    public errorSound(): void {
        if (this.localSettings.enable_sound) {
            // @ts-expect-error: method is injected in WMA WebView
            if (window.native && typeof window.native.postError === 'function') window.native.postError();
        }
    }

    public warningSound(): void {
        if (this.localSettings.enable_sound) {
            // @ts-expect-error: method is injected in WMA WebView
            if (window.native && typeof window.native.postWarning === 'function') window.native.postWarning();
        }
    }
}
