import { Component, EventEmitter, Input, Output } from '@angular/core';
import { angularImports } from '../../utilities/global-imports';

@Component({
    standalone: true,
    selector: 'ax-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrl: './toggle-button.component.scss',
    imports: [angularImports],
})
export class ToggleButtonComponent {
    @Input() isDisabled: boolean = false;
    @Input() isChecked: boolean = false;
    @Input() tabIndex: number = 0;
    @Output() isCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    setContainerClasses(): string {
        let classList: string = '';

        if (this.isChecked) classList = `${classList} ax-toggle-button__container--checked`;

        if (this.isDisabled) classList = `${classList} ax-toggle-button__container--disabled`;

        return classList;
    }

    checkedChange(): void {
        this.isChecked = !this.isChecked;
        this.isCheckedChange.emit(this.isChecked);
    }
}
