<div class="putaway-on-location__container">
    @if (storageLocations.length === 0 && !isLoading) {
        <div class="putaway-on-location__no-data__container">
            <i class="putaway-on-location__no-data__container__icon fa-light fa-boxes-stacked"></i>
            <h5>No Storage locations found</h5>
        </div>
    } @else {
        <div class="putaway-on-location__storage__locations">
            @if (selectedStorageLocation === undefined) {
                <ax-list-view
                    #storageLocationsListView
                    [isLoading]="isLoading"
                    [definitions]="storageLocationListDefinitions"
                    [data]="storageLocations"
                    (rowClicked)="setStorageLocation($event)"
                    [columnCount]="'4'"
                    (scrollTopPosition)="storageLocationListViewScrollTop = $event">
                </ax-list-view>
            } @else {
                <div class="putaway-on-location__linked-lots__container">
                    <h4 class="linked-lots__location">Location: {{selectedStorageLocation.description}}</h4>
                    <div class="linked-lots">
                        <ax-list-view [definitions]="linkedLotsListDefinitions" [data]="selectedStorageLocation.partyStorageLocations" [columnCount]="'2'" [noDataText]="constructNoLotTextOnLocation()"></ax-list-view>
                    </div>
                    <div class="linked-lots__actions">
                        <button ax-button (click)="backToStorageLocations()">
                            <i class="fa-light fa-arrow-left"></i>
                            Back
                        </button>
                        <button ax-button="danger" [disabled]="isCleanPartiesDisabled()" (click)="clearLotsFromLocation()">
                            <i class="fa-light fa-broom-wide"></i>
                            Clear lots
                        </button>
                    </div>
                </div>
            }
        </div>
    }
</div>