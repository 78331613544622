<div class="development-overview__container">
    <ax-tab-container class="tab__container">
        <ax-tab [title]="'Validation'" [iconClass]="'fa-light fa-shield-check'">
            <div class="form__container">
                <div class="input__container">
                    <span class="input__container__label">Test value</span>
                    <input
                        type="text"
                        ax-input
                        placeholder="Validation test"
                        [ax-validate]="'test'"
                        [ax-validation-custom-rules]="validationRules"
                        required
                        maxlength="20"
                        [(ngModel)]="testValue">
                    <small class="input__container__error" [ax-validate-error]="'test'"></small>
                </div>
                <div class="input__container">
                    <span class="input__container__label">Disabled value</span>
                    <input
                        type="text"
                        ax-input
                        placeholder="Validation test"
                        [ax-validate]="'disabled'"
                        required
                        disabled="disabled"
                        maxlength="10"
                        minlength="3"
                        [(ngModel)]="disabledValue">
                    <small class="input__container__error" [ax-validate-error]="'disabled'"></small>
                </div>
                <div class="input__container">
                    <span class="input__container__label">Select a value</span>
                    <ax-select
                        [placeholder]="'Select a value'"
                        [options]="selectOptions"
                        [(value)]="selectedValue"
                        [ax-validate]="'select'"
                        [ax-validation-rules]="['required']"
                        [ax-validate-display-property]="'Custom display property'">
                    </ax-select>
                    <small class="input__container__error" [ax-validate-error]="'select'"></small>
                </div>
                <button [ax-button]="'primary'" (click)="post()">Test</button>
            </div>
        </ax-tab>
        <ax-tab [title]="'Table'" [iconClass]="'fa-light fa-table'">
            <div class="table__tab__container">
                <div class="table__actions">
                    <button [ax-button]="'primary'" (click)="showLoader()" [disabled]="isLoading">Show Loader</button>
                    <button [ax-button]="'danger'" (click)="clearData()" [disabled]="isLoading">Clear Data</button>
                    <button ax-button (click)="reloadData()" [disabled]="isLoading">Reload Data</button>
                </div>
                <ax-table [isLoading]="isLoading" [columns]="tableColumns" [data]="tableData" [loaderText]="'Fetching data'" [noDataText]="'No data to show'" [hasMultiSelectEnabled]="true" (sort)="onSort($event)"></ax-table>
            </div>
        </ax-tab>
        <ax-tab [title]="'List view'" [iconClass]="'fa-light fa-list'" [isVisible]="true">
            <ax-list-view [definitions]="listViewDefinitions" [data]="tableData" [columnCount]="'auto'" [hasMultiSelectEnabled]="true" (rowClicked)="rowClicked($event)"></ax-list-view>
        </ax-tab>
        <ax-tab [title]="'Combined'" [iconClass]="'fa-light fa-object-intersect'">
            @if (isMobile) {
                <div class="combined__tab__container">
                    <div class="combined__actions">
                        <ax-toggle-button (isCheckedChange)="checkAllRows($event)"></ax-toggle-button>
                        <span>Select all rows</span>
                    </div>
                    <ax-list-view #listView [definitions]="listViewDefinitions" [data]="tableData" [columnCount]="'3'" [hasMultiSelectEnabled]="true"></ax-list-view>
                </div>
            } @else {
                <ax-table [isLoading]="isLoading" [columns]="tableColumns" [data]="tableData" [loaderText]="'Fetching data'" [noDataText]="'No data to show'" [hasMultiSelectEnabled]="true" (sort)="onSort($event)"></ax-table>
            }
        </ax-tab>
    </ax-tab-container>
</div>
