/* eslint-disable curly */
import { Component, OnInit } from '@angular/core';
import { angularImports } from '../../../utilities/global-imports';
import { ButtonDirective } from '../../../directives/button.directive';
import { AccessService } from '../../../services/access.service';

@Component({
    standalone: true,
    selector: 'ax-sales-page',
    templateUrl: './sales-overview-page.component.html',
    styleUrl: './sales-overview-page.component.scss',
    imports: [angularImports, ButtonDirective],
})
export class SalesOverviewPageComponent implements OnInit {
    protected isLoading: boolean = false;
    protected showSalesRegistration: boolean = false;

    constructor(private readonly accessService: AccessService) {}

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.showSalesRegistration = await this.accessService.hasAccess('sales-registration');
        this.isLoading = false;
    }
}
