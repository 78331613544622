import { Component, Input } from '@angular/core';
import _ from 'lodash';
import { angularImports } from '../../utilities/global-imports';

@Component({
    standalone: true,
    selector: 'ax-avatar',
    templateUrl: './avatar.component.html',
    styleUrl: './avatar.component.scss',
    imports: [angularImports],
})
export class AvatarComponent {
    @Input() public name: string | null | undefined;
    @Input() public imageUrl: string | null | undefined;
    @Input() public size: 'small' | 'large' = 'small';

    protected getInitials(): string {
        if (!_.isEmpty(this.name)) {
            const splittedName: string[] = this.name.split(' ');
            let initials: string = '';

            splittedName.forEach((x: string) => {
                initials = `${initials}${x[0].toUpperCase()}`;
            });

            return initials;
        }

        return '';
    }

    protected hasName(): boolean {
        return !_.isNil(this.name) && !_.isEmpty(this.name);
    }

    protected hasImage(): boolean {
        return !_.isNil(this.imageUrl) && !_.isEmpty(this.imageUrl);
    }
}
