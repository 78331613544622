import { Component, OnInit } from '@angular/core';
import { LogisticsService } from '../../../../services/logistics.service';
import { TransportVehicleShipmentInformation } from '../../../../models/transport-vehicle-shipment-information';
import { ToastService } from '../../../../components/toast/toast.service';
import { ListViewItemDefinition } from '../../../../components/list-view/interfaces/list-view-item-definition';
import { ListViewDefinitionType } from '../../../../components/list-view/enums/list-view-item-definition-type';
import { commonViewImports } from '../../../../utilities/global-imports';
import { Router } from '@angular/router';

@Component({
    standalone: true,
    selector: 'ax-distribution-load-registration-page',
    templateUrl: './load-registration-page.component.html',
    styleUrl: './load-registration-page.component.scss',
    imports: [commonViewImports],
})
export class LoadRegistrationPageComponent implements OnInit {
    availableTransportVehicleShipments: TransportVehicleShipmentInformation[] = [];

    protected listViewDefinitions: ListViewItemDefinition[] = [{ caption: '', property_name: 'text', type: ListViewDefinitionType.Default }];

    constructor(
        private readonly logisticsService: LogisticsService,
        private readonly toastService: ToastService,
        private router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        try {
            const transportVehicleShipments = await this.logisticsService.getTransportVehicleShipmentAsync();
            if (transportVehicleShipments?.length) transportVehicleShipments.forEach((tvs) => this.availableTransportVehicleShipments.push(tvs));
            else this.toastService.danger('Loads', 'No available loads were found');
        } catch (err) {
            this.toastService.danger('Loading error', err as string);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected async onItemClick(event: any): Promise<void> {
        this.router.navigateByUrl(`distribution/load-registration/${event.key}`);
    }
}
