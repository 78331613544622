/* eslint-disable curly */
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { LoginResponse, OidcSecurityService, PublicEventsService, EventTypes } from 'angular-auth-oidc-client';
import _ from 'lodash';
// import { environment } from '../environments/environment';
import { LoaderComponent } from './components/loader/loader.component';
import { ToastService } from './components/toast/toast.service';
import { ApiResource, Tenant } from './models/tenant.model';
import { ApplicationService } from './services/application.service';
import { AuthService } from './services/auth.service';
import { TenantService } from './services/tenant.service';
import { PageTemplateComponent } from './templates/page-template/page-template.component';
import { ApplicationInsightsService } from './services/application-insights.service';
import { AbsApiService } from './services/abs-api.service';
import { AbsSettings } from './models/abs-settings';
import { filter } from 'rxjs/operators';

@Component({
    standalone: true,
    selector: 'ax-app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    imports: [CommonModule, RouterOutlet, PageTemplateComponent, LoaderComponent],
})
export class AppComponent implements OnInit {
    protected isLoading: boolean = false;
    protected isAuthenticated: boolean = false;

    private tenants: Tenant[] = [];

    constructor(
        private readonly router: Router,
        private readonly oidcSecurityService: OidcSecurityService,
        private readonly applicationService: ApplicationService,
        private readonly authService: AuthService,
        private readonly tenantService: TenantService,
        private readonly toastService: ToastService,
        private readonly aiService: ApplicationInsightsService,
        private readonly absApiService: AbsApiService,
        private readonly eventService: PublicEventsService
    ) {}

    ngOnInit(): void {
        try {
            this.isLoading = true;

            this.applicationService.onTenantSwitched.subscribe(async () => {
                const tenant: Tenant = this.applicationService.getTenant();
                sessionStorage.setItem('selectedTenantId', '' + tenant.tenantId);
                _.delay(() => {
                    location.reload();
                }, 500);
            });

            //TODO restore when we are running WMA via version wrapper
            // if (environment.production) this.router.initialNavigation();
            // else this.handleAuthentication();
            this.handleAuthentication();
        } catch {
            console.error('Unable to load WMA');
        } finally {
            _.delay(() => {
                this.isLoading = false;
            }, 1000);
        }
    }

    private handleAuthentication(): void {
        this.oidcSecurityService.checkAuth().subscribe(async (loginResponse: LoginResponse) => {
            if (loginResponse.isAuthenticated) {
                this.authService.setIsAuthenticated(loginResponse.isAuthenticated);
                this.authService.setAccessToken(loginResponse.accessToken);
                this.authService.setUserData(loginResponse.userData);
                this.aiService.setAuthenticatedUser(loginResponse.userData.preferred_username, loginResponse.userData.sub);
                this.isAuthenticated = true;

                const redirectUrl: string | null = sessionStorage.getItem('redirect');

                if (!_.isNil(redirectUrl)) {
                    sessionStorage.removeItem('redirect');
                    this.router.navigateByUrl(redirectUrl);
                }

                await this.loadTenants();
                this.applicationService.setUserKey(await this.absApiService.getUserKey());
                await this.setAbsSettings();
            } else {
                const url: string = window.location.pathname;

                if (url !== '/') sessionStorage.setItem('redirect', url);

                this.oidcSecurityService.authorize();
            }
        });

        this.eventService
            .registerForEvents()
            .pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult))
            .subscribe((result) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const resultValue: any = result.value;
                if (resultValue.isRenewProcess) {
                    this.oidcSecurityService.checkAuth().subscribe((loginResponse: LoginResponse) => {
                        if (loginResponse.isAuthenticated) {
                            this.authService.setAccessToken(loginResponse.accessToken);
                        }
                    });
                }
            });
    }

    private async loadTenants(): Promise<void> {
        try {
            this.tenants = this.applicationService.getTenants();

            if (_.isNil(this.tenants) || _.isEmpty(this.tenants)) {
                this.tenants = await this.tenantService.getTenants();

                if (_.isNil(this.tenants) || _.isEmpty(this.tenants)) {
                    throw new Error('Unable to fetch tenant information');
                } else {
                    this.tenants.sort((a, b) => (a.name > b.name ? 1 : -1));
                }
                this.applicationService.setTenants(this.tenants);

                const selectedTenantId: string | null = sessionStorage.getItem('selectedTenantId');
                let selectedTenant = this.tenants[0];
                if (!_.isNil(selectedTenantId)) {
                    const selectedTenantIdFound: Tenant | undefined = this.tenants.find((tenant) => tenant.tenantId === parseInt(selectedTenantId));
                    selectedTenant = selectedTenantIdFound ? selectedTenantIdFound : selectedTenant;
                }
                this.applicationService.setTenant(selectedTenant, true);

                await this.loadTenantResources();
                this.applicationService.initialized.next(true);
            }
        } catch {
            this.toastService.danger('Failed to fetch', 'Unable to fetch tenant information');
        }
    }

    private async loadTenantResources(): Promise<void> {
        const resources: ApiResource[] = await this.tenantService.getTenantApiResources(this.applicationService.getTenant().tenantId);
        const logisticsResource: ApiResource | undefined = resources.find((resource: ApiResource) => resource.name === 'logistics-service');
        const absApiResource: ApiResource | undefined = resources.find((resource: ApiResource) => resource.name === 'absapi');

        if (!_.isNil(logisticsResource)) this.applicationService.setLogisticsServiceUrl(logisticsResource.url);

        if (!_.isNil(absApiResource)) this.applicationService.setAbsApiServiceUrl(absApiResource.url);
    }

    private async setAbsSettings(): Promise<void> {
        const absSettings: AbsSettings = await this.absApiService.getSettings();

        this.applicationService.setAbsSettings(absSettings);
    }
}
