<div #selectContainer class="ax-select__container" (click)="toggleCollapse()" [ngClass]="setContainerClasses()" [tabIndex]="tabIndex" (blur)="onFocusLost()">
    <span class="ax-select__selected__value" [ngClass]="hasSelectedValue ? '' : 'ax-select__selected__value--placeholder'">
        {{ hasSelectedValue ? displayValue : placeholder }}
    </span>
    @if (hasClearEnabled && hasSelectedValue) {
        <i class="fa-light fa-circle-xmark ax-select__clear" (click)="clear($event)"></i>
    }
    <i class="fa-solid fa-caret-down ax-select__toggle" [ngClass]="isCollapsed ? '' : 'ax-select__toggle--reversed'"></i>
    @if (isMobile && !isCollapsed) {
        <div class="ax-select__backdrop"></div>
    }
    <div #optionsContainer class="ax-select__options__container" [ngClass]="isCollapsed ? 'ax-select__options__container--collapsed' : ''" (mouseleave)="resetPreSelectedIndex()">
        @if (isMobile) {
            <div class="ax-select__options__header">
                <h4 class="ax-select__options__header__value">{{getMobileContainerPlaceholder()}}</h4>
                <button ax-button [icon-only]="true">
                    <i class="fa-light fa-xmark"></i>
                </button>
            </div>
            <div class="ax-select__options__scroll__container">
                @for (option of internalOptions; track option.value; let index = $index) {
                    <div
                        #optionElement
                        class="ax-select__option"
                        (click)="selectOption(option, true, $event)"
                        [ngClass]="setOptionClasses(option, index)"
                        (mouseenter)="setPreSelectedIndex(index)">
                            <span class="ax-select__option__text">{{option.display_value}}</span>
                    </div>
                }
                @empty {
                    <span class="ax-select__option ax-select__option--no-options">{{noOptionsText}}</span>
                }
            </div>
        } @else {
            @for (option of internalOptions; track option.value; let index = $index) {
                <div
                    #optionElement
                    class="ax-select__option"
                    (click)="selectOption(option, true, $event)"
                    [ngClass]="setOptionClasses(option, index)"
                    (mouseenter)="setPreSelectedIndex(index)">
                        <span class="ax-select__option__text">{{option.display_value}}</span>
                </div>
            }
            @empty {
                <span class="ax-select__option ax-select__option--no-options">{{noOptionsText}}</span>
            }
        }
    </div>
</div>