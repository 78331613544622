/* eslint-disable curly */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import _ from 'lodash';
import { AccessService } from '../services/access.service';

@Injectable({
    providedIn: 'root',
})
export class AuthConfigGuard {
    constructor(
        private readonly router: Router,
        private readonly accessService: AccessService
    ) {}

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        if (!_.isNil(route.data)) {
            const accessKey: string | undefined | null = route.data['access_key'] as string;

            if (_.isNil(accessKey) || _.isEmpty(accessKey)) {
                throw new Error('Access key is required');
            }

            const hasAccess: boolean = await this.accessService.hasAccess(accessKey);

            if (hasAccess) {
                return true;
            } else {
                this.router.navigateByUrl('/403');
            }
        }

        this.router.navigateByUrl('/403');

        return false;
    }
}
