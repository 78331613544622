/* eslint-disable curly */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { LogisticsService } from '../../../../services/logistics.service';
import { Shipment } from '../../../../models/shipment.model';
import { ToastService } from '../../../../components/toast/toast.service';
import { ShipmentPropertyRequest } from '../../../../models/shipment-property-request.model';
import { commonViewImports } from '../../../../utilities/global-imports';
import { ListViewComponent } from '../../../../components/list-view/list-view.component';
import { ListViewItemDefinition } from '../../../../components/list-view/interfaces/list-view-item-definition';
import { ListViewDefinitionType } from '../../../../components/list-view/enums/list-view-item-definition-type';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ScanService } from '../../../../services/scan.service';
import { PageTemplateComponent } from '../../../../templates/page-template/page-template.component';
import { EntityIdentification, EntityType } from '../../../../utilities/entity-identification';
import { ShipmentScanTransaction } from '../../../../models/shipment-scan-transaction.model';
import _ from 'lodash';

@Component({
    standalone: true,
    templateUrl: './shipment-check-page.component.html',
    styleUrl: './shipment-check-page.component.scss',
    imports: [commonViewImports, ListViewComponent],
})
export class ShipmentCheckPageComponent implements OnInit, AfterViewInit, OnDestroy {
    shipments: Shipment[] = [];
    isLoading: boolean = true;

    protected listViewDefinitions: ListViewItemDefinition[] = [
        { caption: 'Transporter', property_name: 'transportLine.transporter', type: ListViewDefinitionType.Primary },
        { caption: 'Number', property_name: 'number', type: ListViewDefinitionType.Default },
        { caption: 'Manual', property_name: 'manualShipmentNumber', type: ListViewDefinitionType.Default },
        { caption: 'Ship date', property_name: 'shipDate', type: ListViewDefinitionType.Date },
        { caption: 'Total', property_name: 'shipmentScanTransactions[0].total', type: ListViewDefinitionType.Default },
    ];

    private barcode: string = '';
    private scanSubscription: Subscription | undefined;
    private shipmentScanTransactionId: number | null = null;
    private shipmentScanTransaction: ShipmentScanTransaction | undefined;

    constructor(
        private readonly pageTemplate: PageTemplateComponent,
        private readonly logisticsService: LogisticsService,
        private readonly toastService: ToastService,
        private readonly scanService: ScanService,
        private router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        this.scanSubscription = this.scanService.onScan.subscribe(async (barcode) => {
            this.barcode = barcode;
            if (this.barcode.length === 14 || this.barcode.length === 12 || this.barcode.length === 8) {
                const entity: EntityIdentification | Error = EntityIdentification.validateBarcode(this.barcode);
                if (!(entity instanceof Error))
                    switch (entity.entityType) {
                        case EntityType.TransportCarrierLayer:
                            await this.handleTransportCarrierLayerEntity(entity);
                            break;
                        case EntityType.PackingLabel:
                            await this.handlePackingLabelEntity(entity);
                            break;
                        default:
                            this.toastService.danger('Entity type', `No valid entity type found`);
                            break;
                    }
            } else this.toastService.danger('Barcode', `Invalid barcode scanned ${this.barcode}`);
        });
        await this.getShipmentsAsync();
    }

    ngAfterViewInit(): void {
        _.delay(() => {
            this.pageTemplate.setBarcodePlaceholder('Scan a barcode');
        }, 10);
    }

    ngOnDestroy(): void {
        this.scanSubscription.unsubscribe();
        this.pageTemplate.setBarcode('');
    }

    protected async getShipmentsAsync(): Promise<void> {
        try {
            const shipmentsAll = await this.logisticsService.getShipmentsAsync();

            if (shipmentsAll.length > 0) {
                const shipmentsFullyScanned: Shipment[] = [];
                const shipmentsPending: Shipment[] = [];

                for (const shipment of shipmentsAll) {
                    const test: ShipmentPropertyRequest = {
                        shipmentKey: shipment.shipmentKey,
                        transportlineKey: shipment.transportLine.transportLineKey,
                        shipDate: shipment.shipDate,
                    };

                    const shipmentWithProperties = await this.logisticsService.getShipmentPropertiesAsync(test);
                    shipment.shipmentScanTransactions = shipmentWithProperties.shipmentScanTransactions;

                    if (this.isShipmentFullyScanned(shipment)) shipmentsFullyScanned.push(shipment);
                    else shipmentsPending.push(shipment);
                }

                this.shipments = [...shipmentsPending, ...shipmentsFullyScanned];
                this.setCustomShipmentValues();
            } else this.toastService.warning('Shipments', 'No shipments found!');
        } catch (err) {
            this.toastService.danger('Error', err as string);
        }

        this.isLoading = false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected async onItemClick(event: any): Promise<void> {
        if (this.shipmentScanTransactionId !== null) await this.stopShipmentScanTransactionAsync();
        this.router.navigateByUrl(`distribution/shipment-check/${event.shipmentKey}`);
    }

    protected getRowClass(row: Shipment): string {
        if (row && _.isArray(row.shipmentScanTransactions) && !_.isEmpty(row.shipmentScanTransactions)) {
            const { amountReady, numberOfPackings } = row.shipmentScanTransactions[0];
            if (amountReady !== 0 && amountReady !== numberOfPackings) {
                return 'ax-list-view__item--status-1';
            } else if (amountReady == numberOfPackings) {
                return 'ax-list-view__item--status-2';
            } else {
                return '';
            }
        } else return '';
    }

    private isShipmentFullyScanned(shipment: Shipment): boolean {
        if (shipment.shipmentScanTransactions != null && shipment.shipmentScanTransactions.length > 0) {
            const firstTransaction = shipment.shipmentScanTransactions[0];
            return firstTransaction.amountReady === firstTransaction.numberOfPackings;
        } else return false;
    }

    private async handleTransportCarrierLayerEntity(entity: EntityIdentification): Promise<void> {
        if (this.shipmentScanTransactionId === null)
            if ((await this.getShipmentByLabelIdAsync(entity.entityKey, true)) !== null) await this.getShipmentScanTransactionAsync(this.shipmentScanTransactionId);
            else this.toastService.danger('Shipment', 'No shipment could be found for this label');
        else await this.scanLabelAsync(entity.entityKey, true);
    }

    private async handlePackingLabelEntity(entity: EntityIdentification): Promise<void> {
        if (this.shipmentScanTransactionId === null)
            if ((await this.getShipmentByLabelIdAsync(entity.entityKey)) !== null) await this.getShipmentScanTransactionAsync(this.shipmentScanTransactionId);
            else this.toastService.danger('Shipment', 'No shipment could be found for this label');
        else await this.scanLabelAsync(entity.entityKey);
    }

    private async getShipmentByLabelIdAsync(labelId: number, groupLabel: boolean = false): Promise<Shipment | null> {
        const shipment = await this.logisticsService.getShipmentByLabelIdAsync(labelId, groupLabel);
        if (shipment !== null) await this.startShipmentScanTransactionAsync(shipment.shipmentKey);
        else this.toastService.danger('Shipment', 'No shipment could be found');

        return shipment;
    }

    private async scanLabelAsync(labelId: number, groupLabel: boolean = false): Promise<void> {
        const scanActionKey = await this.logisticsService.scanLabelOnShipmentScanTransaction(this.shipmentScanTransactionId, labelId, groupLabel);
        if (scanActionKey > 0) {
            const context = await this.logisticsService.getShipmentScanTransactionLabelScanActionContextAsync(this.shipmentScanTransaction.shipment.shipmentKey, labelId, scanActionKey);
            if (context !== null)
                if (!context.valid) {
                    if (context.validationResults.length > 0) {
                        let errorString = '';
                        if (context.validationResults.length > 1) errorString = context.validationResults[0] + ' and ' + context.validationResults[1];
                        else errorString = context.validationResults[0];

                        this.toastService.danger('Scan', errorString);
                    }
                } else this.toastService.success('Label', 'Label scanned successfully');
            else this.toastService.danger('Scan', 'Could not confirm scan result');
        } else this.toastService.danger('Label', 'Label scan failed');
    }

    private async startShipmentScanTransactionAsync(shipmentKey: number) {
        const shipmentScanTransaction = await this.logisticsService.startShipmentScanTransactionAsync({ shipmentKey });
        this.shipmentScanTransactionId = shipmentScanTransaction.shipmentScanTransactionKey;
    }

    private async stopShipmentScanTransactionAsync() {
        await this.logisticsService.stopShipmentScanTransactionAsync(this.shipmentScanTransactionId);
        this.shipmentScanTransactionId = null;
    }

    private async getShipmentScanTransactionAsync(shipmentScanTransactionKey: number) {
        const shipmentScanTransaction = await this.logisticsService.getShipmentScanTransactionAsync(shipmentScanTransactionKey);
        if (shipmentScanTransaction !== null) this.shipmentScanTransaction = shipmentScanTransaction;
        else this.toastService.danger('Scan transaction', 'No scan transaction found');
    }

    private setCustomShipmentValues(): void {
        for (const shipment of this.shipments) {
            const shipmentScanTransactions = shipment.shipmentScanTransactions;

            if (shipmentScanTransactions && shipmentScanTransactions.length > 0) {
                const { amountReady, numberOfPackings } = shipmentScanTransactions[0];
                shipment.completed = amountReady === numberOfPackings;
                shipmentScanTransactions[0].total = `${amountReady} / ${numberOfPackings}`;
            }
        }
    }
}
