import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild, ViewContainerRef } from '@angular/core';
import _ from 'lodash';
import { angularImports } from '../../utilities/global-imports';

@Component({
    standalone: true,
    selector: 'ax-side-panel',
    templateUrl: './side-panel.component.html',
    styleUrls: ['./side-panel.component.scss'],
    imports: [angularImports],
})
export class SidePanelComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
    @Input() public closeOnClick: boolean = true;
    @Input() public orientation: 'left' | 'right' = 'left';
    @Output() public isPanelVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @HostListener('click', ['$event'])
    clickInside(event: Event): void {
        event.stopPropagation();

        if (!this.isToggling && this.isPanelVisible && this.closeOnClick) {
            this.isToggling = true;
            this.panelContainer.nativeElement.classList.add('ax-side-panel__container--hiding');
            this.viewRef.element.nativeElement.style.width = '0';
            _.delay(() => {
                this.panelContainer.nativeElement.classList.remove('ax-side-panel__container--hiding');
                this.isPanelVisible = false;
                this.isPanelVisibleChange.emit(false);
                this.isToggling = false;
            }, 300);
        }
    }

    @ViewChild('panelContainer') private readonly panelContainer: ElementRef;

    private isPanelVisible: boolean = false;
    private isToggling: boolean = false;

    constructor(private readonly viewRef: ViewContainerRef) {}

    ngAfterViewInit(): void {
        this.panelContainer.nativeElement.addEventListener('click', (event: Event) => {
            event.stopPropagation();
        });
    }

    ngAfterViewChecked(): void {
        if (this.orientation === 'left') this.viewRef.element.nativeElement.style.left = '0';

        if (this.orientation === 'right') this.viewRef.element.nativeElement.style.right = '0';

        const offsetTop: number = 0;

        this.viewRef.element.nativeElement.style.top = `${offsetTop}px`;
    }

    ngOnDestroy(): void {
        this.panelContainer.nativeElement.removeEventListener('click', (event: Event) => {
            event.stopPropagation();
        });
    }

    setClasses(): string {
        let classList: string = '';

        if (!this.isPanelVisible) classList = `${classList} ax-side-panel__container--hidden`;

        if (this.orientation === 'left') classList = `${classList} ax-side-panel__container--left`;

        if (this.orientation === 'right') classList = `${classList} ax-side-panel__container--right`;

        return classList;
    }

    toggle(): void {
        this.isToggling = true;

        if (this.isPanelVisible) {
            this.panelContainer.nativeElement.classList.add('ax-side-panel__container--hiding');
            this.viewRef.element.nativeElement.style.width = '0';
            _.delay(() => {
                this.panelContainer.nativeElement.classList.remove('ax-side-panel__container--hiding');
                this.isPanelVisible = false;
                this.isPanelVisibleChange.emit(false);
                this.isToggling = false;
            }, 300);
        } else {
            this.panelContainer.nativeElement.classList.add('ax-side-panel__container--showing');
            this.viewRef.element.nativeElement.style.width = '100%';
            _.delay(() => {
                this.panelContainer.nativeElement.classList.remove('ax-side-panel__container--showing');
                this.isPanelVisible = true;
                this.isPanelVisibleChange.emit(true);
                this.isToggling = false;
            }, 300);
        }
    }
}
