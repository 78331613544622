<div class="search-supplier-dialog__container">
    <div class="search-supplier-dialog__header">
        <h3>Search supplier</h3>
        <button ax-button [icon-only]="true" (click)="close()">
            <i class="fa-light fa-xmark"></i>
        </button>
    </div>
    <div class="search-supplier-dialog__content">
        <div class="search-supplier-dialog__content__block">
            <span class="search-supplier-dialog__content__block__label">Supplier:</span>
            <input
                type="text"
                ax-input
                placeholder="fill in supplier"
                ax-validate="supplier"
                required
                [(ngModel)]="supplier"
                (keyup.enter)="searchSupplier()"
                >
            <small class="search-supplier-dialog__content__block__error" ax-validate-error="supplier"></small>
        </div>
        <div class="search-supplier-dialog__content__block">
            <span class="search-supplier-dialog__content__block__label">From:</span>
            <input
                type="date"
                ax-input
                ax-validate="from_date"
                required
                [(ngModel)]="fromDate"
                (keyup.enter)="searchSupplier()"
                >
            <small class="search-supplier-dialog__content__block__error" ax-validate-error="from_date"></small>
        </div>
        <div class="search-supplier-dialog__content__block">
            <span class="search-supplier-dialog__content__block__label">Until:</span>
            <input
                type="date"
                ax-input
                ax-validate="until_date"
                required
                [(ngModel)]="untilDate"
                (keyup.enter)="searchSupplier()"
                >
            <small class="search-supplier-dialog__content__block__error" ax-validate-error="until_date"></small>
        </div>
    </div>
    <div class="search-supplier-dialog__footer">
        <button ax-button (click)="close()">Cancel</button>
        <button ax-button="primary" (click)="searchSupplier()">Search</button>
    </div>
</div>
