/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import _ from 'lodash';
import { angularImports, pipeImports } from '../../utilities/global-imports';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { LoaderComponent } from '../loader/loader.component';
import { ListViewItemDefinitionOptionKey } from './enums/list-view-item-definition-option-key';
import { ListViewDefinitionType } from './enums/list-view-item-definition-type';
import { ListViewItemDefinition, ListViewItemDefinitionOption } from './interfaces/list-view-item-definition';
import { SelectedListViewItem } from './interfaces/selected-list-view-item';

@Component({
    standalone: true,
    selector: 'ax-list-view',
    templateUrl: './list-view.component.html',
    styleUrl: './list-view.component.scss',
    imports: [angularImports, LoaderComponent, pipeImports, CheckboxComponent],
})
export class ListViewComponent implements OnChanges {
    @Input() public isLoading: boolean = false;
    @Input() public data: any = [];
    @Input() public definitions: ListViewItemDefinition[] = [];
    @Input() public hasMultiSelectEnabled: boolean = false;
    @Input() public isInfiniteScrollEnabled: boolean = true;
    @Input() public loaderText: string = 'Fetching your data';
    @Input() public noDataText: string = 'Nothing to see here...';
    @Input() public columnCount: string = 'auto';
    @Input() public booleanIconTrue: string = 'fa-duotone fa-circle-check';
    @Input() public booleanIconFalse: string = 'fa-duotone fa-circle-xmark';
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Input() public rowClassCallbackFn: Function;
    @Output() public selectedRows: EventEmitter<SelectedListViewItem[]> = new EventEmitter<SelectedListViewItem[]>();
    @Output() public scrollTrigger: EventEmitter<void> = new EventEmitter<void>();
    @Output() public rowClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() public scrollTopPosition: EventEmitter<number> = new EventEmitter<number>();

    @HostListener('window:resize')
    public onResize(): void {
        this.isDesktop = window.matchMedia('(min-width: 1280px)').matches;
        this.isTablet = window.matchMedia('(min-width: 768px)').matches;
    }

    protected isDesktop: boolean = window.matchMedia('(min-width: 1280px)').matches;
    protected isTablet: boolean = window.matchMedia('(min-width: 768px)').matches;

    protected loaderDelay: number = 0;
    protected scrollTop: number = 0;
    protected readonly definitionType = ListViewDefinitionType;
    protected readonly definitionOptionKey = ListViewItemDefinitionOptionKey;

    private _selectedRows: SelectedListViewItem[] = [];
    private delayScrollId: number = -1;
    private loaderTimer: NodeJS.Timeout;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['isLoading'])
            if (this.isLoading === true)
                // Javascript can't keep up with actual milliseconds so we increment every 10 ms.
                this.loaderTimer = setInterval(() => {
                    this.loaderDelay++;
                }, 10);
            else {
                clearInterval(this.loaderTimer);
                this.loaderDelay = 0;
            }
    }

    protected onScrolled(event: Event): void {
        const targetElement: HTMLElement = event.target as HTMLElement;
        this.scrollTop = targetElement.scrollTop;
        this.scrollTopPosition.emit(this.scrollTop);

        if (this.isInfiniteScrollEnabled && !this.isLoading) {
            clearTimeout(this.delayScrollId);

            this.delayScrollId = _.delay(() => {
                const scrollPercent: number = (targetElement.scrollTop + targetElement.clientHeight) / targetElement.scrollHeight;
                const scrollPercentRounded: number = Math.round(scrollPercent * 100);

                if (scrollPercentRounded >= 50) this.scrollTrigger.emit();
            }, 100);
        }
    }

    protected setLayoutStyle(element: HTMLDivElement): string {
        const baseStyle: string = 'display: grid; grid-template-rows: auto; column-gap: 1rem; row-gap: .5rem;';
        const childElements: number = element.childElementCount;

        if (_.isNil(this.columnCount) || _.isEmpty(this.columnCount))
            if (this.isDesktop) return `${baseStyle} grid-template-columns: repeat(${childElements}, 1fr);`;
            else return '';

        switch (this.columnCount) {
            case 'auto':
                if (this.isDesktop) return `${baseStyle} grid-template-columns: repeat(${childElements}, 1fr);`;
                else return '';

            case '2':
                return `${baseStyle} grid-template-columns: repeat(2, 1fr);`;

            case '3':
                return `${baseStyle} grid-template-columns: repeat(3, 1fr);`;

            case '4':
                return `${baseStyle} grid-template-columns: repeat(4, 1fr);`;

            case '5':
                return `${baseStyle} grid-template-columns: repeat(5, 1fr);`;

            case '6':
                return `${baseStyle} grid-template-columns: repeat(6, 1fr);`;

            default:
                if (this.isDesktop) return `${baseStyle} grid-template-columns: repeat(${childElements}, 1fr);`;
                else return '';
        }
    }

    protected getRowClass(row: any): string {
        if (typeof this.rowClassCallbackFn === 'function') return this.rowClassCallbackFn(row);
        return '';
    }

    protected getDataLayoutTypeClass(): string {
        if (_.isNil(this.columnCount) || _.isEmpty(this.columnCount)) return 'ax-list-view__item__data__container__data--auto-layout';

        if (this.columnCount === 'auto') return 'ax-list-view__item__data__container__data--auto-layout';

        return '';
    }

    protected getDefinitionOptionValueAsString(definition: ListViewItemDefinition, optionValue: string): string {
        if (_.isEmpty(definition.options)) return '';

        const option = definition.options?.find((option: ListViewItemDefinitionOption) => option.key === optionValue);

        if (!option?.value) return '';

        return option.value as string;
    }

    protected getDefinitionOptionValueAsBoolean(definition: ListViewItemDefinition, optionValue: string): boolean {
        if (_.isEmpty(definition.options)) return null;

        const option = definition.options?.find((option: ListViewItemDefinitionOption) => option.key === optionValue);

        if (!option?.value) return null;

        return option.value as boolean;
    }

    protected setValueBooleanIconClasses(value: boolean): string {
        return value ? `${this.booleanIconTrue} ax-list-view__item__data__container__data__value__boolean__icon--true` : `${this.booleanIconFalse} ax-list-view__item__data__container__data__value__boolean__icon--false`;
    }

    protected selectRow(index: number, event: Event): void {
        event.stopPropagation();

        const targetRow: any = this.data[index];
        const selectedRow: any = this._selectedRows.find((x: SelectedListViewItem) => x.index === index);

        if (selectedRow === undefined)
            this._selectedRows.push({
                index: index,
                data: targetRow,
            });
        else this._selectedRows.splice(this._selectedRows.indexOf(selectedRow), 1);

        this.selectedRows.emit(this._selectedRows);
    }

    protected isSelectedRow(index: number): boolean {
        if (!this.hasMultiSelectEnabled || _.isEmpty(this._selectedRows)) return false;

        const selectedRow: SelectedListViewItem = this._selectedRows.find((x: SelectedListViewItem) => x.index === index);

        if (selectedRow === undefined) return false;

        return true;
    }

    protected getPrimaryLabel(index: number): string {
        const targetRow: any = this.data[index];
        const primaryListViewDefinitionItem: ListViewItemDefinition | undefined = this.definitions.find((def: ListViewItemDefinition) => def.type === ListViewDefinitionType.Primary);

        if (!_.isNil(primaryListViewDefinitionItem)) return this.getPropertyValue(targetRow, primaryListViewDefinitionItem.property_name);

        return '';
    }

    protected filterDefinitions(): ListViewItemDefinition[] {
        if (this.isTablet) return this.definitions;
        else return this.definitions.filter((defs: ListViewItemDefinition) => defs.type !== ListViewDefinitionType.Primary);
    }

    protected getPropertyValue(entity: any, propertyName: string): any {
        if (_.isNil(propertyName)) return '';

        if (propertyName.includes('.')) {
            const splittedProperties: string[] = propertyName.split('.');
            let data: any = entity;

            for (const prop of splittedProperties) {
                if (_.isNil(data)) {
                    data = '';
                    break;
                }
                if (prop.includes('[')) {
                    const [key, index] = prop.split('[');
                    const idx = parseInt(index.replace(']', ''));
                    data = data[key] && data[key][idx];
                } else data = data[prop];
            }
            return data;
        } else return entity[propertyName];
    }

    protected onRowClick(index: number, event: Event): void {
        event.stopPropagation();

        this.rowClicked.emit(this.data[index]);
    }

    protected shouldShowPrimaryContainer(index: number): boolean {
        if (this.hasMultiSelectEnabled) return true;
        else if (this.getPrimaryLabel(index) !== '') return true;

        return false;
    }

    setScrollTopPosition(scrollTop: number): void {
        this.scrollTop = scrollTop;
    }

    selectAllRows(): void {
        this._selectedRows = [];
        this.data.forEach((data: any, index: number) => {
            this._selectedRows.push({
                data: data,
                index: index,
            });
        });

        this.selectedRows.emit(this._selectedRows);
    }

    deSelectAllRows(): void {
        this._selectedRows = [];
        this.selectedRows.emit(this._selectedRows);
    }
}
