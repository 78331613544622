import { Component } from '@angular/core';
import { ButtonDirective } from '../../../directives/button.directive';
import { angularImports } from '../../../utilities/global-imports';

@Component({
    standalone: true,
    selector: 'ax-forbidden-page',
    templateUrl: './forbidden-error-page.component.html',
    styleUrl: './forbidden-error-page.component.scss',
    imports: [angularImports, ButtonDirective],
})
export class ForbiddenPageComponent {}
