import { Component } from '@angular/core';
import { angularImports } from '../../../utilities/global-imports';
import { ButtonDirective } from '../../../directives/button.directive';

@Component({
    standalone: true,
    selector: 'ax-not-found-page',
    templateUrl: './page-not-found.component.html',
    styleUrl: './page-not-found.component.scss',
    imports: [angularImports, ButtonDirective],
})
export class PageNotFoundComponent {}
