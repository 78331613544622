import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { DIALOG_DATA, DialogComponentRef } from '../../components/dialog/models/dialog-component-ref';
import { ButtonDirective } from '../../directives/button.directive';
import { commonViewImports } from '../../utilities/global-imports';
import { LogisticsService } from '../../services/logistics.service';
import { ToastService } from '../../components/toast/toast.service';
import { ScanService } from '../../services/scan.service';
import { RegisterTransportCarrier } from '../../models/register-transport-carrier.model';
import { PartyDTO } from '../../models/party-dto.model';
import { Party } from '../../models/party.model';
import { ValidationHost } from '../../validator/validation-host';
import { ValidateErrorDirective } from '../../validator/directives/validate-error.directive';
import { ValidateDirective } from '../../validator/directives/validate.directive';
import { Validator } from '../../validator/validator';

@Component({
    standalone: true,
    templateUrl: './register-transport-carrier-dialog.component.html',
    styleUrl: './register-transport-carrier-dialog.component.scss',
    imports: [commonViewImports, ButtonDirective],
})
export class RegisterTransportCarrierDialogComponent implements OnInit, ValidationHost {
    trolleys: number | null = null;
    shelves: number | null = null;

    private validator: Validator;

    @ViewChildren(ValidateDirective) private readonly validateDirectives: ValidateDirective[];
    @ViewChildren(ValidateErrorDirective) private readonly validateErrorDirectives: ValidateErrorDirective[];

    constructor(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        @Inject(DIALOG_DATA) private readonly data: any,
        private readonly dialogComponentRef: DialogComponentRef<RegisterTransportCarrierDialogComponent>,
        private readonly logisticsService: LogisticsService,
        private readonly toastService: ToastService,
        private scanService: ScanService
    ) {}

    ngOnInit(): void {
        try {
            this.validator = new Validator(this);
        } catch (err) {
            this.toastService.danger('Loading error', err as string);
        }
    }

    getValidationItems(): ValidateDirective[] {
        return this.validateDirectives;
    }

    getValidationErrorItems(): ValidateErrorDirective[] {
        return this.validateErrorDirectives;
    }

    protected async registerTransportCarrier(): Promise<void> {
        try {
            this.validator.resetValidationStates();
            const isValid: boolean = this.validator.validate();

            if (!isValid) this.toastService.warning('Validation error', 'Missing required fields');
            else {
                const lotsDTO: PartyDTO[] = this.data.lots.map((lot: Party) => ({
                    partyKey: lot.partyKey,
                    quantity: lot.boxes * lot.stems,
                    virtualMarketPlaceUnitMeasureID: 1,
                    externalID: 0,
                }));

                const registerTransportCarrier: RegisterTransportCarrier = {
                    transportCarrierId: this.data.transportCarrierId,
                    transportCarriers: this.trolleys,
                    layers: this.shelves,
                    parties: lotsDTO,
                };

                try {
                    await this.logisticsService.registerTransportCarrierAsync(registerTransportCarrier);
                    this.toastService.success('Success', `Registered ${registerTransportCarrier.transportCarrierId} successfully!`);
                } catch {
                    this.toastService.danger('Error', 'Could not register this transportcarrier');
                }

                this.close();
            }
        } catch (err) {
            this.toastService.danger('Error', err as string);
        }
    }

    protected close(): void {
        this.dialogComponentRef.closeDialog();
    }
}
