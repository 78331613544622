import { InjectionToken } from '@angular/core';

export interface ToastData {
    id: string;
    title?: string;
    message?: string;
    type: string;
    has_close_button: boolean;
    click_to_dispose: boolean;
}

export const TOAST_DATA: InjectionToken<ToastData> = new InjectionToken<ToastData>('ToastData');
