/* eslint-disable curly */
import { Injectable } from '@angular/core';
import { PartyIdentifierTask } from '../models/party-identifier-task.model';
import { PartyIdentifierResult } from '../models/party-identifier-result.model';
import { ApplicationService } from './application.service';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, firstValueFrom } from 'rxjs';
import { ReceiveLotsTask } from '../models/receive-lots-task.model';
import { ReceiveLotsTaskResult } from '../models/receive-lots-task-result.model';
import { AbsSettings } from '../models/abs-settings';
import _ from 'lodash';
import { PartyIdentifierOptions } from '../models/party-identifier-options.model';
import { StorageLocation } from '../models/storage-location.model';
import { MeasurementPoint } from '../models/measurement-point';
import { MeasurementPointResult } from '../models/measure-point-result';
import { AddMeasurementsTask } from '../models/add-measurement-task';
import { AddMeasurementsResult } from '../models/add-measurements-result';

@Injectable({
    providedIn: 'root',
})
export class AbsApiService {
    private readonly absSettings = new ReplaySubject<AbsSettings>(1);
    private absSettingsFetched: boolean = false;
    constructor(
        private readonly httpClient: HttpClient,
        private applicationService: ApplicationService
    ) {}

    async getBaseUrl(): Promise<string> {
        await firstValueFrom(this.applicationService.initialized);
        const serviceUrl: string = this.applicationService.getAbsApiServiceUrl();
        return serviceUrl.endsWith('/api/v1.0/') ? serviceUrl : `${serviceUrl}/api/v1.0/`;
    }

    async getPartyByTransportCarrier(task: PartyIdentifierTask): Promise<PartyIdentifierResult> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.post<PartyIdentifierResult>(`${baseUrl}wm/receive/findpartiesbytransportcarrier`, task));
    }

    async getPartyBySupplier(task: PartyIdentifierTask): Promise<PartyIdentifierResult> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.post<PartyIdentifierResult>(`${baseUrl}wm/receive/findpartiesbysupplier`, task));
    }

    async getLotsByIdAsync(task: PartyIdentifierTask): Promise<PartyIdentifierResult> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.post<PartyIdentifierResult>(`${baseUrl}wm/receive/findpartiesbyidentifier`, task));
    }

    async savePartyIdentificationJob(task: ReceiveLotsTask): Promise<ReceiveLotsTaskResult> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.post<ReceiveLotsTaskResult>(`${baseUrl}wm/receive/savepartyidentificationjob`, task));
    }

    async getStorageLocation(storageLocationId: number, options: string): Promise<StorageLocation> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<StorageLocation>(`${baseUrl}/wm/storagelocations/${storageLocationId}${options}`));
    }

    async getMeasurementPointsAsync(): Promise<MeasurementPoint[]> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<MeasurementPoint[]>(`${baseUrl}/lm/measurementpoints`));
    }

    async getMeasurementPointAsync(measurementPointKey: number): Promise<MeasurementPointResult> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<MeasurementPointResult>(`${baseUrl}/lm/measurementpoints/${measurementPointKey}`));
    }

    async addMeasurementsResultAsync(addMeasurementTask: AddMeasurementsTask): Promise<AddMeasurementsResult> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.post<AddMeasurementsResult>(`${baseUrl}/lm/measurementtransactions`, addMeasurementTask));
    }

    async getSettings(): Promise<AbsSettings> {
        if (this.absSettingsFetched) {
            return firstValueFrom(this.absSettings);
        } else {
            this.absSettingsFetched = true;
            const settings = await this.fetchAbsSettings();
            this.absSettings.next(settings);
            return settings;
        }
    }

    private async fetchAbsSettings(): Promise<AbsSettings> {
        const baseUrl: string = await this.getBaseUrl();

        const absSettings: AbsSettings = {
            ConfirmPrintPickOrderContextReport: false,
            PrintPickOrderContextReport: false,
            WMAChangePackingUnitQuantityOnParty: false,
            WMAFixedScreen: 'none',
            WMAShowMenuPutAwayOnLocation: false,
            WMAShowMenuOrderPicking: false,
            WMAShowMenuOrderPickingBarcode: false,
            WMAShowMenuStocktake: false,
            WMAShowMenuOverviewStorageLocation: false,
            WMAShowMenuReceive: false,
            WMAReceiveDirectly: false,
            WMAReceiveDirectlyAndShowPicture: false,
            WMAShowProductImages: false,
            WMAShowTCRegistrationOverviewPage: false,
            WMAShowShipmentRegistrationOverviewPage: false,
            WMAShowPutAwayTransfer: false,
            WMAShowLoadRegistrationOverviewPage: false,
            WMAShowStockDistributeOverviewPage: false,
            WMAShowMenuPutAwayOnContainer: false,
            WMAShowMenuContainerOverview: false,
            WMAShowMenuAllocatePartyToOrder: false,
            LICUniqueLogisticalUnits: false,
            UsePartyStorageLocationWithUnits: false,
            WMAShowDeliveryNoteDetailPage: false,
            WMASalesOrderReturnPage: false,
            WMAContainerRegistrationDisablePrintLabel: false,
            WMAContainerRegistrationConfirmPrintLabel: false,
            WMAContainerRegistrationCheckCartLabel: false,
            WMADistributionShipmentSortOnAlphabeticalOrder: false,
            PDAScanInternalNumberandEditAVEandAVE: false,
            StockTakeAlgorithm: 0,
            PDAHideAdminStockLevelSection: false,
            PDAStockTakeSuggestUPP: false,
            LicSalespricelevels: false,
            SuggestExpectedQuantityForStockTake: false,
            WMAShowMenuMeasuring: false,
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fetchedSettings: any[] = await firstValueFrom(this.httpClient.get<any[]>(`${baseUrl}settings`));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fetchedSettings.forEach((setting: any) => {
            const settingKey: string = setting.item2 as string;
            const settingType: string = setting.item3 as string;
            const settingValue: string = setting.item4 as string;

            const settingPropertyValue: boolean | string | number | undefined = this.getValue(absSettings, settingKey as keyof typeof absSettings);

            let newValue: boolean | string | number;

            // Needs to be extended with other types
            if (!_.isNil(settingPropertyValue)) {
                if (settingType.includes('Boolean')) {
                    newValue = settingValue === 'True';

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (absSettings as any)[settingKey] = newValue;
                }

                if (settingType.includes('Int')) {
                    newValue = parseInt(settingValue);

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (absSettings as any)[settingKey] = newValue;
                }

                if (settingType.includes('String')) {
                    newValue = settingValue as string;

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (absSettings as any)[settingKey] = newValue;
                }
            }
        });

        return absSettings;
    }

    getBooleanSetting(settings: AbsSettings, settingKey: string): boolean {
        if (_.isNil(settingKey) || _.isEmpty(settingKey)) {
            throw new Error('Setting key is required');
        }

        const settingPropertyValue: boolean | string | number | undefined = this.getValue(settings, settingKey as keyof typeof settings);

        if (!_.isNil(settingPropertyValue)) {
            return Boolean(settingPropertyValue);
        } else {
            return false;
        }
    }

    getNumberSetting(settings: AbsSettings, settingKey: string): number | undefined {
        if (_.isNil(settingKey) || _.isEmpty(settingKey)) {
            throw new Error('Setting key is required');
        }

        const settingPropertyValue: boolean | string | number | undefined = this.getValue(settings, settingKey as keyof typeof settings);

        if (!_.isNil(settingPropertyValue)) {
            try {
                return parseInt(settingPropertyValue as string);
            } catch {
                return undefined;
            }
        }

        return undefined;
    }

    async getUserKey(): Promise<number> {
        const baseUrl: string = await this.getBaseUrl();

        // Disabled becuase we only need the user key and nothing else, for the model see ABSLogiService in old WMA project
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const userResult: any = await firstValueFrom(this.httpClient.get<any>(`${baseUrl}users/loggedin`));

        return userResult.userKey as number;
    }

    async getPartyKeysByIdentifier(internalNumber: string): Promise<number[]> {
        if (_.isEmpty(internalNumber)) throw new Error('Internal number cannot be whitespace');

        const partyIdentifierTask: PartyIdentifierTask = {
            partyIdentifier: internalNumber,
            departmentTypeFilter: 2,
            partyIdentifierOptions: new PartyIdentifierOptions(),
        };

        partyIdentifierTask.partyIdentifierOptions.searchInExternalNumber = false;
        partyIdentifierTask.partyIdentifierOptions.useLogisticID = false;
        partyIdentifierTask.partyIdentifierOptions.restrictToPurchaseLots = false;
        partyIdentifierTask.partyIdentifierOptions.useHamifleurBarcode = false;
        partyIdentifierTask.partyIdentifierOptions.useHoekhuisBarcode = false;
        partyIdentifierTask.partyIdentifierOptions.useKoperBarcode = false;
        partyIdentifierTask.partyIdentifierOptions.useVBAFloraBarcode = false;
        partyIdentifierTask.partyIdentifierOptions.useLogisticalUnitsBarcode = false;
        partyIdentifierTask.partyIdentifierOptions.useZandbergenBarcode = false;
        partyIdentifierTask.partyIdentifierOptions.excludeAssortmentLots = false;
        partyIdentifierTask.partyIdentifierOptions.includePartyTypeStock = true;
        partyIdentifierTask.partyIdentifierOptions.includePartyTypeProduction = true;
        partyIdentifierTask.partyIdentifierOptions.includePartyTypePurchase = true;
        partyIdentifierTask.partyIdentifierOptions.searchInBasePartyInternalNumber = false;
        partyIdentifierTask.partyIdentifierOptions.includeReceivedLots = true;
        partyIdentifierTask.partyIdentifierOptions.pdaScanInternalNumberandEditAVEandAVE = this.applicationService.getAbsSettings().PDAScanInternalNumberandEditAVEandAVE;
        partyIdentifierTask.partyIdentifierOptions.searchInDescription = false;
        partyIdentifierTask.partyIdentifierOptions.searchInCashAndCarryID = false;
        partyIdentifierTask.partyIdentifierOptions.searchInSupplierCode = false;
        partyIdentifierTask.partyIdentifierOptions.searchInCashAndCarryIDSources = false;
        partyIdentifierTask.partyIdentifierOptions.searchInInternalNumber = true;

        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.post<number[]>(`${baseUrl}wm/lots/findpartybyidentifier`, partyIdentifierTask));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getValue<T, K extends keyof T>(data: T, key: K): any {
        return data[key];
    }
}
