import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import _ from 'lodash';
import { Tenant } from '../models/tenant.model';
import { Toolkit } from '../utilities/toolkit';
import { Feature, Permission, Setting } from '../models/auth-config.model';
import { AbsSettings } from '../models/abs-settings';

@Injectable({
    providedIn: 'root',
})
export class ApplicationService {
    private readonly _logisticsServiceUrl: BehaviorSubject<string> = new BehaviorSubject('');
    private readonly _absApiServiceUrl: BehaviorSubject<string> = new BehaviorSubject('');
    private readonly _features: BehaviorSubject<Feature[]> = new BehaviorSubject([]);
    private readonly _permissions: BehaviorSubject<Permission[]> = new BehaviorSubject([]);
    private readonly _settings: BehaviorSubject<Setting[]> = new BehaviorSubject([]);
    private readonly _tenants: BehaviorSubject<Tenant[]> = new BehaviorSubject([]);
    private readonly _tenant: BehaviorSubject<Tenant> = new BehaviorSubject(null);
    private readonly _userKey: BehaviorSubject<number> = new BehaviorSubject(0);
    private readonly _absSettings: BehaviorSubject<AbsSettings | undefined> = new BehaviorSubject<AbsSettings | undefined>(undefined);

    public readonly onTenantSwitched: Subject<void> = new Subject<void>();
    public readonly initialized = new ReplaySubject<boolean>(1);

    setLogisticsServiceUrl(url: string): void {
        if (_.isNil(url) || _.isEmpty(url)) throw new Error('Unable to set logistics service URL, url cannot be undefined, null or empty');

        this._logisticsServiceUrl.next(url);
    }

    setAbsApiServiceUrl(url: string): void {
        if (_.isNil(url) || _.isEmpty(url)) throw new Error('Unable to set abs-api service URL, url cannot be undefined, null or empty');

        this._absApiServiceUrl.next(url);
    }

    setFeatures(features: Feature[]): void {
        if (_.isNil(features)) throw new Error('Unable to set features, features cannot be undefined, null or empty');

        this._features.next(features);
    }

    setPermissions(permissions: Permission[]): void {
        if (_.isNil(permissions)) throw new Error('Unable to set permissions, permissions cannot be undefined, null or empty');

        this._permissions.next(permissions);
    }

    setSettings(settings: Setting[]): void {
        if (_.isNil(settings)) throw new Error('Unable to set settings, settings cannot be undefined, null or empty');

        this._settings.next(settings);
    }

    setTenants(tenants: Tenant[]): void {
        if (_.isNil(tenants) || _.isEmpty(tenants)) throw new Error('Unable to set tenants, tenants cannot be undefined, null or empty');

        this._tenants.next(tenants);
    }

    setTenant(tenant: Tenant, initialTenant?: boolean): void {
        if (_.isNil(tenant)) throw new Error('Unable to set tenant, tenant cannot be undefined or null');

        this._tenant.next(tenant);
        if (!initialTenant) this.onTenantSwitched.next();
    }

    setUserKey(userKey: number): void {
        if (_.isNil(userKey)) throw new Error('Unable to set user key, user key cannot be undefined, null or empty');

        this._userKey.next(userKey);
    }

    setAbsSettings(absSettings: AbsSettings): void {
        if (_.isNil(absSettings)) throw new Error('Unable to set abs settings, abs settings cannot be undefined or null');

        this._absSettings.next(absSettings);
    }

    getLogisticsServiceUrl(): string {
        return Toolkit.valueFrom(this._logisticsServiceUrl);
    }

    getAbsApiServiceUrl(): string {
        return Toolkit.valueFrom(this._absApiServiceUrl);
    }

    getFeatures(): Feature[] {
        return Toolkit.valueFrom(this._features);
    }

    getPermissions(): Permission[] {
        return Toolkit.valueFrom(this._permissions);
    }

    getSettings(): Setting[] {
        return Toolkit.valueFrom(this._settings);
    }

    getTenants(): Tenant[] {
        return Toolkit.valueFrom(this._tenants);
    }

    getTenant(): Tenant {
        return Toolkit.valueFrom(this._tenant);
    }

    getUserKey(): number {
        return Toolkit.valueFrom(this._userKey);
    }

    getAbsSettings(): AbsSettings | undefined {
        return Toolkit.valueFrom(this._absSettings);
    }
}
