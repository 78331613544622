/* eslint-disable curly */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { angularImports } from '../../../../utilities/global-imports';
import { PageTemplateComponent } from '../../../../templates/page-template/page-template.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import _ from 'lodash';
import { ScanService } from '../../../../services/scan.service';
import { Subscription } from 'rxjs';
import { EntityIdentification, EntityType } from '../../../../utilities/entity-identification';
import { ToastService } from '../../../../components/toast/toast.service';
import { LogisticsService } from '../../../../services/logistics.service';
import { TransportCarrier } from '../../../../models/transport-carrier.model';
import { Cask } from '../../../../models/cask';

@Component({
    standalone: true,
    selector: 'ax-tc-cask-selection-page',
    templateUrl: './tc-cask-selection-page.component.html',
    styleUrl: './tc-cask-selection-page.component.scss',
    imports: [angularImports],
})
export class TcCaskSelectionPageComponent implements OnInit, AfterViewInit, OnDestroy {
    private scanSubscription: Subscription;
    private shipmentKey: number = 0;
    private labelId: number = 0;
    private entityType: EntityType | undefined = undefined;

    protected casks: Cask[] = [];

    constructor(
        private readonly scanService: ScanService,
        private readonly pageTemplate: PageTemplateComponent,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly toasterService: ToastService,
        private readonly logisticsService: LogisticsService
    ) {}

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(async (params: Params) => {
            try {
                this.shipmentKey = parseInt(params['shipmentKey']);
                this.labelId = parseInt(params['labelId']);
                this.entityType = params['entityType'] as EntityType;

                this.casks = await this.logisticsService.getCasksByShipmentKey(this.shipmentKey);

                if (_.isEmpty(this.casks)) {
                    this.toasterService.warning('No casks found');
                }
            } catch {
                this.toasterService.danger('Error', 'Unable to load route values');
                return;
            }
        });

        this.scanSubscription = this.scanService.onScan.subscribe(async (barcode: string) => {
            const entity: EntityIdentification | Error = EntityIdentification.validateBarcode(barcode);
            if (entity instanceof Error) {
                this.toasterService.danger('Invalid barcode', barcode);
                return;
            } else {
                if (entity.entityType === EntityType.Packing) {
                    await this.createTransportCarrier(entity.entityKey);
                } else {
                    this.toasterService.warning('warning', 'The barcode that was scanned is not a valid cask');
                }
            }
        });
    }

    ngAfterViewInit(): void {
        // Add a tiny delay to prevent race condition on change detection
        _.delay(() => {
            this.pageTemplate.setBarcodePlaceholder('Scan cask barcode');
        }, 10);
    }

    ngOnDestroy(): void {
        this.scanSubscription.unsubscribe();
        this.pageTemplate.setBarcode('');
    }

    protected createBarcode(id: string, entityKey: string): string {
        const count: number = 10 - id.length;
        let barcode: string = entityKey;

        for (let i: number = 0; i < count; i++) {
            barcode = `${barcode}0`;
        }

        return `${barcode}${id}`;
    }

    protected async selectCask(event: Event, cask: Cask): Promise<void> {
        event.stopPropagation();

        await this.createTransportCarrier(cask.key);
    }

    private async createTransportCarrier(caskKey: number): Promise<void> {
        try {
            const transportCarrierKey: number = await this.logisticsService.createTransportCarrier(caskKey, this.shipmentKey);
            const transportCarrier: TransportCarrier = await this.logisticsService.getTransportCarrier(transportCarrierKey);

            if (_.isNil(transportCarrier)) {
                this.toasterService.danger('Error', 'Created transport carrier could not be found');
            }

            this.router.navigate([`distribution/tc-registration/${this.labelId}/${this.createBarcode(transportCarrier.transportCarrierId, '95')}/${this.entityType}`], { queryParams: { IsNewTC: true } });
        } catch {
            this.toasterService.danger('Error', 'Unable to create transport carrier');
            return;
        }
    }
}
