/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { SelectComponent } from '../../components/select/select.component';
import { ButtonDirective } from '../../directives/button.directive';
import { DIALOG_DATA, DialogComponentRef } from '../../components/dialog/models/dialog-component-ref';
import { SelectOption } from '../../components/select/interfaces/select-option';
import _ from 'lodash';
import { Party } from '../../models/party.model';

@Component({
    standalone: true,
    templateUrl: './party-select-dialog.component.html',
    styleUrl: './party-select-dialog.component.scss',
    imports: [ButtonDirective, SelectComponent],
})
export class PartySelectDialogComponent implements OnInit {
    protected partyOptions: SelectOption[] = [];
    protected selectedPartyInternalNumber: string = '';

    constructor(
        @Inject(DIALOG_DATA) private readonly dialogData: any,
        private readonly dialogComponentRef: DialogComponentRef<PartySelectDialogComponent>
    ) {}

    ngOnInit(): void {
        if (!_.isNil(this.dialogData) && !_.isNil(this.dialogData.parties)) {
            const parties: Party[] = this.dialogData.parties as Party[];

            parties.forEach((party: Party) => {
                this.partyOptions.push({
                    value: party.internalNumber,
                    display_value: `${party.internalNumber} - ${party.description}`,
                });
            });
        }
    }

    protected confirmSelection(): void {
        this.dialogComponentRef.closeDialog(this.selectedPartyInternalNumber);
    }

    protected close(): void {
        this.dialogComponentRef.closeDialog();
    }
}
