import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScanService } from '../../../services/scan.service';
import { PageTemplateComponent } from '../../../templates/page-template/page-template.component';
import _ from 'lodash';
import { EntityIdentification } from '../../../utilities/entity-identification';
import { ToastService } from '../../../components/toast/toast.service';
import { LogisticsService } from '../../../services/logistics.service';
import { AbsApiService } from '../../../services/abs-api.service';
import { StorageLocation } from '../../../models/storage-location.model';
import { PartyStorageLocation } from '../../../models/party-storage-location';
import { commonViewImports } from '../../../utilities/global-imports';
import { ListViewItemDefinition } from '../../../components/list-view/interfaces/list-view-item-definition';
import { ListViewDefinitionType } from '../../../components/list-view/enums/list-view-item-definition-type';
import { ListViewComponent } from '../../../components/list-view/list-view.component';
import { LocalSettings } from '../../../models/local-settings';
import { SettingsHelper } from '../../../utilities/settings-helper';

@Component({
    standalone: true,
    selector: 'ax-putaway-transfer-page',
    templateUrl: './transfer-page.component.html',
    styleUrl: './transfer-page.component.scss',
    imports: [commonViewImports],
})
export class TransferPageComponent implements OnInit, AfterViewInit, OnDestroy {
    partyStorageLocations: PartyStorageLocation[] = [];
    storageLocation: StorageLocation | undefined;
    isLoading: boolean = false;

    protected listViewDefinitions: ListViewItemDefinition[] = [
        { caption: 'Internal number', property_name: 'party.internalNumber', type: ListViewDefinitionType.Default },
        { caption: 'Storage location', property_name: 'storageLocation.description', type: ListViewDefinitionType.Default },
    ];

    private scanSubscription: Subscription | undefined;
    private settings: LocalSettings = SettingsHelper.loadSettings();
    private barcode: string = '';

    @ViewChild(ListViewComponent) private readonly listViewComponent: ListViewComponent;

    constructor(
        private readonly scanService: ScanService,
        private readonly toastService: ToastService,
        private readonly logisticsService: LogisticsService,
        private readonly absApiService: AbsApiService,
        private readonly pageTemplate: PageTemplateComponent
    ) {}

    async ngOnInit(): Promise<void> {
        this.scanSubscription = this.scanService.onScan.subscribe(async (barcode) => {
            this.isLoading = true;
            if (barcode.length === 12) {
                this.barcode = barcode;
                await this.getPartyStorageLocations(this.barcode);
            } else if (this.storageLocation !== undefined) await this.insertPartyStorageLocationWithoutUnits(barcode);
            else this.toastService.danger('Storage location', 'No storage location was selected');

            this.isLoading = false;
        });
    }

    ngAfterViewInit(): void {
        _.delay(() => {
            this.pageTemplate.setBarcodePlaceholder('Search a storage location');
        }, 10);
    }

    ngOnDestroy(): void {
        this.scanSubscription.unsubscribe();
        this.pageTemplate.setBarcode('');
    }

    protected getEmptyStateText(): string {
        if (this.settings.show_barcode_bar) return 'Scan or type a barcode to start tranfer';
        else return 'Scan a barcode to start transfer';
    }

    private async getPartyStorageLocations(barcode: string): Promise<void> {
        const entity: EntityIdentification | Error = EntityIdentification.validateBarcode(barcode);
        if (!(entity instanceof Error)) {
            this.partyStorageLocations = [];
            const partyStorageLocations = await this.logisticsService.getPartyStorageLocationByStorageLocationId(entity.entityKey);
            this.scanService.successSound();

            const firstParty = partyStorageLocations.find((party) => party !== null);

            if (firstParty === undefined) {
                const storageLocation = await this.absApiService.getStorageLocation(entity.entityKey, '');
                this.storageLocation = storageLocation;
            } else {
                this.storageLocation = firstParty.storageLocation;
                if (partyStorageLocations.length > 0) this.partyStorageLocations.push(...partyStorageLocations);
            }

            this.pageTemplate.setBarcode('');
            this.pageTemplate.setBarcodePlaceholder('Search a lot');
        } else this.toastService.danger('Barcode', 'Invalid barcode was scanned');
    }

    private async insertPartyStorageLocationWithoutUnits(barcode: string): Promise<void> {
        const entityKey = parseInt(barcode);
        const party = await this.logisticsService.getPartyByInternalNumber(entityKey);

        if (party === null) {
            this.toastService.danger('Lot', `No lot was found for internal number: ${entityKey}`);
            return;
        }

        const partyKey = party.partyKey;
        const targetLocationKey = this.storageLocation.storageLocationId;
        const sourcePartyStorageLocationKey = party.partyStorageLocations.length > 0 ? party.partyStorageLocations[0].key : null;

        if (sourcePartyStorageLocationKey === null) await this.logisticsService.createPartyStorageLocationTransactionAsync(partyKey, targetLocationKey);
        else await this.logisticsService.createPartyStorageLocationTransactionAsync(partyKey, targetLocationKey, sourcePartyStorageLocationKey);

        this.toastService.success('Transfer', `Transferred packing units to storage location ${this.storageLocation.description} successfully`);

        setTimeout(async () => {
            await this.getPartyStorageLocations(this.barcode);
        });
    }
}
