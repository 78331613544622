/* eslint-disable curly */
import { Component, OnInit } from '@angular/core';
import { angularImports } from '../../../utilities/global-imports';
import { ButtonDirective } from '../../../directives/button.directive';
import { AccessService } from '../../../services/access.service';

@Component({
    standalone: true,
    selector: 'ax-distribution-page',
    templateUrl: './distribution-overview-page.component.html',
    styleUrl: './distribution-overview-page.component.scss',
    imports: [angularImports, ButtonDirective],
})
export class DistributionOverviewPageComponent implements OnInit {
    protected isLoading: boolean = false;
    protected showTcRegistration: boolean = false;
    protected showShipmentCheck: boolean = false;
    protected showLoadRegistration: boolean = false;
    protected showStockDistribute: boolean = false;
    protected showDeliveryNote: boolean = false;

    constructor(private readonly accessService: AccessService) {}

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.showTcRegistration = await this.accessService.hasAccess('tc-registration');
        this.showShipmentCheck = await this.accessService.hasAccess('shipment-check');
        this.showLoadRegistration = await this.accessService.hasAccess('load-registration');
        this.showStockDistribute = await this.accessService.hasAccess('stock-distribute');
        this.showDeliveryNote = await this.accessService.hasAccess('delivery-note');
        this.isLoading = false;
    }
}
