import _ from 'lodash';

export enum EntityType {
    None = 0,
    Supplier = 1,
    Article = 5,
    ProductGroup = 6,
    Color = 8,
    ColorCategory = 13,
    TradeArticle = 221,
    Lot = 3,
    StorageLocation = 70,
    LogisticalUnit = 71,
    PickOrderContextGroup = 73,
    BoxRow = 41,
    Packing = 69,
    PackingLabel = 79,
    TransportCarrierLayer = 94,
    TransportCarrier = 95,
    PricelistTemplate = 130,
    Shipment = 211,
    PricelistTemplateRow = 256,
    MeasurementPointMeasure = 229,
    Category = 233,
    PartySalesPriceLevel = 234,
    ArticlePricePeriod = 236,
}

export class EntityIdentification {
    entityType: EntityType;
    entityKey: number;

    constructor(entityType: EntityType, entityKey: number) {
        this.entityType = entityType;
        this.entityKey = entityKey;
    }

    public static validateBarcode(entityBarcode: string): EntityIdentification | Error {
        if (_.isNil(entityBarcode) || _.isEmpty(entityBarcode)) throw new Error('Barcode cannot be null, undefined or empty');

        return this.getEntityIdentification(entityBarcode, entityBarcode.length - 10);
    }

    public static getEntityIdentification(entityBarcode: string, entityIdLength: number): EntityIdentification | Error {
        const entityId: number = Number(entityBarcode.substring(0, entityIdLength));
        const entityType: EntityType = entityId as EntityType;

        if (entityType) {
            const entityKey = Number(entityBarcode.substring(entityIdLength, entityBarcode.length));
            return new EntityIdentification(entityType, entityKey);
        } else return new Error('Unknown entity type');
    }
}
