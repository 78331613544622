import { Component, OnInit, ViewChildren } from '@angular/core';
import { commonViewImports } from '../../utilities/global-imports';
import { ButtonDirective } from '../../directives/button.directive';
import { ValidationHost } from '../../validator/validation-host';
import { ValidateErrorDirective } from '../../validator/directives/validate-error.directive';
import { ValidateDirective } from '../../validator/directives/validate.directive';
import { DialogComponentRef } from '../../components/dialog/models/dialog-component-ref';
import { ToastService } from '../../components/toast/toast.service';
import { Validator } from '../../validator/validator';
import { PartyIdentifierTask } from '../../models/party-identifier-task.model';
import { PartyIdentifierOptions } from '../../models/party-identifier-options.model';
import { PartyIdentifierResult } from '../../models/party-identifier-result.model';
import { AbsApiService } from '../../services/abs-api.service';
import dayjs from 'dayjs';

@Component({
    standalone: true,
    templateUrl: './search-supplier-dialog.component.html',
    styleUrl: './search-supplier-dialog.component.scss',
    imports: [commonViewImports, ButtonDirective],
})
export class SearchSupplierDialogComponent implements OnInit, ValidationHost {
    supplier: string = '';
    fromDate: string = dayjs().startOf('year').format('YYYY-MM-DD');
    untilDate: string = dayjs().format('YYYY-MM-DD');

    private validator: Validator;

    @ViewChildren(ValidateDirective) private readonly validateDirectives: ValidateDirective[];
    @ViewChildren(ValidateErrorDirective) private readonly validateErrorDirectives: ValidateErrorDirective[];

    constructor(
        private readonly dialogComponentRef: DialogComponentRef<SearchSupplierDialogComponent>,
        private readonly absApiService: AbsApiService,
        private readonly toastService: ToastService
    ) {}

    ngOnInit(): void {
        try {
            this.validator = new Validator(this);
        } catch (err) {
            this.toastService.danger('Loading error', err as string);
        }
    }

    getValidationItems(): ValidateDirective[] {
        return this.validateDirectives;
    }

    getValidationErrorItems(): ValidateErrorDirective[] {
        return this.validateErrorDirectives;
    }

    protected async searchSupplier(): Promise<void> {
        try {
            this.validator.resetValidationStates();
            const isValid: boolean = this.validator.validate();

            if (!isValid) this.toastService.warning('Validation error', 'Missing required fields');
            else {
                const task: PartyIdentifierTask = {
                    partyIdentifier: this.supplier,
                    fromDate: this.fromDate,
                    toDate: this.untilDate,
                    partyIdentifierOptions: new PartyIdentifierOptions(),
                };
                const result: PartyIdentifierResult = await this.absApiService.getPartyBySupplier(task);
                this.dialogComponentRef.closeDialog(result);
            }
        } catch (err) {
            this.toastService.danger('Error', err as string);
        }
    }

    protected close(): void {
        this.dialogComponentRef.closeDialog();
    }
}
