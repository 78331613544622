import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsightsService } from './application-insights.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
    constructor(private applicationInsightsService: ApplicationInsightsService) {
        super();
    }

    override handleError(error: Error): void {
        if (!environment.production)
            if (error.message.startsWith('NG0100:'))
                // We filter change detection error here since it only thrown in development mode and is a non-breaking error, should be fixed when possible
                console.warn(error);
            else console.error(error);

        this.applicationInsightsService.logException(error);
    }
}
