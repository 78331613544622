<div class="distribution-overview-page__container">
    @if (!isLoading) {
        @if (showTcRegistration) {
            <button ax-button="primary"  class="distribution-overview-page__container__button" [routerLink]="'/distribution/tc-registration'">
                <img src="/assets/icons/icon_container_registration.png" alt="container_registration"/>
                TC Registration
            </button>
        }

        @if (showShipmentCheck) {
            <button ax-button="primary" class="distribution-overview-page__container__button" [routerLink]="'/distribution/shipment-check'">
                <img src="/assets/icons/icon_flowscan.png" alt="flowscan"/>
                Shipment Check
            </button>
        }

        @if (showLoadRegistration) {
            <button ax-button="primary" class="distribution-overview-page__container__button" [routerLink]="'/distribution/load-registration'">
                <img src="/assets/icons/icon_load_registration.png" alt="load_registration" />
                Load Registration
            </button>
        }

        @if (showStockDistribute) {
            <button ax-button="primary" class="distribution-overview-page__container__button" [routerLink]="'/distribution/stock-distribute'">
                <img src="/assets/icons/icon_stock_distribute.png" alt="stock_distribute"/>
                Stock Distribute
            </button>
        }

        @if (showDeliveryNote) {
            <button ax-button="primary" class="distribution-overview-page__container__button" [routerLink]="'/distribution/delivery-note'">
                <img src="/assets/icons/icon_delivery_note.png" alt="delivery_note"/>
                Delivery Note
            </button>
        }
    }
</div>
