<div class="ax-page-template__container">
    <div class="ax-page-template__nav-bar">
        <div class="ax-page-template__nav-bar__navigation__and__brand">
            <button ax-button="primary" [icon-only]="true" (click)="toggleNavigationPanel($event)">
                <i class="fa-light fa-bars"></i>
            </button>
            <div class="ax-navigation__and__brand__content">
                <img src="/assets/images/logo.svg" alt="logo" class="ax-navigation__and__brand__content__logo" [routerLink]="'/'" />
                <h3 class="ax-navigation__and__brand__content__title">Warehouse Management</h3>
            </div>
        </div>
        @if (showBarcode && isDesktop) {
            <div class="ax-page-template__nav-bar__barcode">
                <ax-barcode [placeholder]="barcodeComponentPlaceholder" [isStandalone]="false" [(barcode)]="barcode"></ax-barcode>
            </div>
        }

        @if (!isLoading && isAuthenticated) {
            <div class="ax-page-template__nav-bar__user" (click)="openUserPanel($event)">
                <span class="ax-page-template__nav-bar__user__name">{{ userName }}</span>
                <ax-avatar [name]="userName"></ax-avatar>
            </div>
        }
    </div>
    <div class="ax-page-template__content">
        @if (showBarcode && !isDesktop) {
            <div class="ax-page-template__content__barcode">
                <div class="ax-page-template__content__back">
                    <button ax-button="primary" [icon-only]="true" (click)="navigateBack()">
                        <i class="fa-light fa-arrow-left"></i>
                    </button>
                </div>
                <ax-barcode [placeholder]="barcodeComponentPlaceholder" [(barcode)]="barcode"></ax-barcode>
            </div>
        }
        <!-- @if (showBreadCrumbs) {
            <div class="ax-page-template__content__breadcrumb">
                <i class="fa-light fa-house ax-page-template__content__breadcrumb__link" (click)="handleBreadCrumb('/home')"></i>
                <i class="fa-light fa-angle-right"></i>
                @for (breadcrumb of breadCrumbs; track breadcrumb.route) {
                    <span class="ax-page-template__content__breadcrumb__link" (click)="handleBreadCrumb(breadcrumb.route)">{{ breadcrumb.display_name }}</span>
                    @if (!$last) {
                        <i class="fa-light fa-angle-right"></i>
                    }
                }
            </div>
        } -->
        <router-outlet></router-outlet>
    </div>
</div>
@if (!isLoading && isAuthenticated) {
    <ax-side-panel>
        <div class="ax-navigation-menu__container">
            <div class="ax-navigation-menu__header">
                <img src="/assets/images/logo.svg" alt="logo" class="ax-navigation-menu__header__logo" (click)="handleNavigation('/')" />
                <button ax-button [icon-only]="true" (click)="toggleNavigationPanel($event)">
                    <i class="fa-light fa-xmark"></i>
                </button>
            </div>
            <div class="ax-navigation-menu__items">
                @for (item of navigationItems; track item.route) {
                    <div class="ax-navigation-menu__items__item" (click)="handleNavigation(item.route)">
                        <i class="ax-navigation-menu__items__item__icon" [ngClass]="item.icon_class"></i>
                        <span>{{ item.title }}</span>
                    </div>
                }
            </div>
            <div class="ax-navigation-menu__info">
                <small>Tenant: {{ tenant.name }}</small>
                <small>Version: {{ version }}</small>
            </div>
        </div>
    </ax-side-panel>
}
