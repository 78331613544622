<div class="ax-tab-container__container">
    <div class="ax-tab__navigation__container">
        @for (tab of tabComponents; track tab) {
            <div class="ax-tab__navigation__item" [ngClass]="tab.isVisible ? 'ax-tab__navigation__item--visible' : ''" (click)="selectTab($event, tab)">
                @if (tab.iconClass !== '') {
                    <i class="ax-tab__navigation__item__icon" [ngClass]="tab.iconClass"></i>
                }
                <small class="ax-tab__navigation__item__text" [ngClass]="tab.iconClass === '' ? 'ax-tab__navigation__item__text--center' : ''">{{tab.title}}</small>
            </div>
        }
    </div>
    <div class="ax-tab__content">
        <ng-content></ng-content>
    </div>
</div>