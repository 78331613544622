<div class="shipment-check-details__container">
    @if (!isLoading) {
        <div class="shipment__header__title">
            <h4>
                <span>{{shipment.transportLine.transporter}} - </span>
                <span>{{shipment.shipDate.toString() | date}}  - </span>
                <span>{{shipment.manualShipmentNumber}}</span>
            </h4>
        </div>
        <div class="shipment__values">
            <div class="shipment__values__quantities">
                <div class="shipment__values__quantities__container">
                    <div class="shipment__quantity__block">
                        <small>Total</small>
                        <span>{{total}}</span>
                    </div>
                    <div class="shipment__quantity__block">
                        <small>To scan</small>
                        <span>{{toScan}}</span>
                    </div>
                    <div class="shipment__quantity__block">
                        <small>Open</small>
                        <span>{{openPackings}}</span>
                    </div>
                    <div class="shipment__quantity__block">
                        <small>Sealed</small>
                        <span>{{sealedPackings}}</span>
                    </div>
                    <div class="shipment__quantity__block">
                        <small>Loose</small>
                        <span>{{loosePackings}}</span>
                    </div>
                </div>
            </div>
        </div>
        <ax-list-view
            [isLoading]="isLoading"
            [definitions]="listViewDefinitions"
            [data]="boxRows"
            [columnCount]="'auto'"
            (rowClicked)="showLabelsDialog($event)"
            [booleanIconTrue]=""
            [booleanIconFalse]=""
            [noDataText]="''"
            [rowClassCallbackFn]="getRowClass">
        </ax-list-view>
    }
</div>
