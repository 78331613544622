<div class="home-page__container">
    @if (!isLoading) {
        @if (showReceive) {
            <button ax-button="primary"  class="home-page__container__button" [routerLink]="'/receive'">
                <img src="/assets/icons/icon_warehouse_checkin.png" alt="warehouse_checkin"/>
                Receive
            </button>
        }
    
        @if (showPutaway) {
            <button ax-button="primary" class="home-page__container__button" [routerLink]="'/putaway'">
                <img src="/assets/icons/icon_warehouse_putaway.png" alt="warehouse_putaway"/>
                Putaway
            </button>
        }
    
        @if (showStockTake) {
            <button ax-button="primary" class="home-page__container__button" [routerLink]="'/stocktake'">
                <img src="/assets/icons/icon_warehouse_stocktake.png" alt="warehouse_stocktake"/>
                Stocktake
            </button>
        }
    
        @if (showDistribution) {
            <button ax-button="primary" class="home-page__container__button" [routerLink]="'/distribution'">
                <img src="/assets/icons/icon_distributie.png" alt="distributie"/>
                Distribution
            </button>
        }
    
        @if (showSales) {
            <button ax-button="primary" class="home-page__container__button" [routerLink]="'/sales'">
                <img src="/assets/icons/icon_sales.png" alt="sales"/>
                Sales
            </button>
        }

        @if (showMeasuring) {
            <button ax-button="primary" class="home-page__container__button" [routerLink]="'/measuring'">
                <img src="/assets/icons/icon_orderpicking_barcode.png" alt="measuring"/>
                Measuring
            </button>
        }
    }
</div>
