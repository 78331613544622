/* eslint-disable @typescript-eslint/no-explicit-any */
import { InjectionToken } from '@angular/core';

export interface ContextMenuConfig {
    position: 'top' | 'right' | 'bottom' | 'left';
    elementSpacing: number;
    menuWidth: number;
}

export interface ContextMenuGlobalConfig extends ContextMenuConfig {
    allow_multiple_open: boolean;
    root_container_class: string;
}

export const CONTEXT_MENU_CONFIG: InjectionToken<ContextMenuGlobalConfig> = new InjectionToken<ContextMenuGlobalConfig>('ContextMenuConfig');
export const CONTEXT_MENU_DATA: InjectionToken<any> = new InjectionToken<any>('ContextMenuData');
