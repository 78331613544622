import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserData } from '../models/user-data';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly _isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _accessToken: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private readonly _userData: BehaviorSubject<UserData> = new BehaviorSubject<UserData>(null);

    public readonly isAuthenticated: Observable<boolean> = this._isAuthenticated.asObservable();

    constructor(private readonly oidcSecurityService: OidcSecurityService) {}

    setIsAuthenticated(isAuthenticated: boolean): void {
        this._isAuthenticated.next(isAuthenticated);
    }

    setAccessToken(accessToken: string): void {
        this._accessToken.next(accessToken);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setUserData(userData: any): void {
        const data: UserData = {
            amr: userData.amr,
            auth_time: userData.auth_time,
            email: userData.email,
            email_verified: userData.email_verified,
            idp: userData.idp,
            name: userData.name,
            preferred_username: userData.preferred_username,
            role: userData.role,
            session_guid: userData.sessionGuid,
            sub: userData.sub,
        };

        this._userData.next(data);
    }

    getIsAuthenticated(): boolean {
        return this._isAuthenticated.value;
    }

    getAccessToken(): string {
        return this._accessToken.value;
    }

    getUserData(): UserData {
        return this._userData.value;
    }

    logOff(): void {
        this.oidcSecurityService.logoff().subscribe();
        window.dispatchEvent(new CustomEvent('AFO-AuthService-LogOff'));
    }
}
