import { Pipe, PipeTransform } from '@angular/core';
import { Converters } from '../utilities/converters';
import _ from 'lodash';

@Pipe({ name: 'time', standalone: true })
export class TimePipe implements PipeTransform {
    transform(value: string) {
        if (_.isNil(value) || _.isEmpty(value)) return '';

        return Converters.toTime(value, true);
    }
}
