/* eslint-disable curly */
import { Component, Inject } from '@angular/core';
import { commonViewImports } from '../../utilities/global-imports';
import { DIALOG_DATA, DialogComponentRef } from '../../components/dialog/models/dialog-component-ref';
import { Label } from '../../models/label.model';
import _ from 'lodash';

interface DialogData {
    orderNumber: string | null;
    barcode: string | null;
    scanned: boolean | null;
}

@Component({
    standalone: true,
    templateUrl: './show-labels-dialog.component.html',
    styleUrls: ['./show-labels-dialog.component.scss'],
    imports: [commonViewImports],
})
export class ShowLabelsDialogComponent {
    title: string | null = null;
    labels: DialogData[] = [];

    booleanIconTrue: string = 'fa-duotone fa-circle-check';
    booleanIconFalse: string = 'fa-duotone fa-circle-xmark';

    constructor(
        @Inject(DIALOG_DATA) private readonly data: Label[],
        private readonly dialogComponentRef: DialogComponentRef<ShowLabelsDialogComponent>
    ) {
        this.title = this.getValidTitle(data);

        this.labels = this.data.map((item, index) => ({
            orderNumber: `${item.orderNumber}.${item.boxNumber}.${index + 1}`,
            barcode: item.barcode,
            scanned: item.scanned,
        }));
    }

    protected setValueBooleanIconClasses(value: boolean): string {
        return value ? `${this.booleanIconTrue} boolean__icon--true` : `${this.booleanIconFalse} boolean__icon--false`;
    }

    protected close(): void {
        this.dialogComponentRef.closeDialog();
    }

    private getValidTitle(labels: Label[]): string {
        let validTitle: string = '';

        for (const label of labels) {
            if (_.isNil(label.article) || _.isEmpty(label.article)) {
                continue;
            } else {
                validTitle = label.article;
                break;
            }
        }

        return validTitle;
    }
}
