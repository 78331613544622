/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AvatarComponent } from '../components/avatar/avatar.component';
import { CheckboxComponent } from '../components/checkbox/checkbox.component';
import { DialogComponent } from '../components/dialog/dialog.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { SelectComponent } from '../components/select/select.component';
import { SidePanelComponent } from '../components/side-panel/side-panel.component';
import { ToastComponent } from '../components/toast/toast.component';
import { ToggleButtonComponent } from '../components/toggle-button/toggle-button.component';
import { ButtonDirective } from '../directives/button.directive';
import { ContextMenuDirective } from '../directives/context-menu.directive';
import { DialogDirective } from '../directives/dialog.directvive';
import { InputDirective } from '../directives/input.directive';
import { ValidateErrorDirective } from '../validator/directives/validate-error.directive';
import { ValidateDirective } from '../validator/directives/validate.directive';
import { TabContainerComponent } from '../components/tabs/tab-container.component';
import { TabComponent } from '../components/tabs/content/tab.component';
import { DateTimePipe } from '../pipes/date-time.pipe';
import { DatePipe } from '../pipes/date.pipe';
import { TimePipe } from '../pipes/time.pipe';
import { TimeAgoPipe } from '../pipes/timeago.pipe';
import { TableComponent } from '../components/table/table.component';
import { ListViewComponent } from '../components/list-view/list-view.component';
import { BarcodeComponent } from '../components/barcode/barcode.component';

export const angularImports: Type<any>[] = [CommonModule, RouterModule, FormsModule];
export const componentImports: Type<any>[] = [
    AvatarComponent,
    BarcodeComponent,
    CheckboxComponent,
    DialogComponent,
    ListViewComponent,
    LoaderComponent,
    SelectComponent,
    SidePanelComponent,
    TableComponent,
    TabContainerComponent,
    TabComponent,
    ToastComponent,
    ToggleButtonComponent,
];
export const directiveImports: Type<any>[] = [ButtonDirective, ContextMenuDirective, DialogDirective, InputDirective, ValidateDirective, ValidateErrorDirective];
export const pipeImports: Type<any>[] = [DateTimePipe, DatePipe, TimePipe, TimeAgoPipe];
export const commonViewImports: Type<any>[] = angularImports.concat(directiveImports).concat(componentImports);
