import { ValidationRule } from './validation-rule';
import * as _ from 'lodash';

export class MinLengthValidationRule implements ValidationRule {
    public readonly key: string = 'min-length';
    public readonly errorMessage: string = '';
    private minLength: number;

    constructor(minLength: number) {
        this.minLength = minLength;
        this.errorMessage = `cannot be less than ${minLength} characters`;
    }

    validateRule<T>(value: T): boolean {
        const val: string = value as string;

        if (_.isNil(val) || _.isEmpty(val)) return false;

        if (val.length < this.minLength) return false;

        return true;
    }

    updateMinLength(minLength: number): void {
        this.minLength = minLength;
    }
}
