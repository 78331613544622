/* eslint-disable curly */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { angularImports } from '../../../../utilities/global-imports';
import { PageTemplateComponent } from '../../../../templates/page-template/page-template.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import _ from 'lodash';
import { ToastService } from '../../../../components/toast/toast.service';
import { ButtonDirective } from '../../../../directives/button.directive';
import { LogisticsService } from '../../../../services/logistics.service';
import { Shipment } from '../../../../models/shipment.model';
import { TransportCarrier } from '../../../../models/transport-carrier.model';
import { CheckboxComponent } from '../../../../components/checkbox/checkbox.component';

@Component({
    standalone: true,
    selector: 'ax-tc-selection-page',
    templateUrl: './tc-selection-page.component.html',
    styleUrl: './tc-selection-page.component.scss',
    imports: [angularImports, ButtonDirective, CheckboxComponent],
})
export class TcSelectionPageComponent implements OnInit, AfterViewInit, OnDestroy {
    private labelId: number = 0;
    private entityType: string;
    private shipment: Shipment;

    protected transportCarriers: TransportCarrier[] = [];

    constructor(
        private readonly pageTemplate: PageTemplateComponent,
        private readonly activatedRoute: ActivatedRoute,
        private readonly toasterService: ToastService,
        private readonly logisticsService: LogisticsService,
        private readonly router: Router
    ) {}

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(async (params: Params) => {
            try {
                this.labelId = parseInt(params['labelId']);
                this.entityType = params['entityType'];

                const isGroupLabel: boolean = this.entityType !== null && this.entityType === '94'; // This is transportCarrierLabel
                this.shipment = await this.logisticsService.getAvailableShipmentByLabelId(this.labelId, isGroupLabel);

                if (_.isNil(this.shipment)) {
                    this.toasterService.danger('Error', 'No shipment found for this label');
                    return;
                } else if (_.isNil(this.shipment.transportCarriers) || _.isEmpty(this.shipment.transportCarriers)) {
                    this.toasterService.danger('Error', 'No available transport carrier found for this label');
                    return;
                } else {
                    this.transportCarriers = this.shipment.transportCarriers;
                }
            } catch {
                this.toasterService.danger('Error', 'Unable to load route values');
                return;
            }
        });
    }

    ngAfterViewInit(): void {
        // Add a tiny delay to prevent race condition on change detection
        _.delay(() => {
            this.pageTemplate.setBarcodePlaceholder('Search a transport carrier');
        }, 10);
    }

    ngOnDestroy(): void {
        this.pageTemplate.setBarcode('');
    }

    protected createTransportCarrierBarcode(transportCarrierId: string): string {
        const count: number = 10 - transportCarrierId.length;
        let barcode: string = '95';

        for (let i: number = 0; i < count; i++) {
            barcode = `${barcode}0`;
        }

        return `${barcode}${transportCarrierId}`;
    }

    protected navigateToTcRegistration(event: Event, transportCarrierId: string): void {
        event.stopPropagation();

        this.router.navigateByUrl(`distribution/tc-registration/${this.labelId}/${this.createTransportCarrierBarcode(transportCarrierId)}/${this.entityType}`);
    }

    protected navigatetoCaskSelectionPage(event: Event): void {
        event.stopPropagation();

        this.router.navigateByUrl(`distribution/tc-cask-selection/${this.shipment.shipmentKey}/${this.labelId}/${this.entityType}`);
    }
}
