<div class="shipment-details__dialog__container">
    <div class="shipment-details__header">
        <h5>Shipment: <b>{{shipmentNumber}}</b></h5>
    </div>
    <div class="shipment-details__row">
        <small>Destination</small>
        <small><b>{{destination}}</b></small>
    </div>
    <div class="shipment-details__row">
        <small>Arival date</small>
        <small><b>{{arrivalDate.toString() | datetime}}</b></small>
    </div>
    <div class="shipment-details__row">
        <small>Total</small>
        <small><b>{{total}}</b></small>
    </div>
    <div class="shipment-details__row">
        <small>To scan</small>
        <small><b>{{toScan}}</b></small>
    </div>
    <div class="shipment-details__row">
        <small>Open</small>
        <small><b>{{open}}</b></small>
    </div>
    <div class="shipment-details__row">
        <small>Closed</small>
        <small><b>{{sealedPackings}}</b></small>
    </div>
    <div class="shipment-details__row">
        <small>Loose</small>
        <small><b>{{loose}}</b></small>
    </div>
    <div class="shipment-details__row">
        <small>Dimensions</small>
        @if (hasDimensionsSet) {
            <small><b>{{dimensions}}</b></small>
        } @else {
            <small><i>{{dimensions}}</i></small>
        }
    </div>
    <div class="shipment-details__actions">
        <button [ax-button] (click)="closeDialog($event)">Close</button>
    </div>
</div>