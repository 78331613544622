import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResource, Tenant } from '../models/tenant.model';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TenantService {
    private readonly baseUrl: string = this.sanitizeUrl(environment.tenant_baseurl_server);

    constructor(private readonly httpClient: HttpClient) {}

    async getTenants(): Promise<Tenant[]> {
        return firstValueFrom(this.httpClient.get<Tenant[]>(`${this.baseUrl}account/tenants`));
    }

    async getTenantApiResources(tenantId: number): Promise<ApiResource[]> {
        return firstValueFrom(this.httpClient.get<ApiResource[]>(`${this.baseUrl}tenant/${tenantId}/apiresources`));
    }

    private sanitizeUrl(url: string): string {
        if (url.endsWith('/')) return url;
        else return `${url}/`;
    }
}
