import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[ax-input]',
    standalone: true,
})
export class InputDirective implements OnInit {
    @Input('is-valid') public set isValid(isValid: boolean) {
        if (isValid) this.element.nativeElement.classList.remove('ax-input--invalid');
        else this.element.nativeElement.classList.add('ax-input--invalid');
    }

    constructor(private readonly element: ElementRef) {}

    ngOnInit(): void {
        this.element.nativeElement.classList.add('ax-input');
    }
}
