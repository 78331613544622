import { Component, OnInit } from '@angular/core';
import { SelectOption } from '../../components/select/interfaces/select-option';
import { LocalSettings } from '../../models/local-settings';
import { commonViewImports } from '../../utilities/global-imports';
import { SettingsHelper } from '../../utilities/settings-helper';
import { ApplicationInsightsService } from '../../services/application-insights.service';

@Component({
    standalone: true,
    selector: 'ax-settings-page',
    templateUrl: './settings-page.component.html',
    styleUrl: './settings-page.component.scss',
    imports: [commonViewImports],
})
export class SettingsPageComponent implements OnInit {
    protected selectedTheme: string = '';
    protected settings: LocalSettings;
    protected themeOptions: SelectOption[] = [
        {
            value: 'light',
            display_value: 'Light',
        },
        {
            value: 'dark',
            display_value: 'Dark',
        },
    ];

    constructor(private readonly aiService: ApplicationInsightsService) {}

    ngOnInit(): void {
        this.settings = SettingsHelper.loadSettings();
        this.loadTheme();
    }

    loadTheme(): void {
        const theme: string = this.settings.theme;

        switch (theme) {
            case 'dark':
                this.selectedTheme = 'dark';
                break;

            default:
                this.selectedTheme = 'light';
                break;
        }
    }

    updateSettings(): void {
        SettingsHelper.updateSettings(this.settings);
        this.aiService.logEvent('settings-updated', {
            settings: JSON.stringify(this.settings),
        });
    }

    switchTheme(theme: string): void {
        switch (theme) {
            case 'dark':
                document.body.classList.add('theme-dark');
                this.settings.theme = 'dark';
                break;

            case 'light':
                document.body.classList.remove('theme-dark');
                this.settings.theme = '';
                break;

            default:
                console.warn('Unrecognized theme found, falling back to light');
                document.body.classList.remove('theme-dark');
                this.settings.theme = '';
                break;
        }

        this.updateSettings();
    }
}
