<div class="measuring-overview__container">
    @if (measurementPoints.length > 0) {
        <ax-list-view
            [isLoading]="isLoading"
            [definitions]="listViewDefinitions"
            [data]="measurementPoints"
            (rowClicked)="onItemClick($event)"
        ></ax-list-view>
    } @else {
        <div class="no__data__container">
            <i class="no__data__container__icon fa-light fa-weight-scale"></i>
            <h5>No measuring points found</h5>
        </div>
    }
</div>
