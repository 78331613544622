<div class="show-labels-dialog__container">
    <div class="show-labels-dialog__header">
        <h4>{{title}}</h4>
        <button ax-button [icon-only]="true" (click)="close()">
            <i class="fa-light fa-xmark"></i>
        </button>
    </div>
    <div class="labels__values">
        @for(label of labels; track label.orderNumber) {
            <div class="labels__values__quantities__container">
                <div class="label__quantity__block">
                    <span>{{label.orderNumber}}</span>
                </div>
                <div class="label__quantity__block">
                    <span>{{label.barcode}}</span>
                </div>
                <div class="label__quantity__block">
                    @if (label.scanned) {
                        <span><i [ngClass]="setValueBooleanIconClasses(true)"></i></span>
                    } @else {
                        <span><i [ngClass]="setValueBooleanIconClasses(false)"></i></span>
                    }
                </div>
            </div>
        }
    </div>
</div>
