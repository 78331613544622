export const environment = {
    production: false,
    version: '24.2.0 (Development)',
    licenseTypeId: '202',
    auth_baseurl_server: 'https://axerrio-accounts-dev.azurewebsites.net',
    tenant_baseurl_server: 'https://multitenant-dev.azurewebsites.net',
    appInsights: {
        instrumentationKey: 'fd1ac029-2ad4-48d0-b5eb-a1218b4fd0cc',
    },
};
