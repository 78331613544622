<div class="distribute-stock-dialog__container">
    <div class="distribute-stock-dialog__header">
        <h3>Distribute stock</h3>
        <button ax-button [icon-only]="true" (click)="close()">
            <i class="fa-light fa-xmark"></i>
        </button>
    </div>
    <div class="distribute-stock-dialog__content">
        <div class="distribute-stock-dialog__content__block">
            <h3>Number of packings on trolley:</h3>
            <span class="distribute-stock-dialog__content__block__label">{{this.description}}: {{this.barcode}}</span>
            <span class="distribute-stock-dialog__content__block__label">{{this.boxes}} x {{this.stems}} = {{this.boxes * this.stems}}</span>
            <input
                type="number"
                ax-input
                placeholder="fill in packings"
                required
                ax-validate="packings"
                onkeypress="return event.charCode >= 48"
                [ax-validation-custom-rules]="packingValidationRules"
                [(ngModel)]="packings"
                (keyup.enter)="distributeStock()"
                >
            <small class="distribute-stock-dialog__content__block__error" ax-validate-error="packings"></small>
        </div>
    </div>
    <div class="distribute-stock-dialog__footer">
        <button ax-button (click)="close()">Cancel</button>
        <button ax-button="primary" (click)="distributeStock()">Ok</button>
    </div>
</div>