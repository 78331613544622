<div class="tc-selection__container">
    <div class="tc-selection__header">
        <h4>Create new transport carrier</h4>
        <button ax-button [icon-only]="true" (click)="navigatetoCaskSelectionPage($event)">
            <i class="fa-solid fa-plus"></i>
        </button>
    </div>
    <div class="tc-selection__content">
        <div class="tc-selection__content__header">
            <h5>Barcode</h5>
            <h5>Fixed</h5>
        </div>
        <div class="tc-selection__content__container">
            @for (transportCarrier of transportCarriers; track transportCarrier.transportCarrierId) {
                <div class="tc-selection__transport-carrier" (click)="navigateToTcRegistration($event, transportCarrier.transportCarrierId)">
                    <span>{{createTransportCarrierBarcode(transportCarrier.transportCarrierId)}}</span>
                    <ax-checkbox [isChecked]="transportCarrier.fixed" [isDisabled]="true"></ax-checkbox>
                </div>
            }
        </div>
    </div>
</div>