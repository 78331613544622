<div class="tc-weight-dialog__container">
    <div class="tc-weight__header">
        <h4>Transportcarrier Weight</h4>
        <small>Enter <b>net</b> and <b>gross</b> weight</small>
    </div>
    <div class="tc-weight__input-block">
        <span class="tc-weight__input-block__label">Net weight</span>
        <input type="number" ax-input placeholder="Net weight" [(ngModel)]="netWeight">
    </div>
    <div class="tc-weight__input-block">
        <span class="tc-weight__input-block__label">Gross weight</span>
        <input type="number" ax-input placeholder="Gross weight" [(ngModel)]="grossWeight">
    </div>
    <div class="tc-weight__actions">
        <button [ax-button] (click)="closeDialog($event)">Cancel</button>
        <button [ax-button]="'primary'" (click)="submit($event)">Submit</button>
    </div>
</div>