import { Pipe, PipeTransform } from '@angular/core';
import { Converters } from '../utilities/converters';
import _ from 'lodash';

@Pipe({ name: 'datetime', standalone: true })
export class DateTimePipe implements PipeTransform {
    transform(value: string, format: string = 'DD-MM-YYYY HH:mm') {
        if (_.isNil(value) || _.isEmpty(value)) return '';

        return Converters.toDateTime(value, format);
    }
}
