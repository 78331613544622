import { Component, OnInit } from '@angular/core';
import { ButtonDirective } from '../../directives/button.directive';
import { DialogComponentRef } from '../../components/dialog/models/dialog-component-ref';
import { SelectComponent } from '../../components/select/select.component';
import { ApplicationService } from '../../services/application.service';
import { SelectOption } from '../../components/select/interfaces/select-option';
import { Tenant } from '../../models/tenant.model';
import _ from 'lodash';
import { ToastService } from '../../components/toast/toast.service';
import { Router } from '@angular/router';

@Component({
    standalone: true,
    templateUrl: './tenant-switch-dialog.component.html',
    styleUrl: './tenant-switch-dialog.component.scss',
    imports: [ButtonDirective, SelectComponent],
})
export class TenantSwitchDialogComponent implements OnInit {
    protected tenantoptions: SelectOption[] = [];
    protected selectedTenantId: string = '';

    constructor(
        private readonly dialogComponentRef: DialogComponentRef<TenantSwitchDialogComponent>,
        private readonly applicationService: ApplicationService,
        private readonly toastService: ToastService,
        private readonly router: Router
    ) {}

    ngOnInit(): void {
        this.applicationService.getTenants().forEach((tenant: Tenant) => {
            this.tenantoptions.push({
                value: tenant.tenantId.toString(),
                display_value: tenant.name,
            });
        });

        this.selectedTenantId = this.applicationService.getTenant().tenantId.toString();
    }

    setSelectedTenant(): void {
        if (_.isEmpty(this.selectedTenantId)) {
            this.toastService.warning('Validation error', 'Please select a tenant');
            return;
        }

        const selectedTenant: Tenant = this.applicationService.getTenants().find((tenant: Tenant) => tenant.tenantId.toString() === this.selectedTenantId);

        if (!_.isNil(selectedTenant)) {
            this.applicationService.setTenant(selectedTenant);
            this.router.navigateByUrl('/');
            this.toastService.success('Success', `Switched to tenant ${selectedTenant.name}`);
            this.dialogComponentRef.closeDialog();
        } else this.toastService.danger('Error', 'Selected tenant could not be found');
    }

    close(): void {
        this.dialogComponentRef.closeDialog();
    }
}
