import { Subject, BehaviorSubject, Observable, Subscription } from 'rxjs';

export class Toolkit {
    static generateId(length: number): string {
        let result = '';
        const numbers = '0123456789';
        const charactersLength = numbers.length;

        for (let i = 0; i < length; i++) result += numbers.charAt(Math.floor(Math.random() * charactersLength));

        return result;
    }

    static getGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    static valueFrom<T>(source: Subject<T> | BehaviorSubject<T> | Observable<T>): T {
        let value: T;

        const subscription: Subscription = source.subscribe((val: T) => {
            value = val;
        });

        subscription.unsubscribe();

        return value;
    }
}
