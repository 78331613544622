/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ButtonDirective } from '../../directives/button.directive';
import { DIALOG_DATA, DialogComponentRef } from '../../components/dialog/models/dialog-component-ref';
import _ from 'lodash';
import { angularImports } from '../../utilities/global-imports';
import { InputDirective } from '../../directives/input.directive';

@Component({
    standalone: true,
    templateUrl: './input-dialog.component.html',
    styleUrl: './input-dialog.component.scss',
    imports: [angularImports, ButtonDirective, InputDirective],
})
export class InputDialogComponent implements OnInit {
    protected title: string = 'Confirmation';
    protected text: string = '';
    protected confirmButtonText: string = 'Yes';
    protected cancelButtonText: string = 'Cancel';
    protected buttonType: string = 'primary';

    protected inputType: string = 'text';
    protected inputPlaceholder: string = '';
    protected inputValue: string = '';

    @ViewChild('input') private readonly inputElement: ElementRef;

    constructor(
        @Inject(DIALOG_DATA) private readonly dialogData: any,
        private readonly dialogComponentRef: DialogComponentRef<InputDialogComponent>
    ) {}

    ngOnInit(): void {
        this.dialogComponentRef.disableCloseOnClickOutside();
        if (_.isNil(this.dialogData)) {
            console.warn('Expected data to be injected, but nothing was injected.');
            return;
        }

        if (!_.isNil(this.dialogData.title)) this.title = this.dialogData.title;

        if (!_.isNil(this.dialogData.text)) this.text = this.dialogData.text;

        if (!_.isNil(this.dialogData.confirmation_button_text)) this.confirmButtonText = this.dialogData.confirmation_button_text;

        if (!_.isNil(this.dialogData.cancel_button_text)) this.cancelButtonText = this.dialogData.cancel_button_text;

        if (!_.isNil(this.dialogData.button_type)) this.buttonType = this.dialogData.button_type;

        if (!_.isNil(this.dialogData.input_type)) this.inputType = this.dialogData.input_type;

        if (!_.isNil(this.dialogData.input_value)) this.inputValue = this.dialogData.input_value;

        if (!_.isNil(this.dialogData.input_placeholder_text)) this.inputPlaceholder = this.dialogData.input_placeholder_text;

        if (this.dialogData.input_focus)
            setTimeout(() => {
                this.setInputSelection(0, this.inputValue.toString().length);
            });
    }

    protected cancel(): void {
        this.dialogComponentRef.closeDialog({
            is_submitted: false,
            input_value: this.inputType === 'number' ? Number(this.inputValue) : this.inputType,
        });
    }

    protected confirm(): void {
        this.dialogComponentRef.closeDialog({
            is_submitted: true,
            input_value: this.inputType === 'number' ? Number(this.inputValue) : this.inputType,
        });
    }

    protected checkInput(event: KeyboardEvent): boolean {
        //The setSelectionRange() method is only available on input elements with type 'text' so we check number input here
        if (this.inputType === 'number') {
            const key: string = event.key;
            // Check if the key is a number
            const isNumber = !isNaN(Number(key));
            return isNumber && key !== null && key.trim() !== '';
        }
        return true;
    }

    private setInputSelection(start: number, end: number) {
        //First set focus on the element, before we can set the selection range in a timeout
        this.inputElement.nativeElement.focus();
        setTimeout(() => {
            this.inputElement.nativeElement.setSelectionRange(start, end);
        });
    }
}
