/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsService {
    private readonly appInsights: ApplicationInsights;

    constructor() {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.appInsights.instrumentationKey,
                enableAutoRouteTracking: true,
            },
        });
        this.appInsights.loadAppInsights();
    }

    setAuthenticatedUser(username: string, accountId: string): void {
        this.appInsights.setAuthenticatedUserContext(username, accountId, true);
    }

    logPageView(name?: string, url?: string): void {
        this.appInsights.trackPageView({
            name: name,
            uri: url,
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }): void {
        this.appInsights.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    logException(exception: Error, severityLevel?: number): void {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

    logTrace(message: string, properties?: { [key: string]: any }): void {
        this.appInsights.trackTrace({ message: message }, properties);
    }
}
