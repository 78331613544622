<div class="ax-list-view__container" (scroll)="onScrolled($event)" [scrollTop]="scrollTop">
    @if ((data && data.length > 0) && loaderDelay < 25) {
        @for (entity of data; track entity; let dataIndex = $index) {
            <div class="ax-list-view__item" [ngClass]="getRowClass(entity)">
                @if (shouldShowPrimaryContainer(dataIndex)) {
                    <div class="ax-list-view__item__primary">
                        @if (hasMultiSelectEnabled) {
                            <div class="ax-list-view__item__checkbox">
                                <ax-checkbox [size]="'small'" [isChecked]="isSelectedRow(dataIndex)" [stopPropagationOnChange]="false" (click)="selectRow(dataIndex, $event)"></ax-checkbox>
                            </div>
                        }
                        <h4 class="ax-list-view__item__primary__label" (click)="onRowClick(dataIndex, $event)">{{getPrimaryLabel(dataIndex)}}</h4>
                    </div>
                }
                <div #dataContainer class="ax-list-view__item__data__container" [style]="setLayoutStyle(dataContainer)" (click)="onRowClick(dataIndex, $event)">
                    @for (definition of filterDefinitions(); track definition.property_name) {
                        <div class="ax-list-view__item__data" [ngClass]="getDataLayoutTypeClass()">
                            <h5 class="ax-list-view__item__data__label">{{definition.caption}}</h5>
                            @switch (definition.type) {
                                @case(definitionType.Default) {
                                    <small class="ax-list-view__item__data__value">{{getPropertyValue(entity, definition.property_name)}}</small>
                                }

                                @case(definitionType.DateTime) {
                                    <small class="ax-list-view__item__data__value">{{getPropertyValue(entity, definition.property_name) | datetime}}</small>
                                }

                                @case(definitionType.Date) {
                                    <small class="ax-list-view__item__data__value">{{getPropertyValue(entity, definition.property_name) | date}}</small>
                                }

                                @case(definitionType.Time) {
                                    <small class="ax-list-view__item__data__value">{{getPropertyValue(entity, definition.property_name) | time}}</small>
                                }

                                @case(definitionType.TimeAgo) {
                                    <small class="ax-list-view__item__data__value">{{getPropertyValue(entity, definition.property_name) | timeago}}</small>
                                }

                                @case(definitionType.Boolean) {
                                    <div class="ax-list-view__item__data__value__boolean">
                                        @if (getDefinitionOptionValueAsBoolean(definition, definitionOptionKey.DisplayBooleanAsIcon)) {
                                            <i class="ax-list-view__item__data__value__boolean__icon fa-solid" [ngClass]="setValueBooleanIconClasses(getPropertyValue(entity, definition.property_name))"></i>
                                        }

                                        @if (!getDefinitionOptionValueAsBoolean(definition, definitionOptionKey.DisplayBooleanAsIcon) && getPropertyValue(entity, definition.property_name)) {
                                            <small class="ax-list-view__item__data__value">{{getDefinitionOptionValueAsString(definition, definitionOptionKey.BooleanDisplayTrueValue)}}</small>
                                        }

                                        @if (!getDefinitionOptionValueAsBoolean(definition, definitionOptionKey.DisplayBooleanAsIcon) && !getPropertyValue(entity, definition.property_name)) {
                                            <small class="ax-list-view__item__data__value">{{getDefinitionOptionValueAsString(definition, definitionOptionKey.BooleanDisplayFalseValue)}}</small>
                                        }
                                    </div>
                                }

                                @default {
                                    <small class="ax-list-view__item__data__value">{{getPropertyValue(entity, definition.property_name)}}</small>
                                }
                            }
                        </div>
                    }
                </div>
            </div>
        }
    }
    @if (isLoading && loaderDelay > 25) {
        <div class="ax-list-view__loader">
            <ax-loader class="ax-list-view__loader__image" [width]="164" [height]="160" [loaderText]="loaderText"></ax-loader>
        </div>
    }
    @if (!isLoading && !data.length) {
        <div class="ax-list-view__no-results__container">
            <p class="ax-list-view__no-results__container__text" [innerHTML]="noDataText"></p>
        </div>
    }
</div>
