import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, Provider, inject } from '@angular/core';
import { TitleStrategy, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { PageTitleStrategy } from '../middleware/page-title-strategy';
import _ from 'lodash';
import { TOAST_CONFIG, ToastGlobalConfig } from '../components/toast/interfaces/toast-config';
import { PassedInitialConfig, provideAuth } from 'angular-auth-oidc-client';
import { HttpErrorResponse, HttpHeaders, HttpInterceptorFn, provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import { catchError, throwError } from 'rxjs';
import { ErrorHandlerService } from '../services/error-handler.service';
import { CONTEXT_MENU_CONFIG, ContextMenuGlobalConfig } from '../menus/interfaces/context-menu-config';
import { LocalSettings } from '../models/local-settings';
import { SettingsHelper } from '../utilities/settings-helper';
import { ApplicationService } from '../services/application.service';
import { Toolkit } from '../utilities/toolkit';

// Global configurations
export const toastConfig: ToastGlobalConfig = {
    max_opened: 2,
    prevent_duplicates: true,
    disable_timeout: false,
    delay: 5000,
    has_close_button: true,
    click_to_dispose: true,
    position: 'bottom-right',
};

export const contextMenuConfig: ContextMenuGlobalConfig = {
    allow_multiple_open: false,
    root_container_class: 'app__container',
    position: 'right',
    elementSpacing: 0,
    menuWidth: 200,
};

export const authConfig: PassedInitialConfig = {
    config: {
        authority: environment.auth_baseurl_server,
        redirectUrl: window.location.origin + '/',
        postLogoutRedirectUri: window.location.origin + '/',
        clientId: '4aefc72d28a24b558ff38869d203489e',
        scope: 'openid profile offline_access absapi multitenant-service logistics-service',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
    },
};

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
    const authService: AuthService = inject(AuthService);
    const applicationService: ApplicationService = inject(ApplicationService);

    let headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${authService.getAccessToken()}`,
        licenseTypeId: environment.licenseTypeId,
    });

    // Only add these headers if we have actual values to send to the API
    if (!_.isNil(applicationService.getUserKey()) && applicationService.getUserKey() > 0 && !_.isNil(applicationService.getTenant())) {
        headers = headers.append('x-tenant-id', applicationService.getTenant().tenantId.toString());
        headers = headers.append('x-user-id', applicationService.getUserKey().toString());
    }

    headers = headers.append('x-requestid', Toolkit.getGuid());

    req.headers.keys().forEach((headerKey: string) => {
        headers = headers.set(headerKey, req.headers.get(headerKey) ?? '');
    });

    req = req.clone({ url: req.url, headers, params: req.params });

    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            return throwError(() => error);
        })
    );
};

// Providers
export const toastConfigProvider: Provider = {
    provide: TOAST_CONFIG,
    useValue: toastConfig,
};

export const contextMenuConfigProvider: Provider = {
    provide: CONTEXT_MENU_CONFIG,
    useValue: contextMenuConfig,
};

export const titleStrategyProvider: Provider = {
    provide: TitleStrategy,
    useClass: PageTitleStrategy,
};

export const appInitializerProvider: Provider = {
    provide: APP_INITIALIZER,
    useFactory: setApplicationThemeAndSettings,
    multi: true,
};

export const appInsightsProvider: Provider = {
    provide: ErrorHandler,
    useClass: ErrorHandlerService,
};

// Main application config, this config is loaded by the bootstrapper
export const appConfig: ApplicationConfig = {
    providers: [provideRouter(routes), provideAuth(authConfig), provideHttpClient(withInterceptors([httpInterceptor])), titleStrategyProvider, appInitializerProvider, toastConfigProvider, contextMenuConfigProvider, appInsightsProvider],
};

export function setApplicationThemeAndSettings(): () => Promise<void> {
    return (): Promise<void> => {
        return new Promise((resolve) => {
            const settings: LocalSettings = SettingsHelper.loadSettings();

            if (settings.theme === null) {
                if (window.matchMedia('(prefers-color-scheme: dark)').matches) settings.theme = 'dark';
                else settings.theme = '';

                SettingsHelper.updateSettings(settings);
            }

            if (!_.isNil(settings.theme) && !_.isEmpty(settings.theme) && settings.theme === 'dark') document.body.classList.add('theme-dark');

            resolve();
        });
    };
}
