<div class="delivery-note__container">
    @if (data.length === 0) {
        <div class="delivery-note__no-data__container">
            <i class="fa-light fa-note delivery-note__icon"></i>
            <span>Scan or search for a delivery note or transport carrier</span>
        </div>
    } @else {
        <div class="delivery-note__type__container">
            <h3>{{entityLabel}}</h3>
        </div>
        <div class="delivery-note__data__container">
            @for (row of data; track row.key) {
                <div class="delivery-note__data-row">
                    <h4>{{row.key}}</h4>
                    <span>{{row.value}}</span>
                </div>
            }
        </div>
    }
</div>