/* eslint-disable curly */
import { Injectable } from '@angular/core';
import { AbsApiService } from './abs-api.service';
import { AbsSettings } from '../models/abs-settings';
import _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { ApplicationService } from './application.service';

@Injectable({
    providedIn: 'root',
})
export class AccessService {
    private settings: AbsSettings;

    constructor(
        private readonly applicationService: ApplicationService,
        private readonly absApiService: AbsApiService
    ) {}

    async hasAccess(key: string): Promise<boolean> {
        if (_.isNil(key) || _.isEmpty(key)) {
            throw new Error('Key is required');
        }

        await firstValueFrom(this.applicationService.initialized);
        this.settings = await this.absApiService.getSettings();

        switch (key) {
            case 'receive':
                return this.absApiService.getBooleanSetting(this.settings, 'WMAShowMenuReceive');

            case 'putaway-overview':
                return this.hasAccessToAnyPutawayPages();

            case 'putaway-on-location':
                return this.absApiService.getBooleanSetting(this.settings, 'WMAShowMenuPutAwayOnLocation');

            case 'putaway-transfer':
                return this.hasAccessToPutawayTransfer();

            case 'putaway-on-container':
                return this.hasAccessToPutawayOnContainer();

            case 'stocktake':
                return this.hasAccessToStocktake();

            case 'distribution-overview':
                return this.hasAccessToDistributionPages();

            case 'shipment-check':
                return this.hasAccessToDistributionShipmentCheck();

            case 'tc-registration':
                return this.hasAccessToDistributionTcRegistration();

            case 'load-registration':
                return this.hasAccessToDistributionLoadRegistration();

            case 'stock-distribute':
                return this.hasAccessToDistributionStockDistribute();

            case 'delivery-note':
                return this.hasAccessToDistributionDeliveryNote();

            case 'measuring':
                return this.absApiService.getBooleanSetting(this.settings, 'WMAShowMenuMeasuring');

            case 'sales-overview':
                return this.hasAccessToSalesPages();

            case 'sales-registration':
                return this.hasAccessToSalesRegistration();

            default:
                return false;
        }
    }

    private hasAccessToAnyPutawayPages(): boolean {
        if (this.absApiService.getBooleanSetting(this.settings, 'WMAShowMenuPutAwayOnLocation') || this.hasAccessToPutawayTransfer() || this.hasAccessToPutawayOnContainer()) {
            return true;
        }

        return false;
    }

    private hasAccessToPutawayTransfer(): boolean {
        const wmaPutawayTransfer: boolean = this.absApiService.getBooleanSetting(this.settings, 'WMAShowMenuPutAwayTransfer');
        const useUniqueLogistcalUnits: boolean = this.absApiService.getBooleanSetting(this.settings, 'UseUniqueLogisticalUnits');

        if (wmaPutawayTransfer === true && useUniqueLogistcalUnits === true) {
            return true;
        }

        return false;
    }

    private hasAccessToPutawayOnContainer(): boolean {
        const wmaPutawayOnContainer: boolean = this.absApiService.getBooleanSetting(this.settings, 'WMAShowMenuPutAwayOnContainer');
        const useUniqueLogistcalUnits: boolean = this.absApiService.getBooleanSetting(this.settings, 'UseUniqueLogisticalUnits');

        if (wmaPutawayOnContainer === true && useUniqueLogistcalUnits === true) {
            return true;
        }

        return false;
    }

    private hasAccessToStocktake(): boolean {
        const wmaShowMenuStocktake: boolean = this.absApiService.getBooleanSetting(this.settings, 'WMAShowMenuStocktake');
        const useUniqueLogistalUnits: boolean = this.absApiService.getBooleanSetting(this.settings, 'UseUniqueLogisticalUnits');
        const stockTakeAlgorithm: number | undefined = this.absApiService.getNumberSetting(this.settings, 'StockTakeAlgorithm');

        if (wmaShowMenuStocktake === true && (useUniqueLogistalUnits === true || stockTakeAlgorithm === 1)) {
            return true;
        }

        return false;
    }

    private hasAccessToDistributionPages(): boolean {
        if (
            this.hasAccessToDistributionShipmentCheck() ||
            this.hasAccessToDistributionTcRegistration() ||
            this.hasAccessToDistributionLoadRegistration() ||
            this.hasAccessToDistributionStockDistribute() ||
            this.hasAccessToDistributionDeliveryNote()
        ) {
            return true;
        }

        return false;
    }

    private hasAccessToDistributionShipmentCheck(): boolean {
        return this.absApiService.getBooleanSetting(this.settings, 'WMAShowShipmentRegistrationOverviewPage');
    }

    private hasAccessToDistributionTcRegistration(): boolean {
        return this.absApiService.getBooleanSetting(this.settings, 'WMAShowTCRegistrationOverviewPage');
    }

    private hasAccessToDistributionLoadRegistration(): boolean {
        return this.absApiService.getBooleanSetting(this.settings, 'WMAShowLoadRegistrationOverviewPage');
    }

    private hasAccessToDistributionStockDistribute(): boolean {
        return this.absApiService.getBooleanSetting(this.settings, 'WMAShowStockDistributeOverviewPage');
    }

    private hasAccessToDistributionDeliveryNote(): boolean {
        return this.absApiService.getBooleanSetting(this.settings, 'WMAShowDeliveryNoteDetailPage');
    }

    private hasAccessToSalesPages(): boolean {
        const salesRegistrationAccess: boolean = this.hasAccessToSalesRegistration();
        const salesOrderReturnAccess: boolean = this.hasAccessToSalesOrderReturn();
        const useUniqueLogistcalUnits: boolean = this.absApiService.getBooleanSetting(this.settings, 'UseUniqueLogisticalUnits');

        if (salesRegistrationAccess === true || (salesOrderReturnAccess === true && useUniqueLogistcalUnits === true)) {
            return true;
        } else {
            return false;
        }
    }

    private hasAccessToSalesRegistration(): boolean {
        return this.absApiService.getBooleanSetting(this.settings, 'WMAShowMenuAllocatePartyToOrder');
    }

    private hasAccessToSalesOrderReturn(): boolean {
        return this.absApiService.getBooleanSetting(this.settings, 'WMASalesOrderReturnPage');
    }
}
