import { Component, Input } from '@angular/core';
import { angularImports } from '../../../utilities/global-imports';

@Component({
    standalone: true,
    selector: 'ax-tab',
    templateUrl: './tab.component.html',
    styleUrl: './tab.component.scss',
    imports: [angularImports],
})
export class TabComponent {
    @Input() public title: string = '';
    @Input() public iconClass: string = '';
    @Input() public isVisible: boolean = false;
}
