/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { environment } from '../../../environments/environment';
import { AvatarComponent } from '../../components/avatar/avatar.component';
import { DialogService } from '../../components/dialog/dialog.service';
import { TenantSwitchDialogComponent } from '../../dialogs/tenant-switch-dialog/tenant-switch-dialog.component';
import { AuthService } from '../../services/auth.service';
import { BaseContextMenuComponent } from '../base-context-menu/base-context-menu.component';
import { CONTEXT_MENU_DATA } from '../interfaces/context-menu-config';

@Component({
    standalone: true,
    selector: 'ax-user-context-menu',
    templateUrl: './user-context-menu.component.html',
    styleUrl: './user-context-menu.component.scss',
    imports: [AvatarComponent],
})
export class UserContextMenuComponent {
    protected userName: string = '';
    protected showTenantSwitchOption: boolean = false;
    protected showEnvironmentSwitchOption: boolean = false;

    constructor(
        @Inject(BaseContextMenuComponent) private readonly baseContextMenu: BaseContextMenuComponent,
        @Inject(CONTEXT_MENU_DATA) private readonly menuData: any,
        private readonly authService: AuthService,
        private readonly dialogService: DialogService,
        private readonly router: Router
    ) {
        this.userName = this.authService.getUserData().preferred_username;
        this.showTenantSwitchOption = menuData.show_tenant_switch_option;

        // @ts-expect-error: method is injected with webapp
        if (!environment.production && menuData.show_environment_switch_option) if (!_.isNil(window.native)) if (_.isFunction(window.native.openSwitchBranchDialog)) this.showEnvironmentSwitchOption = true;
    }

    protected goToSettings(): void {
        this.baseContextMenu.dispose();
        this.router.navigateByUrl('/settings');
    }

    protected logout(): void {
        this.authService.logOff();
    }

    protected showTenantSwitchDialog(): void {
        this.baseContextMenu.dispose();
        this.dialogService.createDialog<TenantSwitchDialogComponent>(TenantSwitchDialogComponent);
    }

    protected sendEnvironmentSwitchEvent(): void {
        this.baseContextMenu.dispose();

        // @ts-expect-error: method is injected with webapp
        window.native.openSwitchBranchDialog();
    }
}
