/* eslint-disable curly */
import { Component, OnInit } from '@angular/core';
import { ButtonDirective } from '../../directives/button.directive';
import { angularImports } from '../../utilities/global-imports';
import { AccessService } from '../../services/access.service';

@Component({
    standalone: true,
    selector: 'ax-home-page',
    templateUrl: './home-page.component.html',
    styleUrl: './home-page.component.scss',
    imports: [angularImports, ButtonDirective],
})
export class HomePageComponent implements OnInit {
    protected isLoading: boolean = false;
    protected showReceive: boolean = false;
    protected showStockTake: boolean = false;
    protected showMeasuring: boolean = false;
    protected showSales: boolean = false;
    protected showPutaway: boolean = false;
    protected showDistribution: boolean = false;

    constructor(private readonly accessService: AccessService) {}

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.showReceive = await this.accessService.hasAccess('receive');
        this.showStockTake = await this.accessService.hasAccess('stocktake');
        this.showMeasuring = await this.accessService.hasAccess('measuring');
        this.showSales = await this.accessService.hasAccess('sales-overview');
        this.showPutaway = await this.accessService.hasAccess('putaway-overview');
        this.showDistribution = await this.accessService.hasAccess('distribution-overview');
        this.isLoading = false;
    }
}
