import { Directive, ElementRef, Input, OnInit, Optional } from '@angular/core';
import { ValidationRule } from '../rules/validation-rule';
import { MaxLengthValidationRule } from '../rules/max-length-validation-rule';
import { MinLengthValidationRule } from '../rules/min-length-validation-rule';
import { RequiredValidationRule } from '../rules/required-validation-rule';
import { ValidationContext } from '../validation-context';
import _ from 'lodash';

@Directive({
    selector: '[ax-validate]',
    standalone: true,
})
export class ValidateDirective implements OnInit {
    @Input('ax-validate') public validationKey: string = '';
    @Input('ax-validate-display-property') public displayPropertyName: string | undefined | null;
    @Input('ax-validation-rules') public rules: string[] = [];
    @Input('ax-validation-custom-rules') public customValidationRules: ValidationRule[] = [];
    @Input('prioritize-custom-rules') public prioritizeCustomRules: boolean = false;
    @Input('ax-validate-group') public validationGroup: string = undefined;

    public readonly validationRules: ValidationRule[] = [];

    private nodeName: string | undefined | null;

    constructor(
        private readonly elementRef: ElementRef,
        @Optional() private readonly validationContext: ValidationContext
    ) {}

    ngOnInit(): void {
        if (_.isNil(this.validationKey) || _.isEmpty(this.validationKey)) throw new Error('Validation key is required');

        this.nodeName = this.elementRef.nativeElement.nodeName.toLowerCase();

        if (_.isNil(this.displayPropertyName) || _.isEmpty(this.displayPropertyName)) this.displayPropertyName = this.validationKey;

        this.buildValidationRules();
    }

    /**
     * Gets the value from an element, this can be native html values in case of input elements or custom elements that are extended from {@link ValidationContext}.
     * @returns The value of the element this directive is decorated on
     */
    getValue<T>(): T {
        switch (this.nodeName) {
            case 'input':
                return this.elementRef.nativeElement.value;

            default:
                if (!_.isNil(this.validationContext)) return this.validationContext.getValue();
                else throw new Error(`Node ${this.nodeName} is not valid for validation`);
        }
    }

    /**
     * Sets the directive in valid or invalid state.
     * @param isValid Flag indicating if this directive is in valid state or not.
     */
    setValidState(isValid: boolean): void {
        switch (this.nodeName) {
            case 'input':
                if (!isValid) this.elementRef.nativeElement.classList.add('ax-input--invalid');
                else this.elementRef.nativeElement.classList.remove('ax-input--invalid');

                break;

            default:
                if (!_.isNil(this.validationContext))
                    if (!isValid) this.validationContext.setIsInvalidState();
                    else this.validationContext.setIsValidState();
                else throw new Error(`Node ${this.nodeName} is not valid for validation`);

                break;
        }
    }

    /**
     * Checks if the node that the validate directive is decorated on is disabled or not.
     * @returns A boolean indicating if the element is disabled.
     */
    isElementDisabled(): boolean {
        switch (this.nodeName) {
            case 'input':
                return this.elementRef.nativeElement.disabled;

            default:
                if (!_.isNil(this.validationContext)) return this.validationContext.isDisabled;
                else throw new Error(`Node ${this.nodeName} is not valid for validation`);
        }
    }

    /**
     * Updates the min-length, max-length and required validation rules since these values can be set later in the lifecycle then when this function is triggered.
     * Adds the rules when no previous rules where found
     */
    updateInputElementBindingRules(): void {
        if (this.nodeName === 'input' && this.elementRef.nativeElement.type === 'text') {
            if (this.elementRef.nativeElement.maxLength > -1) {
                const maxLengthRule: MaxLengthValidationRule | undefined = this.validationRules.find((x) => x.key === 'max-length') as MaxLengthValidationRule | undefined;

                if (!_.isNil(maxLengthRule)) maxLengthRule.updateMaxLength(this.elementRef.nativeElement.maxLength);
                else this.validationRules.push(new MaxLengthValidationRule(this.elementRef.nativeElement.maxLength));
            }

            if (this.elementRef.nativeElement.minLength > -1) {
                const minLengthRule: MinLengthValidationRule | undefined = this.validationRules.find((x) => x.key === 'min-length') as MinLengthValidationRule | undefined;

                if (!_.isNil(minLengthRule)) minLengthRule.updateMinLength(this.elementRef.nativeElement.minLength);
                else this.validationRules.push(new MinLengthValidationRule(this.elementRef.nativeElement.minLength));
            }

            if (this.elementRef.nativeElement.required) {
                const requiredValidationRule: ValidationRule = this.validationRules.find((x) => x.key === 'required');

                if (_.isNil(requiredValidationRule)) this.validationRules.unshift(new RequiredValidationRule());
            }
        }
    }

    /**
     * Builds the validation rules in order of the {@link this.rules} array, when a {@link RequiredValidationRule} is passed it is always unshifted to the first element in the array.
     */
    private buildValidationRules(): void {
        this.rules.forEach((rule: string) => {
            switch (rule) {
                case 'required':
                    this.validationRules.unshift(new RequiredValidationRule());
                    break;
                default:
                    throw new Error(`${rule} is not a valid validation rule to pass`);
            }
        });

        if (this.nodeName === 'input') {
            if (this.elementRef.nativeElement.type === 'text') {
                if (this.elementRef.nativeElement.maxLength > -1) this.validationRules.push(new MaxLengthValidationRule(this.elementRef.nativeElement.maxLength));

                if (this.elementRef.nativeElement.minLength > -1) this.validationRules.push(new MinLengthValidationRule(this.elementRef.nativeElement.minLength));
            }

            if (this.elementRef.nativeElement.required) {
                const requiredValidationRule: ValidationRule = this.validationRules.find((x) => x.key === 'required');

                if (_.isNil(requiredValidationRule)) this.validationRules.unshift(new RequiredValidationRule());
            }
        }

        this.customValidationRules.forEach((customRule: ValidationRule) => {
            // We check all custom validation rules that are passed and push them to the array to be validated.
            // If prioritize custom rules is set to true, we will check for existing validation rules based on key and replace them with the custom rule.
            // Else we skip the custom rule and use the default rule for that key instead
            const existingRule: ValidationRule = this.validationRules.find((rule: ValidationRule) => rule.key === customRule.key);

            if (!_.isNil(existingRule) && this.prioritizeCustomRules) {
                const index: number = this.validationRules.indexOf(existingRule);

                if (index > -1) this.validationRules.splice(index, 1);
                else throw new Error(`Unable to remove default rule ${customRule.key}`);

                if (customRule.key.toLowerCase() === 'required') this.validationRules.unshift(customRule);
            } else if (!_.isNil(existingRule) && !this.prioritizeCustomRules) console.warn(`Skipping custom rule ${customRule.key} since there is already a default rule with this key and prioritize custom rules is disabled`);
            else this.validationRules.push(customRule);
        });
    }
}
