import { AfterViewInit, Component, ContentChildren, QueryList } from '@angular/core';
import { angularImports } from '../../utilities/global-imports';
import { TabComponent } from './content/tab.component';

@Component({
    standalone: true,
    selector: 'ax-tab-container',
    templateUrl: './tab-container.component.html',
    styleUrl: './tab-container.component.scss',
    imports: [angularImports],
})
export class TabContainerComponent implements AfterViewInit {
    @ContentChildren(TabComponent) protected readonly tabComponents: QueryList<TabComponent>;

    ngAfterViewInit(): void {
        const visibleTabs: TabComponent[] = this.tabComponents.filter((tab: TabComponent) => tab.isVisible === true);

        if (visibleTabs.length > 1) this.selectTab(null, this.tabComponents.first);
        else if (visibleTabs.length === 0) this.selectTab(null, this.tabComponents.first);
    }

    selectTab(event: Event, tabComponent: TabComponent): void {
        if (event !== null) event.stopPropagation();

        this.tabComponents.forEach((tab: TabComponent) => {
            tab.isVisible = false;
        });

        tabComponent.isVisible = true;
    }
}
