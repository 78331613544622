<div class="ax-user-context-menu__container">
    <div class="ax-context-menu__item ax-context-menu__item--user">
        <ax-avatar [name]="userName"></ax-avatar>
        <span>{{userName}}</span>
    </div>
    @if (showTenantSwitchOption) {
        <div class="ax-context-menu__item" (click)="showTenantSwitchDialog()">
            <i class="fa-light fa-shuffle ax-context-menu__item__icon"></i>
            <span>Switch tenant</span>
        </div>
    }
    @if (showEnvironmentSwitchOption) {
        <div class="ax-context-menu__item" (click)="sendEnvironmentSwitchEvent()">
            <i class="fa-light fa-shuffle ax-context-menu__item__icon"></i>
            <span>Switch environment</span>
        </div>
    }
    <div class="ax-context-menu__item" (click)="goToSettings()">
        <i class="fa-light fa-gears ax-context-menu__item__icon"></i>
        <span>Settings</span>
    </div>
    <div class="ax-context-menu__item" (click)="logout()">
        <i class="fa-light fa-right-from-bracket ax-context-menu__item__icon"></i>
        <span>Logout</span>
    </div>
</div>