import { AfterViewInit, Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TOAST_DATA, ToastData } from './interfaces/toast-data';
import _ from 'lodash';
import { angularImports } from '../../utilities/global-imports';
import { ButtonDirective } from '../../directives/button.directive';

@Component({
    standalone: true,
    selector: 'ax-toast',
    templateUrl: './toast.component.html',
    styleUrl: './toast.component.scss',
    imports: [angularImports, ButtonDirective],
})
export class ToastComponent implements AfterViewInit {
    public readonly id: string;
    public readonly title: string;
    public readonly message: string;
    public readonly type: string;
    public readonly hasCloseButton: boolean;
    public readonly onDispose: Observable<null>;

    private _onDisplose: Subject<null> = new Subject<null>();
    private disposeOnClick: boolean;

    @HostListener('click')
    clickToDispose(): void {
        if (this.disposeOnClick) this.disposeToast();
    }

    @ViewChild('toastContainer') private readonly toastContainer: ElementRef;

    constructor(@Inject(TOAST_DATA) private readonly data: ToastData) {
        this.id = data.id;
        this.title = data.title;
        this.message = data.message;
        this.type = data.type;
        this.hasCloseButton = data.has_close_button;
        this.onDispose = this._onDisplose.asObservable();
        this.disposeOnClick = data.click_to_dispose;
    }

    ngAfterViewInit(): void {
        this.toastContainer.nativeElement.classList.add('ax-toast__container--opening');

        _.delay(() => {
            this.toastContainer.nativeElement.classList.remove('ax-toast__container--opening');
        }, 200);
    }

    handleDestory(): void {
        this.toastContainer.nativeElement.classList.add('ax-toast__container--closing');

        _.delay(() => {
            this.toastContainer.nativeElement.classList.remove('ax-toast__container--closing');
        }, 300);
    }

    setClasses(): string {
        let classList: string = '';

        if (!_.isEmpty(this.type)) classList = `ax-toast__container--${this.type}`;

        return classList;
    }

    disposeToast(): void {
        this._onDisplose.next(null);
    }
}
