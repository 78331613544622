<div class="settings__container">
    <div class="setting__section__header">
        <h4>Appearance Settings</h4>
    </div>
    <div class="setting__option__container">
        <div class="setting__option__container__option">
            <h4 class="setting__option__container__label">Theme</h4>
            <ax-select [options]="themeOptions" [(value)]="selectedTheme" (valueChange)="switchTheme($event)" [placeholder]="'Select a theme'" [hasClearEnabled]="false"></ax-select>
        </div>
    </div>
    <div class="setting__option__container">
        <div class="setting__option__container__option">
            <h4 class="setting__option__container__label">Show barcode bar</h4>
            <ax-toggle-button [(isChecked)]="settings.show_barcode_bar" (isCheckedChange)="updateSettings()"></ax-toggle-button>
        </div>
        <div class="setting__option__container__text">
            <small>Show the barcode input at the top of all screens</small>
        </div>
    </div>
    <!-- <div class="setting__option__container">
        <div class="setting__option__container__option">
            <h4 class="setting__option__container__label">Show breadcrumb bar</h4>
            <ax-toggle-button [(isChecked)]="settings.show_breadcrumb_bar" (isCheckedChange)="updateSettings()"></ax-toggle-button>
        </div>
        <div class="setting__option__container__text">
            <small>Show the breadcrumb bar at the top of all screens</small>
        </div>
    </div> -->
    <div class="setting__section__header" style="margin-top: 1rem;">
        <h4>General Settings</h4>
    </div>
    <div class="setting__option__container">
        <div class="setting__option__container__option">
            <h4 class="setting__option__container__label">Enable sound</h4>
            <ax-toggle-button [(isChecked)]="settings.enable_sound" (isCheckedChange)="updateSettings()"></ax-toggle-button>
        </div>
        <div class="setting__option__container__text">
            <small>Enables or disbales sound being played on scan actions</small>
        </div>
    </div>
</div>
