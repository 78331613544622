/* eslint-disable curly */
import { Injectable } from '@angular/core';
import { ApplicationService } from './application.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { PartyStockLevel } from '../models/party-stock-level';
import { RegisterTransportCarrier } from '../models/register-transport-carrier.model';
import { PartyStocktakeCommand } from '../models/party-stocktake-command-model';
import { Shipment } from '../models/shipment.model';
import { ShipmentPropertyRequest } from '../models/shipment-property-request.model';
import { ShipmentScanTransaction } from '../models/shipment-scan-transaction.model';
import { PagedEnumerable } from '../models/paged-enumerable';
import { PartyStorageLocation } from '../models/party-storage-location';
import { TransportCarrierDeliveryNote } from '../models/transport-carrier-delivery-note';
import { StorageLocation } from '../models/storage-location';
import { LabelScanActionContext } from '../models/label-scan-action-context';
import { TransportCarrierScanTransaction } from '../models/transport-carrier-scan-transaction';
import { TransportCarrier } from '../models/transport-carrier.model';
import { PackingScanProperties } from '../models/packing-scan-properties';
import { Label } from '../models/label.model';
import { DistributePartyStockCommand } from '../models/distribute-party-stock-commands';
import _ from 'lodash';
import { PartyDTO } from '../models/party-dto.model';
import { createPartyStorageLocationCommand } from '../models/create-party-storage-location-command.model';
import { TransportVehicleShipmentInformation } from '../models/transport-vehicle-shipment-information';
import { AddToTransportCarrierValidationResult } from '../models/add-to-transport-carrier-validation-result';
import { Cask } from '../models/cask';

@Injectable({
    providedIn: 'root',
})
export class LogisticsService {
    constructor(
        private readonly httpClient: HttpClient,
        private applicationService: ApplicationService
    ) {}

    async getBaseUrl(): Promise<string> {
        await firstValueFrom(this.applicationService.initialized);
        const serviceUrl: string = this.applicationService.getLogisticsServiceUrl();
        return serviceUrl.endsWith('/') ? serviceUrl : `${serviceUrl}/`;
    }

    async registerTransportCarrierAsync(task: RegisterTransportCarrier): Promise<void> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.post<void>(`${baseUrl}transportcarriers`, task));
    }

    async getPartyStockLevel(partyKey: number): Promise<PartyStockLevel> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<PartyStockLevel>(`${baseUrl}parties/${partyKey}/stock-level`));
    }

    async stocktakeAsync(partyStocktakeCommand: PartyStocktakeCommand): Promise<void> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.post<void>(`${baseUrl}parties/${partyStocktakeCommand.partyKey}/stocktake`, JSON.stringify(partyStocktakeCommand)));
    }

    async getShipmentsAsync(): Promise<Shipment[]> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<Shipment[]>(`${baseUrl}shipments`));
    }

    async getShipmentPropertiesAsync(task: ShipmentPropertyRequest): Promise<Shipment> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.post<Shipment>(`${baseUrl}shipments/properties`, task));
    }

    async startShipmentScanTransactionAsync(task: object): Promise<ShipmentScanTransaction> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.post<ShipmentScanTransaction>(`${baseUrl}shipmentscantransactions/start`, task));
    }

    async stopShipmentScanTransactionAsync(shipmentScanTransactionKey: number): Promise<void> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.post<void>(`${baseUrl}shipmentscantransactions/${shipmentScanTransactionKey}/stop`, JSON.stringify(shipmentScanTransactionKey)));
    }

    async getShipmentScanTransactionAsync(shipmentScanTransactionKey: number): Promise<ShipmentScanTransaction> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<ShipmentScanTransaction>(`${baseUrl}shipmentscantransactions/${shipmentScanTransactionKey}`));
    }

    async getShipmentByLabelIdAsync(labelId: number, groupLabel: boolean): Promise<Shipment | null> {
        const baseUrl: string = await this.getBaseUrl();

        try {
            return await firstValueFrom(this.httpClient.get<Shipment>(`${baseUrl}shipments/labels/${labelId}/${groupLabel}/available`));
        } catch (err) {
            const error: HttpErrorResponse = err as HttpErrorResponse;
            if (error.status === 404) {
                return null;
            }
            return null;
        }
    }

    async getShipmentScanTransactionLabelScanActionContextAsync(shipmentKey: number, labelId: number, scanActionKey: number): Promise<LabelScanActionContext> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<LabelScanActionContext>(`${baseUrl}shipmentscantransactions/${shipmentKey}/labels/${labelId}/scanaction/${scanActionKey}`));
    }

    async scanLabelOnShipmentScanTransaction(shipmentScanTransactionKey: number, labelId: number, groupLabel: boolean = false): Promise<number> {
        const baseUrl: string = await this.getBaseUrl();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const command: any = {
            ShipmentScanTransactionKey: shipmentScanTransactionKey,
            LabelId: labelId,
            GroupLabel: groupLabel,
        };

        return firstValueFrom(this.httpClient.post<number>(`${baseUrl}shipmentscantransactions/${shipmentScanTransactionKey}/labels/${labelId}/scan`, command));
    }

    async getStorageLocations(location: string): Promise<PagedEnumerable<StorageLocation>> {
        let path: string = await this.getBaseUrl();

        if (_.isEmpty(location)) {
            path = `${path}storage-locations?$orderby=storageLocationId asc`;
        } else {
            path = `${path}storage-locations?$orderby=storageLocationId asc&$filter=Description.Contains("${location}") OR Zone.Code.Contains("${location}") OR Warehouse.Code.Contains("${location}") OR Warehouse.Site.Code.Contains("${location}")`;
        }

        return firstValueFrom(this.httpClient.get<PagedEnumerable<StorageLocation>>(path));
    }

    async linkPartyToStorageLocation(partyKey: number, storageLocationId: number): Promise<number> {
        const baseUrl: string = await this.getBaseUrl();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const command: any = {
            storageLocationId: storageLocationId,
            partyKey: partyKey,
        };

        return firstValueFrom(this.httpClient.post<number>(`${baseUrl}storage-locations/${storageLocationId}`, JSON.stringify(command)));
    }

    async getPartyStorageLocations(partyStorageLocationKey: number): Promise<PartyStorageLocation[]> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<PartyStorageLocation[]>(`${baseUrl}party-storage-locations?$filter=Key==${partyStorageLocationKey}`));
    }

    async clearPartyStorageLocation(storageLocationId: number): Promise<number> {
        const baseUrl: string = await this.getBaseUrl();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const command: any = {
            storageLocationId: storageLocationId,
        };

        return firstValueFrom(this.httpClient.post<number>(`${baseUrl}party-storage-locations/${storageLocationId}/clear`, JSON.stringify(command)));
    }

    async getDeliveryNotesByDeliveryNoteCode(deliveryNoteCode: string): Promise<TransportCarrierDeliveryNote> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<TransportCarrierDeliveryNote>(`${baseUrl}transportcarrierdeliverynotes?$filter=DeliveryNoteCode=="${deliveryNoteCode}"`));
    }

    async getDeliveryNotesByTransportCarrierId(transportCarrierId: number): Promise<TransportCarrierDeliveryNote[]> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<TransportCarrierDeliveryNote[]>(`${baseUrl}transportcarriers/deliverynotes?$filter=TransportCarrierId eq "${transportCarrierId}"`));
    }

    async stopTransportCarrierScanTransaction(transportCarrierScanTransactionKey: number): Promise<void> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.post<void>(`${baseUrl}transportcarrierscantransactions/${transportCarrierScanTransactionKey}/stop`, {}));
    }

    async startTransportCarrierScanTransaction(transportCarrierId: number): Promise<TransportCarrierScanTransaction> {
        const baseUrl: string = await this.getBaseUrl();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const command: any = {
            transportCarrierId: transportCarrierId,
        };

        return firstValueFrom(this.httpClient.post<TransportCarrierScanTransaction>(`${baseUrl}transportcarrierscantransactions/start`, JSON.stringify(command)));
    }

    async getTransportCarrierScanTransaction(scanTransactionKey: number): Promise<TransportCarrierScanTransaction> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<TransportCarrierScanTransaction>(`${baseUrl}transportcarrierscantransactions/${scanTransactionKey}`));
    }

    async getTransportCarriersWithShipmentKey(shipmentKey: number): Promise<PagedEnumerable<TransportCarrier>> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<PagedEnumerable<TransportCarrier>>(`${baseUrl}transportcarriers?$filter=shipmentKey eq ${shipmentKey} and Fixed eq False`));
    }

    async updateTransportcarrierWeight(transportCarrierKey: number, netWeight: number, grossWeight: number): Promise<number> {
        const baseUrl: string = await this.getBaseUrl();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const command: any = {
            transportCarrierKey: transportCarrierKey,
            netActualWeight: netWeight,
            grossActualWeight: grossWeight,
        };

        return firstValueFrom(this.httpClient.put<number>(`${baseUrl}transportcarriers/${transportCarrierKey}/weight`, JSON.stringify(command)));
    }

    async getPackingScanProperties(scanTransactionKey: number, packingKey: number): Promise<PackingScanProperties> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<PackingScanProperties>(`${baseUrl}transportcarrierscantransactions/${scanTransactionKey}/packings/${packingKey}/scanproperties`));
    }

    async scanPacking(scanTransactionKey: number, packingKey: number, units: number): Promise<boolean> {
        const baseUrl: string = await this.getBaseUrl();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const command: any = {
            transportCarrierScanTransactionkey: scanTransactionKey,
            packingKey: packingKey,
            units: units,
        };

        await firstValueFrom(this.httpClient.post<boolean>(`${baseUrl}transportcarrierscantransactions/${scanTransactionKey}/packings/${packingKey}/scan`, JSON.stringify(command)));
        return true;
    }

    async getLabelByLabelId(labelId: number, groupLabel: boolean): Promise<Label> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<Label>(`${baseUrl}labels/${labelId}/${groupLabel}`));
    }

    async printTransportcarrierLabel(transportcarrierKey: number): Promise<number> {
        const baseUrl: string = await this.getBaseUrl();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const command: any = {
            transportCarrierKey: transportcarrierKey,
        };

        return firstValueFrom(this.httpClient.post<number>(`${baseUrl}transportcarriers/${transportcarrierKey}/print-label`, JSON.stringify(command)));
    }

    async addLabelScanAction(transportCarrierScanTransactionKey: number, labelId: number, isGroupLabel: boolean): Promise<number> {
        const baseUrl: string = await this.getBaseUrl();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const command: any = {
            transportCarrierScanTransactionKey: transportCarrierScanTransactionKey,
            labelId: labelId,
            groupLabel: isGroupLabel,
        };

        return firstValueFrom(this.httpClient.post<number>(`${baseUrl}transportcarrierscantransactions/${transportCarrierScanTransactionKey}/labels/${labelId}/scanaction`, JSON.stringify(command)));
    }

    async getLabelScanActionContext(transportCarrierScanTransactionKey: number, labelId: number, scanKey: number): Promise<LabelScanActionContext> {
        const baseUrl: string = await this.getBaseUrl();

        return firstValueFrom(this.httpClient.get<LabelScanActionContext>(`${baseUrl}transportcarrierscantransactions/${transportCarrierScanTransactionKey}/labels/${labelId}/scanaction/${scanKey}`));
    }

    async scanLabel(transportCarrierScanTransactionKey: number, labelId: number, scanActionKey: number, isGroupLabel: boolean): Promise<number> {
        const baseUrl: string = await this.getBaseUrl();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const command: any = {
            transportCarrierScanTransactionKey: transportCarrierScanTransactionKey,
            labelId: labelId,
            scanActionKey: scanActionKey,
            groupLabel: isGroupLabel,
        };

        return firstValueFrom(this.httpClient.post<number>(`${baseUrl}transportcarrierscantransactions/${transportCarrierScanTransactionKey}/labels/${labelId}/scan`, command));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async distributePartyStock(partyKey: number, packingUnits: number): Promise<any> {
        const baseUrl: string = await this.getBaseUrl();

        const distributePartyStockCommand: DistributePartyStockCommand = {
            partyKey,
            packingUnits,
        };
        return firstValueFrom(this.httpClient.post(`${baseUrl}parties/${partyKey}/distribute-stock`, JSON.stringify(distributePartyStockCommand)));
    }

    async getPartyStorageLocationByStorageLocationId(storageLocationId: number): Promise<PartyStorageLocation[]> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<PartyStorageLocation[]>(`${baseUrl}party-storage-locations?$filter=StorageLocationID==${storageLocationId}`));
    }

    async getPartyByInternalNumber(internalNumber: number): Promise<PartyDTO> {
        const baseUrl: string = await this.getBaseUrl();
        // eslint-disable-next-line no-useless-escape
        return firstValueFrom(this.httpClient.get<PartyDTO>(`${baseUrl}parties?$filter=InternalNumber==\"${internalNumber}\"`));
    }

    async createPartyStorageLocationTransactionAsync(partyKey: number, targetStorageLocationID: number, sourcePartyStorageLocationKey?: number, packingUnits?: number): Promise<PartyDTO> {
        const baseUrl: string = await this.getBaseUrl();
        const command: createPartyStorageLocationCommand = {
            partyKey,
            sourcePartyStorageLocationKey,
            targetStorageLocationID,
            packingUnits: packingUnits !== null && packingUnits !== undefined ? packingUnits : undefined,
        };
        return firstValueFrom(this.httpClient.post<PartyDTO>(`${baseUrl}party-storage-locations`, command));
    }

    async getTransportVehicleShipmentAsync(): Promise<TransportVehicleShipmentInformation[]> {
        const baseUrl: string = await this.getBaseUrl();
        return firstValueFrom(this.httpClient.get<TransportVehicleShipmentInformation[]>(`${baseUrl}transportvehicleshipments`, {}));
    }

    async addToTransportCarrierAsync(userKey: number, transportVehicleShipmentKey: number, transportCarrierId: number): Promise<AddToTransportCarrierValidationResult> {
        const baseUrl: string = await this.getBaseUrl();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const command: any = {
            userKey,
            transportCarrierId,
            transportVehicleShipmentKey,
        };

        return firstValueFrom(this.httpClient.post<AddToTransportCarrierValidationResult>(`${baseUrl}transportvehicleshipments/${transportVehicleShipmentKey}/transportcarrier/${transportCarrierId}`, command));
    }

    async getAvailableShipmentByLabelId(labelId: number, isGroupLabel: boolean): Promise<Shipment> {
        const baseUrl: string = await this.getBaseUrl();

        return firstValueFrom(this.httpClient.get<Shipment>(`${baseUrl}shipments/labels/${labelId}/${isGroupLabel}/available`));
    }

    async createTransportCarrier(caskKey: number, shipmentKey: number): Promise<number> {
        const baseUrl: string = await this.getBaseUrl();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const command: any = {
            caskKey,
            shipmentKey,
        };

        return firstValueFrom(this.httpClient.post<number>(`${baseUrl}transportcarriers/create`, JSON.stringify(command)));
    }

    async getTransportCarrier(transportCarrierKey: number): Promise<TransportCarrier> {
        const baseUrl: string = await this.getBaseUrl();

        return firstValueFrom(this.httpClient.get<TransportCarrier>(`${baseUrl}transportcarriers/${transportCarrierKey}`));
    }

    async getCasksByShipmentKey(shipmentKey: number): Promise<Cask[]> {
        const baseUrl: string = await this.getBaseUrl();

        return firstValueFrom(this.httpClient.get<Cask[]>(`${baseUrl}casks/shipment/${shipmentKey}?$filter=TransportCarrier eq 1&$orderby=Description`));
    }

    async getCask(caskKey: number): Promise<Cask> {
        const baseUrl: string = await this.getBaseUrl();

        return firstValueFrom(this.httpClient.get<Cask>(`${baseUrl}casks/${caskKey}`));
    }
}
