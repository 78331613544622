import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { InputDirective } from '../../directives/input.directive';
import { angularImports } from '../../utilities/global-imports';
import { ScanService } from '../../services/scan.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ax-barcode',
    standalone: true,
    templateUrl: './barcode.component.html',
    styleUrl: './barcode.component.scss',
    imports: [angularImports, InputDirective],
})
export class BarcodeComponent implements OnInit, OnDestroy {
    @Input() public barcode: string = '';
    @Input() public placeholder: string = 'Type a barcode';
    @Input() public isStandalone: boolean = true;
    @Output() public barcodeChange: EventEmitter<string> = new EventEmitter<string>();

    private scanSubscription: Subscription;

    constructor(private readonly scanService: ScanService) {}

    ngOnInit(): void {
        this.scanSubscription = this.scanService.onScan.subscribe((barcode) => {
            this.updateBarcode(barcode);
        });
    }

    ngOnDestroy(): void {
        this.scanSubscription.unsubscribe();
    }

    protected updateBarcode(barcode: string): void {
        this.barcode = barcode;
        this.barcodeChange.emit(this.barcode);
    }

    protected onEnter(): void {
        this.scanService.onBarcodeScan(this.barcode);
    }
}
