<div class="putaway-overview-page__container">
    @if (!isLoading) {
        @if (showOnLocation) {
            <button ax-button="primary" class="putaway-overview-page__container__button" [routerLink]="'/putaway/on-location'">
                <img src="/assets/icons/icon_warehouse_order_picking.png" alt="warehouse_order_picking"/>
                On Location
            </button>
        }

        @if (showTransfer) {
            <button ax-button="primary" class="putaway-overview-page__container__button" [routerLink]="'/putaway/transfer'">
                <img src="/assets/icons/icon_putaway_transfer.png" alt="putaway_transfer"/>
                Transfer
            </button>
        }

        @if (showOnContainer) {
            <button ax-button="primary" class="putaway-overview-page__container__button" [routerLink]="'/putaway/on-container'">
                <img src="/assets/icons/icon_putaway_on_container.png" alt="putaway_on_container"/>
                On Container
            </button>   
        }
    }
</div>
