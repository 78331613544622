import { Injectable } from '@angular/core';

/**
 * Class to be extended by components for validation.
 */
@Injectable({
    providedIn: 'root',
})
export abstract class ValidationContext {
    abstract isDisabled: boolean;
    abstract getValue<T>(): T;
    abstract setIsInvalidState(): void;
    abstract setIsValidState(): void;
}
