import { ValidationRule } from './validation-rule';
import * as _ from 'lodash';

export class MaxLengthValidationRule implements ValidationRule {
    public readonly key: string = 'max-length';
    public readonly errorMessage: string = '';
    private maxLength: number;

    constructor(maxLength: number) {
        this.maxLength = maxLength;
        this.errorMessage = `cannot be more than ${maxLength} characters`;
    }

    validateRule<T>(value: T): boolean {
        const val: string = value as string;

        if (_.isNil(val) || _.isEmpty(val)) return true;

        if (val.length > this.maxLength) return false;

        return true;
    }

    updateMaxLength(maxLength: number): void {
        this.maxLength = maxLength;
    }
}
