/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogComponent } from '../dialog.component';

@Injectable({
    providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class DialogComponentRef<T> {
    constructor(private readonly dialog: DialogComponent) {}

    public readonly afterClose: Observable<any> = this.dialog.afterClose;

    disableCloseOnClickOutside(): void {
        this.dialog.setCloseOnClickOutside(false);
    }

    closeDialog(data?: any | null): void {
        this.dialog.closeDialog(data);
    }
}

export const DIALOG_DATA: InjectionToken<any> = new InjectionToken<any>('DialogData');
