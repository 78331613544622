import { Pipe, PipeTransform } from '@angular/core';
import { Converters } from '../utilities/converters';
import _ from 'lodash';

@Pipe({ name: 'date', standalone: true })
export class DatePipe implements PipeTransform {
    transform(value: string, format: string = 'DD-MM-YYYY') {
        if (_.isNil(value) || _.isEmpty(value)) return '';

        return Converters.toDate(value, format);
    }
}
