export class PartyIdentifierOptions {
    useLogisticID: boolean;
    includeReceivedLots: boolean;
    restrictToPurchaseLots: boolean;
    useHamifleurBarcode: boolean;
    useVBAFloraBarcode: boolean;
    useKoperBarcode: boolean;
    useZandbergenBarcode: boolean;
    useHoekhuisBarcode: boolean;
    useLogisticalUnitsBarcode: boolean;
    searchInDescription: boolean;
    searchInExternalNumber: boolean;
    searchInInternalNumber: boolean;
    searchInSupplierCode: boolean;
    includePartyTypePurchase: boolean;
    includePartyTypeStock: boolean;
    includePartyTypeProduction: boolean;
    searchInBasePartyInternalNumber: boolean;
    searchInCashAndCarryID: boolean;
    pdaScanInternalNumberandEditAVEandAVE: boolean;
    searchInCashAndCarryIDSources: boolean;
    excludeAssortmentLots: boolean;
    searchInPartySalesPriceLevelBarcode: boolean;

    constructor() {
        this.useLogisticID = true;
        this.restrictToPurchaseLots = true;
        this.useHamifleurBarcode = true;
        this.useVBAFloraBarcode = true;
        this.useKoperBarcode = true;
        this.useZandbergenBarcode = true;
        this.useHoekhuisBarcode = true;
        this.useLogisticalUnitsBarcode = true;
        this.searchInDescription = true;
        this.searchInExternalNumber = true;
        this.searchInInternalNumber = true;
        this.searchInSupplierCode = true;
        this.includePartyTypePurchase = true;
        this.includePartyTypeStock = true;
        this.includePartyTypeProduction = true;
        this.searchInBasePartyInternalNumber = true;
        this.pdaScanInternalNumberandEditAVEandAVE = true;
        this.searchInCashAndCarryIDSources = true;
    }
}
