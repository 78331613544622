import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import numeral from 'numeral';

export class Converters {
    static toTimeAgo(value: string): string {
        dayjs.extend(relativeTime);

        return dayjs(value).fromNow();
    }

    static toDateTime(value: string, format: string = 'DD-MM-YYYY HH:mm'): string {
        dayjs.extend(relativeTime);

        return dayjs(value).format(format);
    }

    static toDate(value: string, format: string = 'DD-MM-YYYY'): string {
        dayjs.extend(relativeTime);

        return dayjs(value).format(format);
    }

    static toTime(value: string, useNumeral: boolean = false): string {
        if (!useNumeral) {
            dayjs.extend(relativeTime);

            return dayjs(value).format('HH:mm:ss');
        } else return numeral(value).format('00:00:00');
    }

    static toDateObject(value: string, format: string = 'DD-MM-YYYY'): Date {
        dayjs.extend(customParseFormat);

        return dayjs(value, format).toDate();
    }
}
