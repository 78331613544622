<div class="lot-details-dialog__container">
    <div class="lot-details-dialog__header">
        <h3>{{description}}</h3>
        <button ax-button [icon-only]="true" (click)="close()">
            <i class="fa-light fa-xmark"></i>
        </button>
    </div>
    <div class="lot-details-dialog__content">
        <div class="lot-details-dialog__content__block">
            <span>Logistic ID:</span>
            <span>{{logisticID}}</span>
        </div>
        <div class="lot-details-dialog__content__block">
            <span>Internal number:</span>
            <span>{{internalNumber}}</span>
        </div>
        <div class="lot-details-dialog__content__block">
            <span>Total units:</span>
            <span>{{totalUnits}}</span>
        </div>
        <div class="lot-details-dialog__content__input">
            <span class="lot-details-dialog__content__input__label">Packing units:</span>
            <input
                type="number"
                ax-input
                ax-validate="packing_units"
                required
                [(ngModel)]="packingUnits"
                (keyup.enter)="updatePackingUnit()">
        </div>
        <div class="lot-details-dialog__content__block">
            <span>Product units:</span>
            <span>{{productUnits}}</span>
        </div>
        <div class="lot-details-dialog__content__block">
            <span>MOA:</span>
            <span>{{moa}}</span>
        </div>
        <div class="lot-details-dialog__content__block">
            <span>Packing:</span>
            <span>{{packing}}</span>
        </div>
    </div>
    <div class="lot-details-dialog__footer">
        <button ax-button (click)="close()">Cancel</button>
        <button ax-button="primary" (click)="updatePackingUnit()">Ok</button>
    </div>
</div>
