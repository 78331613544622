/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, ElementRef, EnvironmentInjector, HostListener, Injector, StaticProvider, Type, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import _ from 'lodash';
import { CONTEXT_MENU_DATA } from '../interfaces/context-menu-config';
import { ContextMenuDirective } from '../../directives/context-menu.directive';

@Component({
    standalone: true,
    selector: 'ax-base-context-menu',
    templateUrl: './base-context-menu.component.html',
    styleUrl: './base-context-menu.component.scss',
    imports: [ContextMenuDirective],
})
export class BaseContextMenuComponent implements AfterViewInit {
    public readonly onDispose: Observable<any>;

    private _onDisplose: Subject<void> = new Subject<void>();
    private clickedWithinElement: boolean = false;

    @ViewChild('menuContainer') private readonly menuContainer: ElementRef;
    @ViewChild(ContextMenuDirective) private readonly contextMenuDirective: ContextMenuDirective;

    constructor(private readonly environmentInjector: EnvironmentInjector) {
        this.onDispose = this._onDisplose.asObservable();
    }

    @HostListener('document:click')
    clickOutside(): void {
        if (!this.clickedWithinElement) this.dispose();

        this.clickedWithinElement = false;
    }

    ngAfterViewInit(): void {
        this.menuContainer.nativeElement.addEventListener('click', () => {
            this.clickedWithinElement = true;
        });

        this.menuContainer.nativeElement.classList.add('ax-base-context-menu__container--opening');

        _.delay(() => {
            this.menuContainer.nativeElement.classList.remove('ax-base-context-menu__container--opening');
        }, 200);
    }

    createMenuContent<T>(element: Type<T>, data?: any): void {
        this.contextMenuDirective.viewRef.createComponent<T>(element, {
            environmentInjector: this.environmentInjector,
            injector: this.createInjector(data),
        });
    }

    handleDestroy(): void {
        this.menuContainer.nativeElement.classList.add('ax-base-context-menu__container--closing');

        _.delay(() => {
            this.menuContainer.nativeElement.classList.remove('ax-base-context-menu__container--closing');
        }, 350);
    }

    dispose(): void {
        this._onDisplose.next();
    }

    private createInjector(data: any): Injector {
        const providers: StaticProvider[] = [
            { provide: CONTEXT_MENU_DATA, useValue: data },
            { provide: BaseContextMenuComponent, useValue: this },
        ];

        return Injector.create({ providers: providers });
    }
}
