/* eslint-disable curly */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { commonViewImports } from '../../../utilities/global-imports';
import { Subscription } from 'rxjs';
import { ScanService } from '../../../services/scan.service';
import { PageTemplateComponent } from '../../../templates/page-template/page-template.component';
import _ from 'lodash';
import { TimeAgoPipe } from '../../../pipes/timeago.pipe';
import { LogRow } from './interfaces/log-row';
import { EntityIdentification, EntityType } from '../../../utilities/entity-identification';
import { ToastService } from '../../../components/toast/toast.service';
import { TransportCarrierScanTransaction } from '../../../models/transport-carrier-scan-transaction';
import { LogisticsService } from '../../../services/logistics.service';
import { TransportCarrier } from '../../../models/transport-carrier.model';
import { PagedEnumerable } from '../../../models/paged-enumerable';
import { Label } from '../../../models/label.model';
import { LabelViewModel } from './interfaces/label-view-model';
import { Shipment } from '../../../models/shipment.model';
import { ShipmentBoxRow } from '../../../models/shipment-box-row.model';
import { ShipmentScanTransaction } from '../../../models/shipment-scan-transaction.model';
import { DialogService } from '../../../components/dialog/dialog.service';
import { ShipmentDetailsDialogComponent } from '../../../dialogs/shipment-details-dialog/shipment-details-dialog.component';
import { TcWeightDialogComponent } from '../../../dialogs/tc-weight-dialog/tc-weight-dialog.component';
import { DialogComponentRef } from '../../../components/dialog/models/dialog-component-ref';
import { PackingScanProperties } from '../../../models/packing-scan-properties';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AbsSettings } from '../../../models/abs-settings';
import { AbsApiService } from '../../../services/abs-api.service';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { LabelScanActionContext } from '../../../models/label-scan-action-context';
import { InputDialogComponent } from '../../../dialogs/input-dialog/input-dialog.component';

@Component({
    standalone: true,
    selector: 'ax-distribution-tc-registration-page',
    templateUrl: './tc-registration-page.component.html',
    styleUrl: './tc-registration-page.component.scss',
    imports: [commonViewImports, TimeAgoPipe],
})
export class TCRegistrationPageComponent implements OnInit, AfterViewInit, OnDestroy {
    private scanSubscription: Subscription;
    private settings: AbsSettings;
    private shouldPrint: boolean = false;
    private createdTransportCarrier: boolean = false;
    private labelId: number | null = null;
    private entityType: EntityType | undefined = undefined;
    private _labels: LabelViewModel[] = [];

    protected isLoading: boolean = false;
    protected transportCarrierScanTransaction: TransportCarrierScanTransaction | null = null;
    protected transportCarriers: TransportCarrier[] = [];
    protected shipment: Shipment | null = null;
    protected labels: LabelViewModel[] = [];
    protected scanLogs: LogRow[] = [];
    protected scannedBarcode: string = '';
    protected amountScanned: number = 0;
    protected totalAmountToScan: number = 0;
    protected showAllLabels: boolean = false;

    constructor(
        private readonly scanService: ScanService,
        private readonly pageTemplate: PageTemplateComponent,
        private readonly toasterService: ToastService,
        private readonly logisticsService: LogisticsService,
        private readonly absApiService: AbsApiService,
        private readonly dialogService: DialogService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute
    ) {}

    async ngOnInit(): Promise<void> {
        this.settings = await this.absApiService.getSettings();

        this.activatedRoute.params.subscribe(async (params: Params) => {
            const isNewTcPage = this.activatedRoute.snapshot.queryParams['IsNewTC'] === 'true';
            const barcode: string = params['barcode'];
            this.labelId = params['labelId'] ? Number(params['labelId']) : null;
            this.entityType = params['entityType'] ? (parseInt(params['entityType']) as EntityType) : undefined;

            if (isNewTcPage) {
                if (!_.isNil(barcode) && !_.isEmpty(barcode) && !_.isNil(this.labelId) && !_.isNaN(this.labelId)) {
                    this.createdTransportCarrier = true;

                    const entity: EntityIdentification | Error = EntityIdentification.validateBarcode(barcode);

                    if (entity instanceof Error) {
                        this.toasterService.danger('Error', 'Invalid entity type');
                    } else {
                        this.isLoading = true;
                        await this.handleTransportCarrierScanned(entity);
                        this.scannedBarcode = barcode;
                        this.isLoading = false;
                    }
                }
            } else {
                if (!_.isNil(barcode) && !_.isEmpty(barcode) && !_.isNil(this.labelId) && !_.isNaN(this.labelId)) {
                    const entity: EntityIdentification | Error = EntityIdentification.validateBarcode(barcode);

                    if (entity instanceof Error) {
                        this.toasterService.danger('Error', 'Invalid entity type');
                    } else {
                        this.isLoading = true;
                        await this.handleTransportCarrierScanned(entity);
                        this.scannedBarcode = barcode;
                        this.isLoading = false;
                    }
                }
            }
        });

        this.scanSubscription = this.scanService.onScan.subscribe(async (barcode: string) => {
            if (barcode.length === 14 || barcode.length === 12 || barcode.length === 8) {
                try {
                    const entity: EntityIdentification | Error = EntityIdentification.validateBarcode(barcode);

                    if (entity instanceof Error) {
                        this.toasterService.danger('Invalid barcode', barcode);
                        return;
                    } else {
                        this.scanLogs.push({
                            timestamp: new Date().toString(),
                            barcode: barcode,
                        });

                        if (this.transportCarrierScanTransaction === null) {
                            this.isLoading = true;
                        }

                        switch (entity.entityType) {
                            case EntityType.TransportCarrier:
                                await this.handleTransportCarrierScanned(entity);
                                this.scannedBarcode = barcode;
                                break;

                            case EntityType.Packing:
                                await this.handlePackingScanned(entity);
                                break;

                            case EntityType.TransportCarrierLayer:
                            case EntityType.PackingLabel:
                                await this.handleLabelScanned(entity.entityKey, entity.entityType);
                                break;

                            default:
                                if (barcode.length === 8) {
                                    await this.handleLabelScanned(entity.entityKey, entity.entityType);
                                }
                                break;
                        }

                        this.pageTemplate.setBarcode('');
                        this.isLoading = false;
                    }
                } catch {
                    this.toasterService.danger('Error', 'Unable to start TC registration');
                    this.scanService.errorSound();
                    this.scanLogs = [];
                }
            }
        });
    }

    ngAfterViewInit(): void {
        // Add a tiny delay to prevent race condition on change detection
        _.delay(() => {
            this.pageTemplate.setBarcodePlaceholder('Search a transport carrier');
        }, 10);
    }

    ngOnDestroy(): void {
        this.scanSubscription.unsubscribe();
        this.pageTemplate.setBarcode('');
    }

    setShipmentBoxRowStatus(boxRow: ShipmentBoxRow): string {
        switch (boxRow.rowStatus) {
            case 1:
                return 'box-row--status-1';

            case 2:
                return 'box-row--status-2';

            default:
                return '';
        }
    }

    clearView(event: Event): void {
        event.stopPropagation();
        this.transportCarrierScanTransaction = null;
        this.transportCarriers = [];
        this.shipment = null;
        this.labels = [];
        this.scannedBarcode = '';
        this.scanLogs = [];
        this.amountScanned = 0;
        this.totalAmountToScan = 0;
    }

    openTcWeightDialog(event: Event): void {
        event.stopPropagation();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tcWeightData: any = {
            net_weight: this.transportCarrierScanTransaction.transportCarrier.netActualWeight,
            gross_weight: this.transportCarrierScanTransaction.transportCarrier.grossActualWeight,
        };

        const dialogComponentRef: DialogComponentRef<TcWeightDialogComponent> = this.dialogService.createDialog(TcWeightDialogComponent, tcWeightData);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dialogComponentRef.afterClose.subscribe(async (responseData: any) => {
            if (responseData.is_submitted) {
                await this.logisticsService.updateTransportcarrierWeight(this.transportCarrierScanTransaction.transportCarrier.transportCarrierKey, responseData.net_weight, responseData.gross_weight);
                this.transportCarrierScanTransaction.transportCarrier.netActualWeight = responseData.net_weight;
                this.transportCarrierScanTransaction.transportCarrier.grossActualWeight = responseData.gross_weight;

                this.toasterService.success('Update successfull', 'Weight updated sucessfully');
            }
        });
    }

    toggleScannedLabels(doShow: boolean): void {
        this.showAllLabels = doShow;

        if (doShow) {
            this.labels = this._labels;
        } else {
            this.labels = this._labels.filter((label: LabelViewModel) => label.is_scanned === false);
        }
    }

    async transportCarrierClick(event: Event, transportCarrier: TransportCarrier): Promise<void> {
        event.stopPropagation();

        this.isLoading = true;

        await this.stopTransaction();
        await this.startTransaction(parseInt(transportCarrier.transportCarrierId));

        if (this.transportCarrierScanTransaction.transportCarrier.shipment !== null) {
            const shipment: Shipment = await this.logisticsService.getShipmentPropertiesAsync({
                transportlineKey: this.transportCarrierScanTransaction.transportCarrier.shipment.transportLine.transportLineKey,
                shipmentKey: this.transportCarrierScanTransaction.transportCarrier.shipment.shipmentKey,
                shipDate: this.transportCarrierScanTransaction.transportCarrier.shipment.shipDate,
            });

            this.shipment = shipment;
        }

        this.scannedBarcode = this.constructBarcode(transportCarrier.transportCarrierId);

        this.isLoading = false;
    }

    async openShipmentDetailsDialog(event: Event): Promise<void> {
        event.stopPropagation();

        const shipmentScanTransaction: ShipmentScanTransaction = await this.logisticsService.getShipmentScanTransactionAsync(this.transportCarrierScanTransaction.transportCarrierScanTransactionKey);
        this.dialogService.createDialog(ShipmentDetailsDialogComponent, shipmentScanTransaction);
    }

    private async handleTransportCarrierScanned(entityIdentification: EntityIdentification): Promise<void> {
        if (this.transportCarrierScanTransaction !== null) {
            try {
                await this.stopTransaction();
                await this.startTransaction(entityIdentification.entityKey);
            } catch {
                this.toasterService.danger('Transaction error', 'Unable to start / stop transaction');
            }
        } else {
            await this.startTransaction(entityIdentification.entityKey);
        }

        if (this.transportCarrierScanTransaction.transportCarrier.shipment === null) {
            return;
        } else {
            const shipment: Shipment = await this.logisticsService.getShipmentPropertiesAsync({
                transportlineKey: this.transportCarrierScanTransaction.transportCarrier.shipment.transportLine.transportLineKey,
                shipmentKey: this.transportCarrierScanTransaction.transportCarrier.shipment.shipmentKey,
                shipDate: this.transportCarrierScanTransaction.transportCarrier.shipment.shipDate,
            });

            this.shipment = shipment;
        }
    }

    private async handlePackingScanned(entityIdentification: EntityIdentification): Promise<void> {
        if (!_.isNil(this.transportCarrierScanTransaction)) {
            if (this.createdTransportCarrier) {
                const cask = await this.logisticsService.getCask(entityIdentification.entityKey);
                if (cask == null) {
                    this.toasterService.danger('Error', 'Cask could not be found');
                } else {
                    await this.scanPacking(cask.scanDefaultUnits, entityIdentification.entityKey);
                }
            } else {
                try {
                    const packingScanProperties: PackingScanProperties = await this.logisticsService.getPackingScanProperties(this.transportCarrierScanTransaction.transportCarrierScanTransactionKey, entityIdentification.entityKey);

                    if (!packingScanProperties.allowScan) {
                        this.toasterService.danger('Error', 'Scan is not allowed');
                        return;
                    }

                    if (packingScanProperties.allowUnitsEntry) {
                        const dialogComponentRef: DialogComponentRef<InputDialogComponent> = this.dialogService.createDialog(InputDialogComponent, {
                            title: 'Confirm action',
                            text: `Enter the number of logistical resource units to register?`,
                            cancel_button_text: 'Cancel',
                            confirmation_button_text: 'Register',
                            input_type: 'number',
                            input_value: packingScanProperties.defaultUnits,
                            input_placeholder_text: 'Units',
                            input_focus: true,
                        });

                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        dialogComponentRef.afterClose.subscribe(async (responseData: any) => {
                            if (responseData.is_submitted) {
                                await this.scanPacking(responseData.input_value, entityIdentification.entityKey);
                            }
                        });
                    } else {
                        await this.scanPacking(packingScanProperties.defaultUnits, entityIdentification.entityKey);
                    }
                } catch {
                    this.toasterService.danger('Error', 'Unable to get packing properties');
                    this.scanService.errorSound();
                    return;
                }
            }
        } else {
            this.toasterService.danger('Error', 'Cannot scan a packing without a context');
            this.scanService.errorSound();
        }
    }

    private async handleLabelScanned(entityKey: number, entityType: EntityType | null): Promise<void> {
        try {
            let isGroupLabel: boolean = false;
            if (entityType !== null && entityType === EntityType.TransportCarrierLayer) {
                isGroupLabel = true;
            }

            const isCartLabel: boolean = await this.isCartLabel(entityKey, isGroupLabel);

            if (this.shouldScanLabel(isCartLabel)) {
                await this.handleLabel(entityKey, isGroupLabel);
            } else {
                const shipment: Shipment = await this.logisticsService.getShipmentByLabelIdAsync(entityKey, isGroupLabel);

                if (this.transportCarrierScanTransaction === null) {
                    if (isCartLabel) {
                        this.router.navigateByUrl(`distribution/tc-cask-selection/${shipment.shipmentKey}/${entityKey}/${entityType}`); // Transportcarrier cask selection page
                    } else {
                        this.navigateToCreateTCPage(shipment, entityKey, entityType);
                    }
                } else {
                    if (!this.settings.WMAContainerRegistrationDisablePrintLabel) {
                        if (this.shouldPrint && this.transportCarrierScanTransaction !== null) {
                            if (this.settings.WMAContainerRegistrationConfirmPrintLabel) {
                                const dialogComponentRef: DialogComponentRef<ConfirmationDialogComponent> = this.dialogService.createDialog(ConfirmationDialogComponent, {
                                    title: 'Print label',
                                    text: 'This transportcarrier has no label, do you want to print this label?',
                                    cancelButtonText: 'No',
                                });

                                dialogComponentRef.afterClose.subscribe(async (hasConfirmation: boolean) => {
                                    if (hasConfirmation) {
                                        await this.printLabel(this.transportCarrierScanTransaction.transportCarrier.transportCarrierKey);
                                    }
                                });
                            } else {
                                if (this.transportCarrierScanTransaction.transportCarrier.labelPrinted === false) {
                                    await this.printLabel(this.transportCarrierScanTransaction.transportCarrier.transportCarrierKey);
                                }
                            }
                        }
                    }

                    this.router.navigateByUrl(`distribution/tc-cask-selection/${shipment.shipmentKey}/${entityKey}/${entityType}`); // Transportcarrier cask selection page
                }
            }
        } catch {
            this.toasterService.danger('Error', 'Unable to handle label scan');
            this.scanService.errorSound();
        }
    }

    private async scanPacking(units: number, packingKey: number): Promise<void> {
        if (_.isNil(units)) {
            return;
        } else {
            const success: boolean = await this.logisticsService.scanPacking(this.transportCarrierScanTransaction.transportCarrierScanTransactionKey, packingKey, units);

            if (success) {
                this.transportCarrierScanTransaction = await this.logisticsService.getTransportCarrierScanTransaction(this.transportCarrierScanTransaction.transportCarrierScanTransactionKey);
                this.shouldPrint = true; // No clue what this does
                this.toasterService.success('Success', 'Packing scanned');
                this.scanService.successSound();
            } else {
                this.toasterService.danger('Error', 'Unable to scan packing on transportcarrier');
                this.scanService.errorSound();
            }
        }
    }

    private async stopTransaction(): Promise<void> {
        if (this.transportCarrierScanTransaction === null) {
            return;
        }

        await this.logisticsService.stopTransportCarrierScanTransaction(this.transportCarrierScanTransaction.transportCarrierScanTransactionKey);
        this.transportCarrierScanTransaction = null;
    }

    private async startTransaction(transportCarrierId: number): Promise<void> {
        this.transportCarrierScanTransaction = await this.logisticsService.startTransportCarrierScanTransaction(transportCarrierId);
        this.transportCarrierScanTransaction = await this.logisticsService.getTransportCarrierScanTransaction(this.transportCarrierScanTransaction.transportCarrierScanTransactionKey);

        if (!_.isNil(this.labelId)) {
            await this.handleLabelScanned(this.labelId, this.entityType);
            this.labelId = null;
        }

        const result: PagedEnumerable<TransportCarrier> = await this.logisticsService.getTransportCarriersWithShipmentKey(this.transportCarrierScanTransaction.transportCarrier.shipment.shipmentKey);
        this.transportCarriers = result.items.filter((carrier: TransportCarrier) => carrier.transportCarrierId !== this.transportCarrierScanTransaction.transportCarrier.transportCarrierId);
        this.initLabels();
    }

    private async isCartLabel(labelId: number, isGroupLabel: boolean): Promise<boolean> {
        const wmaContainerRegistrationCheckCartLabel: boolean = false;

        if (!wmaContainerRegistrationCheckCartLabel) {
            return false;
        }

        const label: Label = await this.logisticsService.getLabelByLabelId(labelId, isGroupLabel);

        return label.groupingType == 2; // groupingType === 2 is cartlabel
    }

    private async printLabel(transportcarrierKey: number): Promise<void> {
        await this.logisticsService.printTransportcarrierLabel(transportcarrierKey);

        this.toasterService.success('Success', 'Transportcarrier label printed');
        this.scanService.successSound();
    }

    private async handleLabel(labelId: number, isGroupLabel: boolean): Promise<void> {
        try {
            if (this.transportCarrierScanTransaction !== null) {
                const scanKey: number = await this.logisticsService.addLabelScanAction(this.transportCarrierScanTransaction.transportCarrierScanTransactionKey, labelId, isGroupLabel);

                if (scanKey <= 0) {
                    this.toasterService.danger('Error', 'Label could not be scanned on transportcarrier');
                    this.scanService.errorSound();
                } else {
                    const labelScanActionContext: LabelScanActionContext = await this.logisticsService.getLabelScanActionContext(this.transportCarrierScanTransaction.transportCarrierScanTransactionKey, labelId, scanKey);

                    if (labelScanActionContext.valid) {
                        if (labelScanActionContext.needsConfirmationOnRelocate) {
                            const dialogComponentRef: DialogComponentRef<ConfirmationDialogComponent> = this.dialogService.createDialog(ConfirmationDialogComponent, {
                                title: 'Confirm action',
                                text: `Do you want to relocate this label: ${labelId}?`,
                                cancelButtonText: 'No',
                            });

                            dialogComponentRef.afterClose.subscribe(async (hasConfirmation: boolean) => {
                                if (hasConfirmation) {
                                    await this.scanLabel(labelId, scanKey, isGroupLabel, true);
                                }
                            });
                        } else {
                            await this.scanLabel(labelId, scanKey, isGroupLabel, true);
                        }
                    } else {
                        if (labelScanActionContext.validationResults.length > 0) {
                            let errorString: string = '';

                            if (labelScanActionContext.validationResults.length > 1) {
                                errorString = `${labelScanActionContext.validationResults[0]} and ${labelScanActionContext.validationResults[1]}`;
                            } else {
                                if (labelScanActionContext.validationResults.length === 1) {
                                    errorString = labelScanActionContext.validationResults[0];
                                } else {
                                    errorString = '';
                                }
                            }

                            this.toasterService.danger('Error', errorString);
                            this.scanService.errorSound();
                        } else {
                            this.toasterService.danger('Error', 'Error during scan label, please contact your supervisor');
                            this.scanService.errorSound();
                        }
                    }
                }
            }
        } catch {
            this.toasterService.danger('Error', 'Unable to scan label, please contact your supervisor');
            this.scanService.errorSound();
        }
    }

    private async scanLabel(labelId: number, scanActionKey: number, isGroupLabel: boolean, initLabels?: boolean): Promise<void> {
        try {
            await this.logisticsService.scanLabel(this.transportCarrierScanTransaction.transportCarrierScanTransactionKey, labelId, scanActionKey, isGroupLabel);
            this.transportCarrierScanTransaction = await this.logisticsService.getTransportCarrierScanTransaction(this.transportCarrierScanTransaction.transportCarrierScanTransactionKey);
            this.shouldPrint = true;

            if (initLabels) {
                this.initLabels();
            } else {
                const originalLabel: Label | undefined = this.transportCarrierScanTransaction.transportCarrier.labels.find((x: Label) => x.labelId === labelId);

                if (!_.isEmpty(originalLabel)) {
                    const label: LabelViewModel | undefined = this._labels.find((x: LabelViewModel) => x.barcode === originalLabel.barcode);

                    if (!_.isEmpty(label)) {
                        label.is_scanned = true;
                    }
                }

                if (!this.showAllLabels) {
                    this.labels = this._labels.filter((label: LabelViewModel) => label.is_scanned === false);
                }

                this.totalAmountToScan = this._labels.length;
                this.amountScanned++;
            }
            this.toasterService.success('Success', 'Label scanned');
            this.scanService.successSound();
        } catch {
            this.toasterService.danger('Error', 'Label could not be scanned onto transportcarrier');
            this.scanService.errorSound();
        }
    }

    private shouldScanLabel(isCartLabel: boolean): boolean {
        if (this.transportCarrierScanTransaction === null) {
            return false;
        }

        if (!isCartLabel) {
            return true;
        }

        let hasPackingTransactions: boolean = false;

        if (
            !_.isNil(this.transportCarrierScanTransaction) &&
            !_.isNil(this.transportCarrierScanTransaction.transportCarrier) &&
            !_.isNil(this.transportCarrierScanTransaction.transportCarrier.packingTransactions) &&
            !_.isEmpty(this.transportCarrierScanTransaction.transportCarrier.packingTransactions)
        ) {
            hasPackingTransactions = true;
        } else {
            hasPackingTransactions = false;
        }

        return !hasPackingTransactions;
    }

    private navigateToCreateTCPage(shipment: Shipment, entityKey: number, entityType: EntityType): void {
        if (!_.isNil(shipment.transportCarriers) && !_.isEmpty(shipment.transportCarriers)) {
            this.router.navigateByUrl(`distribution/tc-selection/${entityKey}/${entityType}`); // TC selection page
        } else {
            this.router.navigateByUrl(`distribution/tc-cask-selection/${shipment.shipmentKey}/${entityKey}/${entityType}`); // Transportcarrier cask selection page
        }
    }

    private constructBarcode(transportCarrierId: string): string {
        let barcode: string = '95';

        if (transportCarrierId.length === 4) {
            barcode = `${barcode}000000${transportCarrierId}`;
        }

        return barcode;
    }

    private initLabels() {
        this.labels = [];
        this.transportCarrierScanTransaction.transportCarrier.labels.forEach((label: Label, index: number) => {
            this.labels.push({
                article: label.article,
                barcode: label.barcode,
                packing_code: label.packingCode,
                order_number: `${label.orderNumber}.${label.boxNumber}.${index + 1}`,
                is_scanned: label.measuredOnTransportCarrier === true ? true : false,
            });
        });

        // Keep the original list of labels so we can mutate the other list to show or hide the already scanned labels
        this._labels = _.cloneDeep(this.labels);
        this.labels = this._labels.filter((label: LabelViewModel) => label.is_scanned === false);

        this.totalAmountToScan = this._labels.length;
        this.amountScanned = this.transportCarrierScanTransaction.transportCarrier.labels.filter((label: Label) => label.measuredOnTransportCarrier === true).length;
    }
}
