<div class="tc-registration__overview__container">
    @if (transportCarrierScanTransaction === null) {
        <div class="tc-registration__overview__empty-state">
            <h4>Nothing scanned yet</h4>
        </div>
    } @else {
        <div class="tc-registration__action-bar">
            <button [ax-button]="'primary'" [icon-only]="true" (click)="openTcWeightDialog($event)">
                <i class="fa-solid fa-weight-hanging"></i>
            </button>
            <button [ax-button]="'primary'" [icon-only]="true" (click)="openShipmentDetailsDialog($event)">
                <i class="fa-regular fa-circle-info"></i>
            </button>
            <button [ax-button]="'primary'" [icon-only]="true" (click)="clearView($event)">
                <i class="fa-solid fa-broom-wide"></i>
            </button>
        </div>
        <ax-tab-container class="tc-registration__tab__container">
            <ax-tab [title]="'Labels'">
                @if (!isLoading) {
                    <div class="label__container">
                        <div class="label__header">
                            <ax-checkbox [size]="'small'" [isChecked]="showAllLabels" (isCheckedChange)="toggleScannedLabels($event)"></ax-checkbox>
                            <div class="label__header__info">
                                <small class="label__header__info__barcode">{{scannedBarcode}}</small>
                                <div class="label__header__info__scan__values">
                                    <small>Planned: {{totalAmountToScan}}</small>
                                    <small>Registered: {{amountScanned}}</small>
                                </div>
                            </div>
                        </div>
                        <div class="label__data__container">
                            <h4 class="label__data__logistical__units__header">Logistical Units</h4>
                            @for (label of labels; track label.barcode) {
                                <div class="label__row">
                                    <ax-checkbox [size]="'small'" [isDisabled]="true" [isChecked]="label.is_scanned"></ax-checkbox>
                                    <div class="label__row__data">
                                        <div class="label__row__data__values">
                                            <small>{{label.barcode}}</small>
                                            <small>{{label.order_number}}</small>
                                            <small>{{label.packing_code}}</small>
                                        </div>
                                        <small><b>{{label.article}}</b></small>
                                    </div>
                                </div>
                            }
                            <h4 class="label__data__logistical__resources">Logistical Resources</h4>
                            @for (packingTransaction of transportCarrierScanTransaction.transportCarrier.packingTransactions; track packingTransaction.packingTransactionId) {
                                <div class="logistical__resource__row">
                                    <small>{{packingTransaction.packingTransactionId}}</small>
                                    <small>{{packingTransaction.code}}</small>
                                    <div class="logistical__resource__row__unit__block">
                                        <small>{{packingTransaction.units}}&nbsp;</small>
                                        <small>{{packingTransaction.description}}</small>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </ax-tab>
            <ax-tab [title]="'Shipment'">
                @if (!isLoading) {
                    <div class="tc-registration__shipment__labels__container">
                        @if (shipment !== null) {
                            <div class="shipment__header">
                                <small><b>Details shipment:</b> {{shipment.manualShipmentNumber.trim()}}</small>
                            </div>
                            <div class="shipment__box-rows__container">
                                @if (shipment.boxRows !== null && shipment.boxRows !== undefined && shipment.boxRows.length > 0) {
                                    <div class="shipment__box-rows__header">
                                        <b>Product</b>
                                        <b>To Scan</b>
                                        <b>Packing</b>
                                    </div>
                                }
                                @for (boxRow of shipment.boxRows; track boxRow.shipmentBoxRowKey) {
                                    <div class="box-row" [ngClass]="setShipmentBoxRowStatus(boxRow)">
                                        <small>{{boxRow.product}}</small>
                                        <small>{{boxRow.toScan}}</small>
                                        <small>{{boxRow.caskCode}}</small>
                                    </div>
                                } @empty {
                                    <h4>No shipment box rows found</h4>
                                }
                            </div>
                        } @else {
                            <h4>No shipment</h4>
                        }
                    </div>
                }
            </ax-tab>
            <ax-tab [title]="'TC'">
                <div class="tc-registration__available__container">
                    <div class="available__header">
                        <small><b>Available transportcarriers:</b></small>
                    </div>
                    <div class="transportcarrier__container">
                        @if (transportCarriers !== null && transportCarriers.length > 0) {
                            <div class="transportcarrier__header">
                                <b>Transportcarrier</b>
                                <b>Remark</b>
                                <b>Shipment</b>
                            </div>
                        }
                        @for (transportCarrier of transportCarriers; track transportCarrier.transportCarrierId) {
                            <div class="transportcarrier" (click)="transportCarrierClick($event, transportCarrier)">
                                <small>{{transportCarrier.transportCarrierId}}</small>
                                <small>{{transportCarrier.remark}}</small>
                                <small>{{transportCarrier.shipment.manualShipmentNumber.trim()}}</small>
                            </div>
                        } @empty {
                           <div class="available__empty__state">
                                <h4>No available transportcarriers</h4>
                           </div>
                        }
                    </div>
                </div>
            </ax-tab>
            <ax-tab [title]="'Scan logs'">
                <div class="tc-registration__scan__logs__container">
                    @for (log of scanLogs; track log.barcode) {
                        <div class="scan__log__block">
                            <small class="scan__log__block__barcode">{{log.barcode}}</small>
                            <small class="scan__log__block__timestamp">{{log.timestamp | timeago}}</small>
                        </div>
                    } @empty {
                        <div class="tc-registration__scan__logs__no-data">
                            <h4>Nothing scanned in this session yet.</h4>
                        </div>
                    }
                </div>
            </ax-tab>
        </ax-tab-container>
    }
</div>