/* eslint-disable curly */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageTemplateComponent } from '../../../templates/page-template/page-template.component';
import { ScanService } from '../../../services/scan.service';
import { ToastService } from '../../../components/toast/toast.service';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { LogisticsService } from '../../../services/logistics.service';
import { StorageLocation } from '../../../models/storage-location';
import { PagedEnumerable } from '../../../models/paged-enumerable';
import { commonViewImports } from '../../../utilities/global-imports';
import { ListViewItemDefinition } from '../../../components/list-view/interfaces/list-view-item-definition';
import { ListViewDefinitionType } from '../../../components/list-view/enums/list-view-item-definition-type';
import { EntityIdentification, EntityType } from '../../../utilities/entity-identification';
import { AbsApiService } from '../../../services/abs-api.service';
import { DialogService } from '../../../components/dialog/dialog.service';
import { DialogComponentRef } from '../../../components/dialog/models/dialog-component-ref';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { PartyStorageLocation } from '../../../models/party-storage-location';
import { ListViewComponent } from '../../../components/list-view/list-view.component';

@Component({
    standalone: true,
    selector: 'ax-putaway-on-location-page',
    templateUrl: './on-location-page.component.html',
    styleUrl: './on-location-page.component.scss',
    imports: [commonViewImports],
})
export class OnLocationPageComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('storageLocationsListView') private readonly storageLocationListViewComponent: ListViewComponent;

    protected storageLocations: StorageLocation[] = [];
    protected selectedStorageLocation: StorageLocation | undefined = undefined;
    protected storageLocationListViewScrollTop: number = 0;
    protected isLoading: boolean = false;

    protected storageLocationListDefinitions: ListViewItemDefinition[] = [
        {
            caption: 'Description',
            property_name: 'description',
            type: ListViewDefinitionType.Default,
        },
        {
            caption: 'Zone',
            property_name: 'zone.code',
            type: ListViewDefinitionType.Default,
        },
        {
            caption: 'Warehouse',
            property_name: 'warehouse.code',
            type: ListViewDefinitionType.Default,
        },
        {
            caption: 'Site',
            property_name: 'warehouse.site.code',
            type: ListViewDefinitionType.Default,
        },
    ];
    protected linkedLotsListDefinitions: ListViewItemDefinition[] = [
        {
            caption: 'Description',
            property_name: 'party.description',
            type: ListViewDefinitionType.Default,
        },
        {
            caption: 'Internal number',
            property_name: 'party.internalNumber',
            type: ListViewDefinitionType.Default,
        },
    ];

    private scanSubscription: Subscription;

    constructor(
        private readonly pageTemplate: PageTemplateComponent,
        private readonly scanService: ScanService,
        private readonly toastService: ToastService,
        private readonly logisticsService: LogisticsService,
        private readonly absApiService: AbsApiService,
        private readonly dialogService: DialogService
    ) {}

    async ngOnInit(): Promise<void> {
        try {
            this.isLoading = true;
            await this.fetchStorageLocations('');
        } catch {
            this.toastService.danger('Fetch error', 'Unable to fetch storage lcoations');
        } finally {
            this.isLoading = false;
        }

        this.scanSubscription = this.scanService.onScan.subscribe(async (barcode: string) => {
            if (_.isEmpty(barcode) && _.isNil(this.selectedStorageLocation)) {
                await this.fetchStorageLocations('');
            } else {
                const entityIdentification: EntityIdentification | Error = EntityIdentification.validateBarcode(barcode);

                if (entityIdentification instanceof Error) {
                    // When on the location overview screen we do a search, else we are on the location page with linked lots and we throw an error.
                    if (_.isNil(this.selectedStorageLocation)) {
                        await this.fetchStorageLocations(barcode);
                    } else {
                        await this.linkLotToStorageLocation(barcode);
                    }
                } else {
                    switch (entityIdentification.entityType) {
                        case EntityType.StorageLocation:
                            // eslint-disable-next-line no-case-declarations
                            const storageLocation: StorageLocation = this.storageLocations.find((location: StorageLocation) => location.storageLocationId === entityIdentification.entityKey);

                            if (!_.isNil(storageLocation)) {
                                this.setStorageLocation(storageLocation);
                            } else {
                                this.toastService.danger('Fetch error', `Unknown storage location '${barcode}'`);
                            }
                            break;

                        default:
                            this.toastService.danger('Identification error', 'Unable to identify entity');
                            break;
                    }
                }
            }
        });
    }

    ngAfterViewInit(): void {
        // Add a tiny delay to prevent race condition on change detection
        _.delay(() => {
            this.pageTemplate.setBarcodePlaceholder('Search a location');
        }, 10);
    }

    ngOnDestroy(): void {
        this.scanSubscription.unsubscribe();
        this.pageTemplate.setBarcode('');
    }

    protected async linkLotToStorageLocation(barcode: string): Promise<void> {
        const partyStorageLocation: PartyStorageLocation | undefined = this.selectedStorageLocation.partyStorageLocations.find((party: PartyStorageLocation) => party.party.internalNumber === barcode);

        if (!_.isNil(partyStorageLocation)) {
            this.toastService.warning('Warning', 'Lot is already on this location');
            return;
        }

        try {
            const partyKeys: number[] = await this.absApiService.getPartyKeysByIdentifier(barcode);

            if (_.isEmpty(partyKeys)) {
                this.toastService.danger('Fetch error', `Unable to find lot with ${barcode}`);
                return;
            }

            const partyStorageLocationKey: number = await this.logisticsService.linkPartyToStorageLocation(partyKeys[0], this.selectedStorageLocation.storageLocationId);
            const partyStorageLocations: PartyStorageLocation[] = await this.logisticsService.getPartyStorageLocations(partyStorageLocationKey);

            this.selectedStorageLocation.partyStorageLocations.unshift(partyStorageLocations[0]);
            this.toastService.success('Success', `Lot linked to ${this.selectedStorageLocation.description}`);
        } catch {
            this.toastService.danger('Error', 'Unable to link lot to storage location');
        }
    }

    protected setStorageLocation(data: StorageLocation): void {
        this.selectedStorageLocation = data;
        this.pageTemplate.setBarcodePlaceholder('Scan lot to link to location');

        _.delay(() => {
            this.pageTemplate.setBarcode('');
        }, 10);
    }

    protected constructNoLotTextOnLocation(): string {
        return `No lots on location <b>${this.selectedStorageLocation.description}</b>`;
    }

    protected backToStorageLocations(): void {
        this.selectedStorageLocation = undefined;
        this.pageTemplate.setBarcode('');
        this.pageTemplate.setBarcodePlaceholder('Search a location');

        // Give the component some time to be rendered back into the view
        _.delay(() => {
            this.storageLocationListViewComponent.setScrollTopPosition(this.storageLocationListViewScrollTop);
        }, 10);
    }

    protected isCleanPartiesDisabled(): boolean {
        if (_.isNil(this.selectedStorageLocation.partyStorageLocations) || _.isEmpty(this.selectedStorageLocation.partyStorageLocations)) {
            return true;
        }

        return false;
    }

    protected clearLotsFromLocation(): void {
        const confirmDialogRef: DialogComponentRef<ConfirmationDialogComponent> = this.dialogService.createDialog(ConfirmationDialogComponent, {
            title: 'Clear all lots',
            text: `Are you sure you want to clear all lots from location <b>${this.selectedStorageLocation.description}</b>?`,
            button_type: 'danger',
        });

        confirmDialogRef.afterClose.subscribe(async (isConfirmed: boolean) => {
            if (!isConfirmed) {
                return;
            } else {
                const locationId: number = await this.logisticsService.clearPartyStorageLocation(this.selectedStorageLocation.storageLocationId);

                if (locationId === this.selectedStorageLocation.storageLocationId) {
                    this.toastService.success('Successs', `Lots cleared from location <b>${this.selectedStorageLocation.description}</b>`);
                    this.selectedStorageLocation.partyStorageLocations = [];
                }
            }
        });
    }

    private async fetchStorageLocations(searchString: string): Promise<void> {
        try {
            const pagedEnumerable: PagedEnumerable<StorageLocation> = await this.logisticsService.getStorageLocations(searchString);
            this.storageLocations = pagedEnumerable.items;
        } catch {
            this.toastService.danger('Fetch error', 'Unable to fetch storage locations');
        }
    }
}
