/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApplicationRef, ComponentRef, createComponent, EnvironmentInjector, Inject, Injectable, Type } from '@angular/core';
import _ from 'lodash';
import { CONTEXT_MENU_CONFIG, ContextMenuConfig, ContextMenuGlobalConfig } from './interfaces/context-menu-config';
import { BaseContextMenuComponent } from './base-context-menu/base-context-menu.component';

@Injectable({
    providedIn: 'root',
})
export class ContextMenuService {
    private contextMenuReferences: ComponentRef<BaseContextMenuComponent>[] = [];
    private trackedMenus: Map<string, ComponentRef<BaseContextMenuComponent>> = new Map<string, ComponentRef<BaseContextMenuComponent>>();

    constructor(
        @Inject(CONTEXT_MENU_CONFIG) private readonly config: ContextMenuGlobalConfig,
        private readonly applicationRef: ApplicationRef,
        private readonly injector: EnvironmentInjector
    ) {}

    showContextMenu<T>(element: Type<T>, data: any, clickTarget: HTMLElement | PointerEvent | TouchEvent, override: Partial<ContextMenuConfig> = {}): void {
        this.createMenu(element, data, clickTarget, override, false);
    }

    toggleContextMenu<T>(key: string, element: Type<T>, data: any, clickTarget: HTMLElement | PointerEvent | TouchEvent, override: Partial<ContextMenuConfig> = {}, keepAllCurrentOpen: boolean = false): void {
        if (!this.trackedMenus.has(key)) {
            const ref: ComponentRef<BaseContextMenuComponent> = this.createMenu(element, data, clickTarget, override, keepAllCurrentOpen);
            this.trackedMenus.set(key, ref);
        } else {
            const ref: ComponentRef<BaseContextMenuComponent> = this.trackedMenus.get(key);
            this.removeAndDestroy(ref);
        }
    }

    closeAllMenus(): void {
        this.contextMenuReferences.forEach((menuRef: ComponentRef<BaseContextMenuComponent>) => {
            this.removeAndDestroy(menuRef);
        });
    }

    private createMenu<T>(element: Type<T>, data: any, clickTarget: HTMLElement | PointerEvent | TouchEvent, override: Partial<ContextMenuConfig> = {}, keepAllCurrentOpen: boolean): ComponentRef<BaseContextMenuComponent> {
        const conextMenuRef: ComponentRef<BaseContextMenuComponent> = createComponent(BaseContextMenuComponent, {
            environmentInjector: this.injector,
        });

        this.setupComponent(conextMenuRef, override, keepAllCurrentOpen);
        // Wait for tick before adding content
        _.delay(() => {
            conextMenuRef.instance.createMenuContent(element, data);
            this.setMenuPosition(conextMenuRef, clickTarget, override);
            this.repositionMenuIfColliding(conextMenuRef);
        }, 0);

        _.delay(() => {
            this.repositionMenuIfColliding(conextMenuRef);
        }, 20);

        return conextMenuRef;
    }

    private setupComponent(ref: ComponentRef<BaseContextMenuComponent>, override: Partial<ContextMenuConfig> = {}, keepAllCurrentOpen: boolean): void {
        const config: ContextMenuGlobalConfig = this.buildConfig(override);
        const appContainer: any = document.body.getElementsByClassName(config.root_container_class)[0];

        appContainer.appendChild(ref.location.nativeElement);
        this.applicationRef.attachView(ref.hostView);

        if (!keepAllCurrentOpen)
            if (!config.allow_multiple_open)
                this.contextMenuReferences.forEach((menuRef: ComponentRef<BaseContextMenuComponent>) => {
                    this.removeAndDestroy(menuRef);
                });

        ref.instance.onDispose.subscribe(() => {
            this.removeAndDestroy(ref);
        });

        this.contextMenuReferences.push(ref);
    }

    private setMenuPosition(ref: ComponentRef<BaseContextMenuComponent>, clickTarget: HTMLElement | PointerEvent | TouchEvent, override: Partial<ContextMenuConfig> = {}): void {
        const config: ContextMenuGlobalConfig = this.buildConfig(override);
        const isMobile: boolean = window.matchMedia('(max-width: 767px)').matches;

        if (!isMobile) {
            if ('nodeName' in clickTarget) {
                const target: HTMLElement = clickTarget as HTMLElement;
                const clickTargetRect: DOMRect = target.getBoundingClientRect();

                switch (config.position) {
                    case 'top':
                        ref.location.nativeElement.style.bottom = `${window.innerHeight - (clickTargetRect.top + config.elementSpacing)}px`;
                        ref.location.nativeElement.style.left = `${clickTargetRect.left}px`;
                        break;

                    case 'right':
                        ref.location.nativeElement.style.top = `${clickTargetRect.top}px`;
                        ref.location.nativeElement.style.left = `${clickTargetRect.right + config.elementSpacing}px`;
                        break;

                    case 'bottom':
                        ref.location.nativeElement.style.top = `${clickTargetRect.bottom + config.elementSpacing}px`;
                        ref.location.nativeElement.style.left = `${clickTargetRect.left}px`;
                        break;

                    case 'left':
                        ref.location.nativeElement.style.top = `${clickTargetRect.top}px`;
                        ref.location.nativeElement.style.right = `${window.innerWidth - (clickTargetRect.left - config.elementSpacing)}px`;
                        break;

                    default:
                        ref.location.nativeElement.style.top = '0px';
                        ref.location.nativeElement.style.left = '0px';
                }
            }

            if ('pointerType' in clickTarget) {
                const target: PointerEvent = clickTarget as PointerEvent;

                switch (config.position) {
                    case 'top':
                        ref.location.nativeElement.style.bottom = `${window.innerHeight - (target.clientY + config.elementSpacing)}px`;
                        ref.location.nativeElement.style.left = `${target.clientX}px`;
                        break;

                    case 'right':
                        ref.location.nativeElement.style.top = `${target.clientY}px`;
                        ref.location.nativeElement.style.left = `${target.clientX + config.elementSpacing}px`;
                        break;

                    case 'bottom':
                        ref.location.nativeElement.style.top = `${target.clientY}px`;
                        ref.location.nativeElement.style.left = `${target.clientX + config.elementSpacing}px`;
                        break;

                    case 'left':
                        ref.location.nativeElement.style.top = `${target.clientY}px`;
                        ref.location.nativeElement.style.right = `${window.innerWidth - (target.clientX + config.elementSpacing)}px`;
                        break;

                    default:
                        ref.location.nativeElement.style.top = `${target.clientY}px`;
                        ref.location.nativeElement.style.left = `${target.clientX + config.elementSpacing}px`;
                }
            }

            if ('changedTouches' in clickTarget) {
                const event: TouchEvent = clickTarget as TouchEvent;
                const target: Touch = event.touches.item(0);

                switch (config.position) {
                    case 'top':
                        ref.location.nativeElement.style.bottom = `${window.innerHeight - (target.clientY + config.elementSpacing)}px`;
                        ref.location.nativeElement.style.left = `${target.clientX}px`;
                        break;

                    case 'right':
                        ref.location.nativeElement.style.top = `${target.clientY}px`;
                        ref.location.nativeElement.style.left = `${target.clientX + config.elementSpacing}px`;
                        break;

                    case 'bottom':
                        ref.location.nativeElement.style.top = `${target.clientY}px`;
                        ref.location.nativeElement.style.left = `${target.clientX + config.elementSpacing}px`;
                        break;

                    case 'left':
                        ref.location.nativeElement.style.top = `${target.clientY}px`;
                        ref.location.nativeElement.style.right = `${window.innerWidth - (target.clientX + config.elementSpacing)}px`;
                        break;

                    default:
                        ref.location.nativeElement.style.top = `${target.clientY}px`;
                        ref.location.nativeElement.style.left = `${target.clientX + config.elementSpacing}px`;
                }
            }

            this.repositionMenuIfColliding(ref);
        }
    }

    // This function is not water proof, there is a case where a context menu would be to large to fit anyway (very unlikely)
    // and that would result into infinity if you would recursively check / resolve with this function.
    // So in this very unlikely case we should add an overflow to the menu with some max height.
    // If nobody fucks with the base context menu and we set a max height and width we would never come in this scenarion described above.
    private repositionMenuIfColliding(ref: ComponentRef<BaseContextMenuComponent>): void {
        // Check top
        if (ref.location.nativeElement.offsetTop < 0) {
            ref.location.nativeElement.style.top = '12px';
            ref.location.nativeElement.style.bottom = '';
        }

        // Check right
        if (ref.location.nativeElement.offsetLeft + ref.location.nativeElement.clientWidth > window.innerWidth) {
            ref.location.nativeElement.style.right = '12px';
            ref.location.nativeElement.style.left = '';
        }

        // Check bottom
        if (ref.location.nativeElement.offsetTop + ref.location.nativeElement.clientHeight > window.innerHeight) {
            ref.location.nativeElement.style.bottom = '12px';
            ref.location.nativeElement.style.top = '';
        }

        // Check left
        if (ref.location.nativeElement.offsetLeft < 0) {
            ref.location.nativeElement.style.left = '12px';
            ref.location.nativeElement.style.right = '';
        }
    }

    private removeAndDestroy(menuRef: ComponentRef<BaseContextMenuComponent>): void {
        if (!_.isNil(menuRef)) {
            const index: number = this.contextMenuReferences.indexOf(menuRef);

            if (index > -1) {
                this.contextMenuReferences.splice(index, 1);

                menuRef.instance.handleDestroy();
                // Give the animation some time before destroying the component.
                _.delay(() => {
                    menuRef.destroy();

                    let keyToDelete: string = '';

                    this.trackedMenus.forEach((value: ComponentRef<BaseContextMenuComponent>, key: string) => {
                        if (_.isEqual(value, menuRef)) keyToDelete = key;
                    });

                    if (!_.isEmpty(keyToDelete)) this.trackedMenus.delete(keyToDelete);
                }, 300);
            }
        }
    }

    private buildConfig(override: Partial<ContextMenuConfig> = {}): ContextMenuGlobalConfig {
        return { ...this.config, ...override };
    }
}
