import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { commonViewImports } from '../../utilities/global-imports';
import { ButtonDirective } from '../../directives/button.directive';
import { DIALOG_DATA, DialogComponentRef } from '../../components/dialog/models/dialog-component-ref';
import { ToastService } from '../../components/toast/toast.service';
import { ScanService } from '../../services/scan.service';
import { Party } from '../../models/party.model';
import { Validator } from '../../validator/validator';
import { ValidateDirective } from '../../validator/directives/validate.directive';
import { ValidateErrorDirective } from '../../validator/directives/validate-error.directive';
import { ValidationHost } from '../../validator/validation-host';
import { LogisticsService } from '../../services/logistics.service';
import { ValidationRule } from '../../validator/rules/validation-rule';
import { CustomValidationRule, CustomValidationRuleValidateResult } from '../../validator/rules/custom-validation-rule';

@Component({
    standalone: true,
    templateUrl: './distribute-stock-dialog.component.html',
    styleUrl: './distribute-stock-dialog.component.scss',
    imports: [commonViewImports, ButtonDirective],
})
export class DistributeStockDialogComponent implements OnInit, ValidationHost {
    protected lotFound: Party = null;
    protected barcode: string = '';
    protected boxes: number = 0;
    protected stems: number = 0;
    protected description: string = '';
    protected packings: number | null = null;
    protected packingValidationRules: ValidationRule[] = [];

    private validator: Validator;

    @ViewChildren(ValidateDirective) private readonly validateDirectives: ValidateDirective[];
    @ViewChildren(ValidateErrorDirective) private readonly validateErrorDirectives: ValidateErrorDirective[];

    constructor(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        @Inject(DIALOG_DATA) private readonly data: any,
        private readonly dialogComponentRef: DialogComponentRef<DistributeStockDialogComponent>,
        private readonly logisticsService: LogisticsService,
        private readonly toastService: ToastService,
        private readonly scanService: ScanService
    ) {
        this.lotFound = data.lot;
    }

    ngOnInit(): void {
        try {
            this.validator = new Validator(this);
            this.setPackingValidationRules();
            this.boxes = this.lotFound.boxes;
            this.stems = this.lotFound.stems;
            this.barcode = this.lotFound.internalNumber;
            this.description = this.lotFound.description;
        } catch (err) {
            this.toastService.danger('Loading error', err as string);
        }
    }

    getValidationItems(): ValidateDirective[] {
        return this.validateDirectives;
    }

    getValidationErrorItems(): ValidateErrorDirective[] {
        return this.validateErrorDirectives;
    }

    protected distributeStock(): void {
        try {
            this.validator.resetValidationStates();
            const isValid: boolean = this.validator.validate();

            if (isValid) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const tasks: Promise<any>[] = [];

                const v: number = this.boxes / this.packings;
                for (let i = 0; i < Math.floor(v); i++) tasks.push(this.logisticsService.distributePartyStock(this.lotFound.partyKey, this.packings));

                const remainder: number = this.boxes % this.packings;
                if (remainder > 0) tasks.push(this.logisticsService.distributePartyStock(this.lotFound.partyKey, remainder));

                Promise.allSettled(tasks).then((result) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const amountFailed: number = result.filter((element: any) => element.status === 'rejected').length;

                    if (amountFailed > 0) this.toastService.danger('Error', `Failed to distribute party to ${amountFailed} of total ${tasks.length}`);
                    else if (remainder > 0) this.toastService.success('Success', `Distributed party on ${tasks.length} trolleys, with a remainder trolley of ${remainder}`);
                    else this.toastService.success('Success', `Distributed party on ${tasks.length} trolleys!`);
                });

                this.close();
            }
        } catch (err) {
            this.toastService.danger('Error', err as string);
        }
    }

    protected close(): void {
        this.dialogComponentRef.closeDialog();
    }

    private setPackingValidationRules(): void {
        const func = <T>(value: T): CustomValidationRuleValidateResult => {
            const val: number = value as number;

            if (val > 99999) return new CustomValidationRuleValidateResult(false, 'must be less than 99999');
            else return new CustomValidationRuleValidateResult(true, '');
        };

        this.packingValidationRules.push(new CustomValidationRule('packing', func));
    }
}
