import { Component, Inject, OnInit } from '@angular/core';
import { ButtonDirective } from '../../directives/button.directive';
import { InputDirective } from '../../directives/input.directive';
import { DIALOG_DATA, DialogComponentRef } from '../../components/dialog/models/dialog-component-ref';
import { angularImports } from '../../utilities/global-imports';

@Component({
    standalone: true,
    templateUrl: './tc-weight-dialog.component.html',
    styleUrl: './tc-weight-dialog.component.scss',
    imports: [angularImports, ButtonDirective, InputDirective],
})
export class TcWeightDialogComponent implements OnInit {
    protected netWeight: number | null = null;
    protected grossWeight: number | null = null;

    constructor(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        @Inject(DIALOG_DATA) private readonly dialogData: any,
        private readonly dialogComponentRef: DialogComponentRef<TcWeightDialogComponent>
    ) {}

    ngOnInit(): void {
        this.netWeight = this.dialogData.net_weight;
        this.grossWeight = this.dialogData.gross_weight;
    }

    closeDialog(event: Event): void {
        event.stopPropagation();
        this.dialogComponentRef.closeDialog({
            is_submitted: false,
            net_weight: this.netWeight,
            gross_weight: this.grossWeight,
        });
    }

    submit(event: Event): void {
        event.stopPropagation();
        this.dialogComponentRef.closeDialog({
            is_submitted: true,
            net_weight: this.netWeight,
            gross_weight: this.grossWeight,
        });
    }
}
