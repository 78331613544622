/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject } from '@angular/core';
import { ButtonDirective } from '../../directives/button.directive';
import { DIALOG_DATA, DialogComponentRef } from '../../components/dialog/models/dialog-component-ref';
import _ from 'lodash';

@Component({
    standalone: true,
    templateUrl: './confirmation-dialog.component.html',
    styleUrl: './confirmation-dialog.component.scss',
    imports: [ButtonDirective],
})
export class ConfirmationDialogComponent {
    protected title: string = 'Confirmation';
    protected text: string = '';
    protected confirmButtonText: string = 'Yes';
    protected cancelButtonText: string = 'Cancel';
    protected buttonType: string = 'primary';

    constructor(
        @Inject(DIALOG_DATA) private readonly data: any,
        private readonly dialogComponentRef: DialogComponentRef<ConfirmationDialogComponent>
    ) {
        this.dialogComponentRef.disableCloseOnClickOutside();
        if (_.isNil(data)) {
            console.warn('Expected data to be injected, but nothing was injected.');
            return;
        }

        if (!_.isNil(data.title)) this.title = data.title;

        if (!_.isNil(data.text)) this.text = data.text;

        if (!_.isNil(data.confirmation_button_text)) this.confirmButtonText = data.confirmation_button_text;

        if (!_.isNil(data.cancel_button_text)) this.cancelButtonText = data.cancel_button_text;

        if (!_.isNil(data.button_type)) this.buttonType = data.button_type;
    }

    protected cancel(): void {
        this.dialogComponentRef.closeDialog(false);
    }

    protected confirm(): void {
        this.dialogComponentRef.closeDialog(true);
    }
}
