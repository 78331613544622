import { ValidationRule } from './validation-rule';
import _ from 'lodash';

export class RequiredValidationRule implements ValidationRule {
    public readonly key: string = 'required';
    public readonly errorMessage: string = 'is required';

    validateRule<T>(value: T): boolean {
        if (typeof value === 'string') return !_.isEmpty(value);

        if (typeof value === 'boolean') return (value as boolean) === true;

        return false;
    }
}
