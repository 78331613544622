<div class="receive-overview__container">
    @if (lots.length > 0) {
        <div class="receive__actions">
            <ax-checkbox [size]="'small'" [isChecked]="true" (isCheckedChange)="checkAllRows()"></ax-checkbox>
            <span>All</span>
            <div class="receive__actions__btn">
                <button [ax-button]="'primary'" (click)="showSearchBySupplier()"><i class="fa-light fa-search"></i></button>
                <button [disabled]="selectedLots.length === 0" [ax-button]="'primary'" style="margin-left: .5rem;" (click)="receiveLots()">Receive</button>
            </div>
        </div>
        <ax-list-view
            [isLoading]="isLoading"
            [definitions]="listViewDefinitions"
            [data]="lots"
            [columnCount]="'auto'"
            [hasMultiSelectEnabled]="true"
            (selectedRows)="selectedRows($event)"
            (rowClicked)="showLotDetailsDialog($event)"
        ></ax-list-view>
    } @else {
        <div class="no__data__container">
            <i class="no__data__container__icon fa-light fa-truck-ramp-box"></i>
            <h5>{{ getEmptyStateText() }}</h5>
            <button [ax-button]="'primary'" (click)="showSearchBySupplier()">Search supplier</button>
        </div>
    }
</div>
