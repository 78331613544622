import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[ax-validate-error]',
    standalone: true,
})
export class ValidateErrorDirective implements OnInit {
    @Input('ax-validate-error') public validationKey: string = '';
    @Input('ax-validate-error-node') public nodeType: 'span' | 'small' = 'small';

    // I might miss a few, feel free to add where see fit.
    private readonly supportedTextNodes: string[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'label', 'span', 'small', 'i', 'b', 'a', 'p', 'strong'];
    private textOutputElement: HTMLElement;

    constructor(private readonly elementRef: ElementRef) {}

    ngOnInit(): void {
        this.elementRef.nativeElement.style.display = 'none';

        const nodeName: string = this.elementRef.nativeElement.nodeName;

        if (!this.supportedTextNodes.includes(nodeName.toLowerCase())) {
            this.textOutputElement = document.createElement(this.nodeType);
            this.elementRef.nativeElement.appendChild(this.textOutputElement);
        } else this.textOutputElement = this.elementRef.nativeElement;

        this.textOutputElement.innerHTML = '';
    }

    handleValidation(isValid: boolean, errorMessage: string): void {
        if (!isValid) {
            this.elementRef.nativeElement.style.display = 'flex';
            this.textOutputElement.innerHTML = errorMessage;
        } else {
            this.elementRef.nativeElement.style.display = 'none';
            this.textOutputElement.innerHTML = '';
        }
    }
}
