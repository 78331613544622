import { Routes } from '@angular/router';
import { HomePageComponent } from '../views/home/home-page.component';
import { PutawayOverviewPageComponent } from '../views/putaway/overview/putaway-overview-page.component';
import { DistributionOverviewPageComponent } from '../views/distribution/overview/distribution-overview-page.component';
import { SalesOverviewPageComponent } from '../views/sales/overview/sales-overview-page.component';
import { OnLocationPageComponent } from '../views/putaway/on-location/on-location-page.component';
import { TransferPageComponent } from '../views/putaway/transfer/transfer-page.component';
import { OnContainerPageComponent } from '../views/putaway/on-container/on-container-page.component';
import { SalesRegistrationPageComponent } from '../views/sales/sales-registration/sales-registration-page.component';
import { ShipmentCheckPageComponent } from '../views/distribution/shipment-check/overview/shipment-check-page.component';
import { TCRegistrationPageComponent } from '../views/distribution/tc-registration/tc-registration-page.component';
import { LoadRegistrationPageComponent } from '../views/distribution/load-registration/overview/load-registration-page.component';
import { StockDistributePageComponent } from '../views/distribution/stock-distribute/stock-distribute-page.component';
import { DeliveryNotePageComponent } from '../views/distribution/delivery-note/delivery-note-page.component';
import { ReceivePageComponent } from '../views/receive/receive-page.component';
import { StocktakeOverviewPageComponent } from '../views/stocktake/overview/stocktake-overview-page.component';
import { AuthConfigGuard } from '../middleware/auth-config.guard';
import { PageNotFoundComponent } from '../views/error/page-not-found/page-not-found.component';
import { ForbiddenPageComponent } from '../views/error/forbidden/forbidden-error-page.component';
import { SettingsPageComponent } from '../views/settings/settings-page.component';
import { DevelopmentPageComponent } from '../views/development/development-page.component';
import { ShipmentCheckDetailsPageComponent } from '../views/distribution/shipment-check/details/shipment-check-details-page.component';
import { TcSelectionPageComponent } from '../views/distribution/tc-registration/selection/tc-selection-page.component';
import { TcCaskSelectionPageComponent } from '../views/distribution/tc-registration/cask-selection/tc-cask-selection-page.component';
import { LoadRegistrationDetailsPageComponent } from '../views/distribution/load-registration/details/load-registration-details-page.component';
import { MeasuringDetailsPageComponent } from '../views/measuring/details/measuring-details-page.component';
import { MeasuringPageComponent } from '../views/measuring/overview/measuring-page.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'home',
        title: 'Home',
        component: HomePageComponent,
        pathMatch: 'full',
        data: {
            iconClass: 'fa-light fa-house',
        },
    },
    {
        path: 'receive',
        title: 'Receive',
        component: ReceivePageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            iconClass: 'fa-light fa-truck-ramp-box',
            access_key: 'receive',
        },
    },
    {
        path: 'putaway',
        title: 'Putaway',
        component: PutawayOverviewPageComponent,
        canActivate: [AuthConfigGuard],
        pathMatch: 'full',
        data: {
            iconClass: 'fa-light fa-boxes-stacked',
            access_key: 'putaway-overview',
        },
    },
    {
        path: 'putaway/on-location',
        title: 'On location',
        component: OnLocationPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'putaway-on-location',
        },
    },
    {
        path: 'putaway/transfer',
        title: 'Transfer',
        component: TransferPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'putaway-transfer',
        },
    },
    {
        path: 'putaway/on-container',
        title: 'On Container',
        component: OnContainerPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'putaway-on-container',
        },
    },
    {
        path: 'stocktake',
        title: 'Stocktake',
        component: StocktakeOverviewPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            iconClass: 'fa-light fa-box-circle-check',
            access_key: 'stocktake',
        },
    },
    {
        path: 'distribution',
        title: 'Distribution',
        component: DistributionOverviewPageComponent,
        canActivate: [AuthConfigGuard],
        pathMatch: 'full',
        data: {
            iconClass: 'fa-light fa-shelves',
            access_key: 'distribution-overview',
        },
    },
    {
        path: 'distribution/shipment-check',
        title: 'Shipment Check',
        component: ShipmentCheckPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'shipment-check',
        },
    },
    {
        path: 'distribution/shipment-check/:shipmentKey',
        title: 'Shipment Check',
        component: ShipmentCheckDetailsPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'shipment-check',
        },
    },
    {
        path: 'distribution/tc-registration',
        title: 'TC Registration',
        component: TCRegistrationPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'tc-registration',
        },
    },
    {
        path: 'distribution/tc-registration/:labelId/:barcode',
        title: 'TC Registration',
        component: TCRegistrationPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'tc-registration',
        },
    },
    {
        path: 'distribution/tc-registration/:labelId/:barcode/:entityType',
        title: 'TC Registration',
        component: TCRegistrationPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'tc-registration',
        },
    },
    {
        path: 'distribution/tc-selection/:labelId/:entityType',
        title: 'Transportcarrier Selection',
        component: TcSelectionPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'tc-registration',
        },
    },
    {
        path: 'distribution/tc-cask-selection/:shipmentKey/:labelId/:entityType',
        title: 'Transportcarrier Cask Selection',
        component: TcCaskSelectionPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'tc-registration',
        },
    },
    {
        path: 'distribution/load-registration',
        title: 'Load Registration',
        component: LoadRegistrationPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'load-registration',
        },
    },
    {
        path: 'distribution/load-registration/:loadRegistrationKey',
        title: 'Load Registration',
        component: LoadRegistrationDetailsPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'load-registration',
        },
    },
    {
        path: 'distribution/stock-distribute',
        title: 'Stock Distribute',
        component: StockDistributePageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'stock-distribute',
        },
    },
    {
        path: 'distribution/delivery-note',
        title: 'Delivery Note',
        component: DeliveryNotePageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'delivery-note',
        },
    },
    {
        path: 'measuring',
        title: 'Measuring',
        component: MeasuringPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            iconClass: 'fa-light fa-weight-scale',
            access_key: 'measuring',
        },
    },
    {
        path: 'measuring/:measurementPointKey',
        title: 'Measuring',
        component: MeasuringDetailsPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'measuring',
        },
    },
    {
        path: 'sales',
        title: 'Sales',
        component: SalesOverviewPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            iconClass: 'fa-light fa-box-dollar',
            access_key: 'sales-overview',
        },
    },
    {
        path: 'sales/sales-registration',
        title: 'Sales Registration',
        component: SalesRegistrationPageComponent,
        pathMatch: 'full',
        canActivate: [AuthConfigGuard],
        data: {
            access_key: 'sales-registration',
        },
    },
    {
        path: '403',
        title: 'Forbidden',
        component: ForbiddenPageComponent,
    },
    {
        path: '404',
        title: 'Page not found',
        component: PageNotFoundComponent,
    },
    {
        path: 'settings',
        title: 'Settings',
        component: SettingsPageComponent,
    },
    {
        path: 'development',
        title: 'Development',
        component: DevelopmentPageComponent,
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: '404',
    },
];
