import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastService } from '../../../../components/toast/toast.service';
import { ApplicationService } from '../../../../services/application.service';
import { LogisticsService } from '../../../../services/logistics.service';
import { ScanService } from '../../../../services/scan.service';
import { EntityIdentification, EntityType } from '../../../../utilities/entity-identification';
import { AddToTransportCarrierValidationResult } from '../../../../models/add-to-transport-carrier-validation-result';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'ax-load-registration-details-page',
    standalone: true,
    templateUrl: './load-registration-details-page.component.html',
    styleUrl: './load-registration-details-page.component.scss',
})
export class LoadRegistrationDetailsPageComponent implements OnInit {
    private scanSubscription: Subscription;
    private loadRegistrationKey: number = 0;

    constructor(
        private readonly logisticsService: LogisticsService,
        private readonly toastService: ToastService,
        private readonly scanService: ScanService,
        private readonly applicationService: ApplicationService,
        route: ActivatedRoute
    ) {
        this.loadRegistrationKey = route.snapshot.params['loadRegistrationKey'];
    }

    async ngOnInit(): Promise<void> {
        this.scanSubscription = this.scanService.onScan.subscribe(async (barcode) => {
            const entity: EntityIdentification | Error = EntityIdentification.validateBarcode(barcode);
            if (!(entity instanceof Error) && entity.entityType === EntityType.TransportCarrier) await this.addToTransportCarrierAsync(entity.entityKey);
            else this.toastService.danger('Barcode', 'The barcode that was scanned is not a valid TransportCarrier');
        });
    }

    private async addToTransportCarrierAsync(transportCarrierId: number): Promise<void> {
        try {
            const userKey = this.applicationService.getUserKey();
            if (userKey === null) this.toastService.danger('User', 'The user(key) was not found, this is required for this feature');
            else {
                const validationResult = await this.logisticsService.addToTransportCarrierAsync(userKey, this.loadRegistrationKey, transportCarrierId);
                if (validationResult.isvalid) this.toastService.success('Load', 'Successfully registered Load to TransportCarrier');
                else this.showValidationResultInfoDialog(validationResult);
            }
        } catch {
            this.toastService.danger('Transportcarrier', 'Failed to register this load to the transportcarrier');
        }
    }

    private showValidationResultInfoDialog(validationResult: AddToTransportCarrierValidationResult): void {
        const infoList = this.generateAddToTransportCarrierValidationResultInfoList(validationResult);
        this.toastService.danger('Validation results', infoList[0]);
    }

    private generateAddToTransportCarrierValidationResultInfoList(validationResult: AddToTransportCarrierValidationResult): string[] {
        const messages: string[] = [];

        messages.push(...validationResult.validationResultMessage);

        return messages;
    }
}
