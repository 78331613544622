/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, DoCheck, ElementRef, EventEmitter, Input, IterableDiffer, IterableDiffers, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { angularImports, pipeImports } from '../../utilities/global-imports';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { LoaderComponent } from '../loader/loader.component';
import { TableColumn, TableColumnOption } from './interfaces/table-column';
import { SelectedTableRow } from './interfaces/selected-table-row';
import { ContextMenuCallbackEvent } from './interfaces/context-menu-callback-event';
import { TableRouteResolverEvent } from './interfaces/table-route-resolver';
import { TableColumnType } from './enums/table-column-property-type';
import { TableColumnOptionKey } from './enums/table-column-option-key';
import { Router } from '@angular/router';
import _ from 'lodash';
import { SortDirection } from './enums/sort-direction';

@Component({
    standalone: true,
    selector: 'ax-table',
    templateUrl: './table.component.html',
    styleUrl: './table.component.scss',
    imports: [angularImports, CheckboxComponent, LoaderComponent, pipeImports],
})
export class TableComponent implements OnInit, OnChanges, DoCheck {
    @Input() public isLoading: boolean = false;
    @Input() public data: any = [];
    @Input() public columns: TableColumn[] = [];
    @Input() public hasMultiSelectEnabled: boolean = false;
    @Input() public isInfiniteScrollEnabled: boolean = true;
    @Input() public loaderText: string = 'Fetching your data';
    @Input() public noDataText: string = 'Nothing to see here...';
    @Output() public selectedRows: EventEmitter<SelectedTableRow[]> = new EventEmitter<SelectedTableRow[]>();
    @Output() public sort: EventEmitter<TableColumn> = new EventEmitter<TableColumn>();
    @Output() public scrollTrigger: EventEmitter<void> = new EventEmitter<void>();
    @Output() public contextMenuItemCallback: EventEmitter<ContextMenuCallbackEvent> = new EventEmitter<ContextMenuCallbackEvent>();
    @Output() public resolveRoute: EventEmitter<TableRouteResolverEvent> = new EventEmitter<TableRouteResolverEvent>();

    @ViewChild('tableElement') public readonly tableElement: ElementRef;

    protected hasAllRowsChecked: boolean = false;
    protected loaderDelay: number = 0;
    protected readonly tablePropertyType = TableColumnType;
    protected readonly tableColumnOptionKey = TableColumnOptionKey;

    private _selectedRows: SelectedTableRow[] = [];
    private urlKey: string = '';
    private iterableDiffer: IterableDiffer<any>;
    private delayScrollId: number = -1;
    private loaderTimer: NodeJS.Timeout;

    constructor(
        private router: Router,
        private readonly iterableDiffers: IterableDiffers
    ) {}

    ngOnInit(): void {
        this.iterableDiffer = this.iterableDiffers.find(this.data).create(null);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['isLoading'])
            if (this.isLoading === true)
                // Javascript can't keep up with actual milliseconds so we increment every 10 ms.
                this.loaderTimer = setInterval(() => {
                    this.loaderDelay++;
                }, 10);
            else {
                clearInterval(this.loaderTimer);
                this.loaderDelay = 0;
            }
    }

    ngDoCheck(): void {
        // Change detection to update all selected state if new items are pushed to the data array;
        const dataChanges = this.iterableDiffer.diff(this.data);

        if (dataChanges !== undefined && dataChanges !== null) if (this.hasAllRowsChecked) this.hasAllRowsChecked = false;
    }

    setColumnCaptionClasses(column: TableColumn): string {
        return column.is_sortable ? 'sortable' : '';
    }

    setSortDirectionClass(column: TableColumn): string {
        switch (column.sort_direction) {
            case SortDirection.Descending:
                return 'ax-table__sort-icon--descending';

            case SortDirection.Ascending:
                return 'ax-table__sort-icon--ascending';

            default:
                return '';
        }
    }

    setRowClasses(index: number): string {
        const isSelected = this.isSelectedRow(index) ? ' selected ' : '';
        const hasMultiSelectEnabled = this.hasMultiSelectEnabled ? ' multi-select-enabled ' : '';
        return isSelected + hasMultiSelectEnabled;
    }

    setCellDefaultClasses(column: TableColumn): string {
        let classList: string = '';

        if ((!_.isEmpty(column.route_url) && !_.isEmpty(column.route_property_name)) || (!_.isNil(column.use_route_resolver) && column.use_route_resolver === true)) classList = `${classList} navigatable`;

        return classList;
    }

    navigateTo(index: number, column: TableColumn, event: Event): void {
        if ((_.isEmpty(column.route_url) || _.isEmpty(column.route_property_name)) && (_.isNil(column.use_route_resolver) || column.use_route_resolver === false)) return;

        event.stopPropagation();

        if (column.use_route_resolver)
            this.resolveRoute.emit({
                row_index: index,
                column: column,
                data: this.data[index],
            });
        else this.router.navigateByUrl(`${this.urlKey}/${column.route_url}/${this.data[index][column.route_property_name]}`);
    }

    selectRow(index: number, event: Event): void {
        if (!this.hasMultiSelectEnabled) return;

        event.stopPropagation();

        const pointerEvent: PointerEvent = event as PointerEvent;

        if (pointerEvent.ctrlKey || pointerEvent.metaKey) {
            document.getSelection().removeAllRanges();
            event.preventDefault();

            if (_.isEmpty(this._selectedRows))
                for (let i: number = 0; i <= index; i++)
                    this._selectedRows.push({
                        index: i,
                        data: this.data[i],
                    });
            else {
                const closest: SelectedTableRow = this._selectedRows.reduce((prev: SelectedTableRow, curr: SelectedTableRow) => {
                    return Math.abs(curr.index - index) < Math.abs(prev.index - index) ? curr : prev;
                });

                if (index > closest.index)
                    for (let i: number = closest.index + 1; i <= index; i++)
                        this._selectedRows.push({
                            index: i,
                            data: this.data[i],
                        });
                else if (index < closest.index)
                    for (let i: number = closest.index - 1; i >= index; i--)
                        this._selectedRows.push({
                            index: i,
                            data: this.data[i],
                        });
                else if (index === closest.index) {
                    const targetRow: any = this.data[index];
                    const selectedRow: any = this._selectedRows.find((x: SelectedTableRow) => x.index === index);

                    if (selectedRow === undefined)
                        this._selectedRows.push({
                            index: index,
                            data: targetRow,
                        });
                    else this._selectedRows.splice(this._selectedRows.indexOf(selectedRow), 1);
                }
            }
        } else {
            const targetRow: any = this.data[index];
            const selectedRow: any = this._selectedRows.find((x: SelectedTableRow) => x.index === index);

            if (selectedRow === undefined)
                this._selectedRows.push({
                    index: index,
                    data: targetRow,
                });
            else this._selectedRows.splice(this._selectedRows.indexOf(selectedRow), 1);
        }

        this.setAllRowsChecked();
        this.selectedRows.emit(this._selectedRows);
    }

    selectAllRows(event: Event): void {
        if (!this.hasMultiSelectEnabled) return;

        event.stopPropagation();
        this.hasAllRowsChecked = !this.hasAllRowsChecked;

        if (this.hasAllRowsChecked) {
            this._selectedRows = [];
            this.data.forEach((data: any) => {
                this._selectedRows.push({
                    index: this.data.indexOf(data),
                    data: data,
                });
            });
        } else this._selectedRows = [];

        this.setAllRowsChecked();
        this.selectedRows.emit(this._selectedRows);
    }

    isSelectedRow(index: number): boolean {
        if (!this.hasMultiSelectEnabled || _.isEmpty(this._selectedRows)) return false;

        const selectedRow: SelectedTableRow = this._selectedRows.find((x: SelectedTableRow) => x.index === index);

        if (selectedRow === undefined) return false;

        return true;
    }

    getColumnOptionValueAsString(column: TableColumn, optionValue: string): string {
        if (_.isEmpty(column.options)) return '';

        const option = column.options?.find((option: TableColumnOption) => option.key === optionValue);

        if (!option?.value) return '';

        return option.value as string;
    }

    getColumnOptionValueAsBoolean(column: TableColumn, optionValue: string): boolean {
        if (_.isEmpty(column.options)) return null;

        const option = column.options?.find((option: TableColumnOption) => option.key === optionValue);

        if (!option?.value) return null;

        return option.value as boolean;
    }

    getColumnOptionAsObjectValue(index: number, column: TableColumn, optionValue: string): string {
        if (_.isEmpty(column.options) || !this.data[index] || !this.data[index][column.property_name]) return '';

        return this.data[index][column.property_name][this.getColumnOptionValueAsString(column, optionValue)];
    }

    onSort(column: TableColumn): void {
        if (!column || !column.is_sortable || this.isLoading) return;

        if (!column.sort_direction) column.sort_direction = SortDirection.None;

        this.columns.forEach((tableColumn: TableColumn) => {
            if (column !== tableColumn) tableColumn.sort_direction = SortDirection.None;
        });

        if (column.sort_direction === SortDirection.None) column.sort_direction = SortDirection.Ascending;
        else if (column.sort_direction === SortDirection.Ascending) column.sort_direction = SortDirection.Descending;
        else if (column.sort_direction === SortDirection.Descending) column.sort_direction = SortDirection.None;

        this.sort.emit(column);
    }

    setCellBooleanIconClasses(value: boolean): string {
        return value ? 'fa-duotone fa-circle-check ax-table__cell__boolean__icon--true' : 'fa-duotone fa-circle-xmark ax-table__cell__boolean__icon--false';
    }

    onScrolled(event: Event): void {
        if (this.isInfiniteScrollEnabled && !this.isLoading) {
            clearTimeout(this.delayScrollId);

            this.delayScrollId = _.delay(() => {
                const targetElement: HTMLElement = event.target as HTMLElement;

                const scrollPercent: number = (targetElement.scrollTop + targetElement.clientHeight) / targetElement.scrollHeight;
                const scrollPercentRounded: number = Math.round(scrollPercent * 100);

                if (scrollPercentRounded >= 50) this.scrollTrigger.emit();
            }, 100);
        }
    }

    clearSelectedRows(): void {
        this._selectedRows = [];
    }

    protected getPropertyValue(entity: any, propertyName: string): any {
        if (_.isNil(propertyName)) return '';

        if (propertyName.includes('.')) {
            const splittedProperties: string[] = propertyName.split('.');
            let data: any = entity;

            for (const prop of splittedProperties) {
                if (_.isNil(data)) {
                    data = '';
                    break;
                }
                if (prop.includes('[')) {
                    const [key, index] = prop.split('[');
                    const idx = parseInt(index.replace(']', ''));
                    data = data[key] && data[key][idx];
                } else data = data[prop];
            }

            return data;
        } else return entity[propertyName];
    }

    private setAllRowsChecked(): void {
        this.hasAllRowsChecked = this._selectedRows.length === this.data.length;
    }
}
