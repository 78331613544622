/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    IterableDiffer,
    IterableDiffers,
    KeyValueChangeRecord,
    KeyValueChanges,
    KeyValueDiffer,
    KeyValueDiffers,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    forwardRef,
} from '@angular/core';
import { angularImports } from '../../utilities/global-imports';
import { SelectOption } from './interfaces/select-option';
import { ButtonDirective } from '../../directives/button.directive';
import _ from 'lodash';
import { ValidationContext } from '../../validator/validation-context';

@Component({
    standalone: true,
    selector: 'ax-select',
    templateUrl: './select.component.html',
    styleUrl: './select.component.scss',
    imports: [angularImports, ButtonDirective],
    providers: [{ provide: ValidationContext, useExisting: forwardRef(() => this) }],
})
export class SelectComponent extends ValidationContext implements OnInit, DoCheck {
    @Input() public value: string;
    @Input() public options: SelectOption[] = [];
    @Input() public hasClearEnabled: boolean = true;
    @Input() public placeholder: string = 'placeholder';
    @Input() public mobileSelectContainerPlaceholder: string | undefined = undefined;
    @Input() public tabIndex: number = 0;
    @Input() public isValid: boolean = true;
    @Input() public isDisabled: boolean = false;
    @Input() public noOptionsText: string = 'Nothing to show';
    @Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('selectContainer') private readonly selectContainer: ElementRef;
    @ViewChild('optionsContainer') private readonly optionsContainer: ElementRef;
    @ViewChildren('optionElement') private readonly optionElements: QueryList<ElementRef>;

    @HostListener('window:resize')
    public onResize(): void {
        this.isMobile = window.matchMedia('(max-width: 767px)').matches;
    }

    @HostListener('keydown.arrowDown', ['$event'])
    public onArrowDown(event: KeyboardEvent): void {
        event.preventDefault();

        if (this.preSelectedIndex === -1) this.preSelectedIndex = 0;
        else if (this.preSelectedIndex === this.internalOptions.length - 1) return;
        else this.preSelectedIndex++;

        if (this.preSelectedIndex !== -1) this.scrollToPreSelectedIndexItem();

        // TODO: Flip this when component is dropping up
    }

    @HostListener('keydown.arrowUp', ['$event'])
    public onArrowUp(event: KeyboardEvent): void {
        event.preventDefault();

        if (this.preSelectedIndex === -1) return;
        else if (this.preSelectedIndex === 0) this.preSelectedIndex = -1;
        else this.preSelectedIndex--;

        if (this.preSelectedIndex > -1) this.scrollToPreSelectedIndexItem();

        // TODO: Flip this when component is dropping up
    }

    @HostListener('keydown.enter', ['$event'])
    public onEnter(event: KeyboardEvent): void {
        event.preventDefault();

        if (this.preSelectedIndex > -1) {
            const optionToSelect: SelectOption = this.internalOptions[this.preSelectedIndex];

            if (!_.isNil(optionToSelect)) this.selectOption(optionToSelect);
        } else this.toggleCollapse();
    }

    @HostListener('keydown.escape', ['$event'])
    public onEscape(event: KeyboardEvent): void {
        event.preventDefault();

        if (!this.isCollapsed) this.toggleCollapse();
        else this.clear(null);
    }

    public displayValue: string = '';
    public hasSelectedValue: boolean = false;
    public isCollapsed: boolean = true;
    public internalOptions: SelectOption[] = [];
    public isMobile: boolean = window.matchMedia('(max-width: 767px)').matches;

    private keyValueDiffer: KeyValueDiffer<string, any>;
    private iteratableDiffer: IterableDiffer<SelectOption>;
    private isInternallyTriggered: boolean = false;
    private preSelectedIndex: number = -1;
    private selectedOptionIndex: number = -1;

    constructor(
        private readonly differs: KeyValueDiffers,
        private readonly iteratableDiffers: IterableDiffers
    ) {
        super();
        this.keyValueDiffer = this.differs.find({}).create();
        this.iteratableDiffer = iteratableDiffers.find(this.options).create(null);
    }

    override getValue<T>(): T {
        return this.value as T;
    }

    override setIsInvalidState(): void {
        this.isValid = false;
    }

    override setIsValidState(): void {
        this.isValid = true;
    }

    ngOnInit(): void {
        // TODO: Add multi select behavior
        if (_.isArray(this.options)) {
            this.internalOptions = _.cloneDeep(this.options);

            if (!_.isEmpty(this.value)) {
                const optionToSelect: SelectOption = this.internalOptions.find((option: SelectOption) => option.value === this.value);

                if (!_.isNil(optionToSelect))
                    // If we get in this case we don't emit a value, this because we already got a value and are only updating internal options list and selection states.
                    // Emiiting a value here will trigger valueChange eventemitter even when bindings are still being set.
                    // To keep this component as idempotent as possible we do not want to emit here since if there is some logic that listens for valueChange event it will be triggerd on data binding
                    // and with that can come unwanted behaviour.
                    this.selectOption(optionToSelect, false);
            }
        }

        if (this.isDisabled) this.tabIndex = -1;
    }

    ngDoCheck(): void {
        const changes: KeyValueChanges<string, any> = this.keyValueDiffer.diff(this);

        if (changes)
            // Value listener:
            // Listens to the value change and clears and selected state from the component when the value is either an empty string, undefined or null
            // Value listener can in some cases be triggered if the component's parent is listening to the value change and alters it. In this case we re-execute selectOption.
            changes.forEachChangedItem((record: KeyValueChangeRecord<string, any>) => {
                if (record.key === 'value' && (record.currentValue === '' || _.isNil(record.currentValue) || _.isNull(record.currentValue)) && !this.isInternallyTriggered) this.clear(null);
                else if (record.key === 'value' && !this.isInternallyTriggered) {
                    const optionToSelect: SelectOption = this.internalOptions.find((option: SelectOption) => option.value === this.value);

                    if (!_.isNil(optionToSelect))
                        // See comment in options listener section as of why doEmitValue flag is passed as false
                        this.selectOption(optionToSelect, false);

                    this.isInternallyTriggered = false;
                } else if (record.key === 'value' && this.isInternallyTriggered)
                    // Reset flag
                    this.isInternallyTriggered = false;

                if (record.key === 'isDisabled')
                    if (record.currentValue === false) this.tabIndex = 0;
                    else this.tabIndex = -1;
            });

        const dataChanges = this.iteratableDiffer.diff(this.options);

        if (!_.isNil(dataChanges)) {
            this.internalOptions = _.cloneDeep(this.options);

            if (!_.isEmpty(this.value)) {
                const optionToSelect: SelectOption = this.internalOptions.find((option: SelectOption) => option.value === this.value);

                if (optionToSelect !== undefined && optionToSelect !== null)
                    // If we get in this case we don't emit a value, this because we already got a value and are only updating internal options list and selection states.
                    // Emiiting a value here will trigger valueChange eventemitter even when bindings are still being set.
                    // To keep this component as idempotent as possible we do not want to emit here since if there is some logic that listens for valueChange event it will be triggerd on data binding
                    // and with that can come unwanted behaviour.
                    this.selectOption(optionToSelect, false);
                else this.clear(null);
            } else {
                const optionToSelect: SelectOption = this.internalOptions.find((option: SelectOption) => option.is_selected === true);

                if (optionToSelect !== undefined && optionToSelect !== null) this.selectOption(optionToSelect);
                else this.clear(null);
            }
        }
    }

    setContainerClasses(): string {
        let classList: string = '';

        if (!this.isCollapsed) classList = `${classList} ax-select__container--drop-down`;

        if (!this.isValid) classList = `${classList} ax-select__container--invalid`;

        return classList;
    }

    setOptionClasses(option: SelectOption, index: number): string {
        let classList: string = '';

        if (option.is_selected) classList = `${classList} ax-select__option--selected`;

        if (!option.is_selected && index === this.preSelectedIndex) classList = `${classList} ax-select__option--pre-selected`;

        return classList;
    }

    setOptionContainerClasses(): string {
        let classList: string = '';

        if (this.isMobile) classList = `${classList} ax-select__options__container--mobile`;

        if (this.isCollapsed) classList = `${classList} ax-select__options__container--collapsed`;

        return classList;
    }

    toggleCollapse(): void {
        if (!this.isMobile) {
            const offsetTop: number = this.selectContainer.nativeElement.offsetTop;
            const offsetLeft: number = this.selectContainer.nativeElement.offsetLeft;
            const clientWidth: number = this.selectContainer.nativeElement.clientWidth;
            const clientHeight: number = this.selectContainer.nativeElement.clientHeight;

            this.optionsContainer.nativeElement.style.top = `${offsetTop + clientHeight}px`;
            this.optionsContainer.nativeElement.style.left = `${offsetLeft}px`;
            this.optionsContainer.nativeElement.style.width = `${clientWidth}px`;
        } else {
            this.optionsContainer.nativeElement.style.top = null;
            this.optionsContainer.nativeElement.style.left = null;
            this.optionsContainer.nativeElement.style.width = null;
        }

        this.isCollapsed = !this.isCollapsed;

        this.checkOptionContainerPosition();
        this.resetPreSelectedIndex();

        if (!this.isCollapsed && this.selectedOptionIndex > 1) this.preSelectedIndex = this.selectedOptionIndex;
    }

    onFocusLost(): void {
        this.isCollapsed = true;
    }

    selectOption(option: SelectOption, doEmitValue: boolean = true, event: Event = null): void {
        if (!_.isNull(event)) event.stopPropagation();

        this.isInternallyTriggered = doEmitValue;

        this.internalOptions.forEach((option: SelectOption) => {
            option.is_selected = false;
        });

        this.hasSelectedValue = true;
        this.displayValue = option.display_value;

        option.is_selected = true;

        this.selectedOptionIndex = this.internalOptions.indexOf(option);

        if (doEmitValue) {
            this.value = option.value;
            this.valueChange.emit(this.value);
        }

        this.isCollapsed = true;
        this.resetPreSelectedIndex();
    }

    clear(event: Event): void {
        if (!_.isNull(event)) event.stopPropagation();

        this.internalOptions.forEach((option: SelectOption) => {
            option.is_selected = false;
        });

        this.hasSelectedValue = false;
        this.isInternallyTriggered = true;

        this.value = '';
        this.displayValue = '';
        this.valueChange.emit(this.value);

        this.selectedOptionIndex = -1;
    }

    setPreSelectedIndex(index: number): void {
        this.preSelectedIndex = index;
    }

    resetPreSelectedIndex(): void {
        this.preSelectedIndex = -1;
    }

    getMobileContainerPlaceholder(): string {
        if (_.isNil(this.mobileSelectContainerPlaceholder) || _.isEmpty(this.mobileSelectContainerPlaceholder)) return this.placeholder;
        else return this.mobileSelectContainerPlaceholder;
    }

    private checkOptionContainerPosition(): void {
        // Wait for next tick
        _.delay(() => {
            const offsetTop: number = this.optionsContainer.nativeElement.offsetTop;
            const clientHeight: number = this.optionsContainer.nativeElement.clientHeight;

            // 16 is for extra padding so the container does not intersect with the edgs of the window
            if (offsetTop + clientHeight + 16 > window.innerHeight) {
                // TODO: Drop up
            }
        }, 0);
    }

    private scrollToPreSelectedIndexItem(): void {
        const elementToScrollTo: ElementRef = this.optionElements.find((_, i) => i === this.preSelectedIndex);

        elementToScrollTo.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
}
