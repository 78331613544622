<div class="stocktake-overview__container">
    @if (!hasValidEntity) {
        <div class="stocktake__no__data__container">
            <i class="stocktake__no__data__container__icon fa-light fa-box-circle-check"></i>
            <h5>{{getEmptyStateText()}}</h5>
        </div>
    } @else {
        @if (partyStockLevel !== undefined) {
            <div class="stocktake__party__info">
                <span class="stocktake__party__info__title">{{partyStockLevel.description}}</span>
                @if (partyStockLevel.locationCode !== '') {
                    <span class="stocktake__party__info__storage-location">{{partyStockLevel.locationCode}}</span>
                } @else {
                    <span class="stocktake__party__info__storage-location stocktake__party__info__storage-location--no-location">No storage location</span>
                }
            </div>
            <div class="stocktake__party__values">
                <div class="stocktake__party__values__quantities">
                    <h5 class="stocktake__party__values__quantities__label">Currently in stock</h5>
                    <div class="stocktake__party__values__quantities__container">
                        <div class="stocktake__quantity__block">
                            <small class="stocktake__quantity__block__label">Packings</small>
                            <span>{{boxesInStock}}</span>
                        </div>
                        <div class="stocktake__quantity__block">
                            <small class="stocktake__quantity__block__label">Product Units</small>
                            <span>{{stemsPerBox}}</span>
                        </div>
                        <div class="stocktake__quantity__block">
                            <small class="stocktake__quantity__block__label">Rest</small>
                            <span>{{restUnits}}</span>
                        </div>
                        <div class="stocktake__quantity__block">
                            <small class="stocktake__quantity__block__label">Total</small>
                            <span>{{totalUnitsInStock}}</span>
                        </div>
                    </div>
                </div>
                <div class="stocktake__party__values__quantities">
                    <h5 class="stocktake__party__values__quantities__label">Available in stock</h5>
                    <div class="stocktake__party__values__quantities__container">
                        <div class="stocktake__quantity__block">
                            <small class="stocktake__quantity__block__label">Packings</small>
                            <span>{{availableBoxesInStock}}</span>
                        </div>
                        <div class="stocktake__quantity__block">
                            <small class="stocktake__quantity__block__label">Product Units</small>
                            <span>{{availableStemsPerBox}}</span>
                        </div>
                        <div class="stocktake__quantity__block">
                            <small class="stocktake__quantity__block__label">Rest</small>
                            <span>{{availableRestUnits}}</span>
                        </div>
                        <div class="stocktake__quantity__block">
                            <small class="stocktake__quantity__block__label">Total</small>
                            <span>{{availableTotalUnitsInStock}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="stocktake__input__container">
                <span class="stocktake__input__label">Recorded quantity</span>
                <input #quantityInput type="text" ax-input class="stocktake__input__input" placeholder="Amount" [(ngModel)]="recordedQuantity" (keypress)="checkInput($event)" (keyup.enter)="updateStock()">
            </div>
            <div class="stocktake__actions">
                <button ax-button="primary" [disabled]="isStockUpdateDisabled()" (click)="updateStock()">Update</button>
            </div>
        }
    }
</div>
