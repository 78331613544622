<div #toastContainer class="ax-toast__container" [ngClass]="setClasses()">
    <div class="ax-toast__icon">
        @switch (type) {
            @case ('success') { <i class="fa-light fa-octagon-check fa-xl"></i> }
            @case ('info') { <i class="fa-light fa-square-info fa-xl fa-xl"></i> }
            @case ('warning') { <i class="fa-light fa-triangle-exclamation fa-xl"></i> }
            @case ('danger') { <i class="fa-sharp fa-light fa-octagon-xmark fa-xl"></i> }
        }
    </div>
    <div class="ax-toast__header">
        <h3 class="ax-toast__header__title">{{title}}</h3>
        @if (hasCloseButton) {
            <button ax-button class="ax-toast__header__button">
                <i class="fa-solid fa-xmark"></i>
            </button>
        }
    </div>
    <span class="ax-toast__message" [innerHtml]="message"></span>
</div>