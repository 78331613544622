<div class="sales-overview-page__container">
    @if (!isLoading) {
        @if (showSalesRegistration) {
            <button ax-button="primary" class="sales-overview-page__container__button" [routerLink]="'/sales/sales-registration'">
                <img src="/assets/icons/icon_allocate_customer_order.png" alt="allocate_customer_order"/>
                Sales Registration
            </button>
        }
    }
</div>
